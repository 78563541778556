import { Box, Grid } from '@mui/material'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ICertificate, ICourse, IEducation } from 'types/cvsInterfaces'
import EducationListItem from './EducationListItem'

export interface StyleProps {
  color: string
}

/** @notExported */
interface EducationItemCardProps {
  /** Function to open item. */
  openItem: (item) => void
  /** Item title. */
  title: string
  /** Item color. */
  editable: boolean
  /** Is item editable. */
  color: string
  /** Education items. */
  educations?: IEducation[]
  /** Course items. */
  courses?: ICourse[]
  /** Certificate items. */
  certificates?: ICertificate[]
  /** Is item anonymous. */
  anonymous: boolean
}

/**
 * Education item card.
 *
 * @param openItem -
 * @param title -
 * @param color -
 * @param editable -
 * @param educations -
 * @param courses -
 * @param certificates -
 * @param anonymous -
 * @returns Education item card component.
 * @notExported
 */
const EducationItemCard: React.FC<EducationItemCardProps> = ({
  openItem,
  color,
  editable,
  title,
  educations,
  courses,
  certificates,
  anonymous,
}) => {
  let educationsMap: React.ReactNode[] = []
  let coursesMap: React.ReactNode[] = []
  let certificatesMap: React.ReactNode[] = []

  const { t } = useTranslation()

  if (title === 'degrees' && educations) {
    const length = educations.length
    educationsMap = useMemo(
      () =>
        educations.map((education, i) => {
          return (
            <EducationListItem
              key={education.id}
              editable={editable}
              title={title}
              education={education}
              showDivider={i++ < length - 1}
              onOpen={() => openItem(education)}
              header="degree"
              anonymous={anonymous}
            />
          )
        }),
      [educations]
    )
  } else if (title === 'courses' && courses) {
    const length = courses.length
    coursesMap = useMemo(
      () =>
        courses.map((course, i) => {
          return (
            <EducationListItem
              key={course.id}
              editable={editable}
              title={title}
              course={course}
              showDivider={i++ < length - 1}
              onOpen={() => openItem(course)}
              header="course"
              anonymous={anonymous}
            />
          )
        }),
      [courses]
    )
  } else if (title === 'certificates' && certificates) {
    const length = certificates.length
    certificatesMap = useMemo(
      () =>
        certificates.map((certificate, i) => {
          return (
            <EducationListItem
              key={certificate.id}
              editable={editable}
              title={title}
              certificate={certificate}
              showDivider={i++ < length - 1}
              onOpen={() => openItem(certificate)}
              header="certificate"
              anonymous={anonymous}
            />
          )
        }),
      [certificates]
    )
  }

  const getMargin = title => {
    if (title === 'certificates') {
      return 'none'
    } else {
      return '18px'
    }
  }

  return (
    <>
      <Box
        sx={{
          font: 'Raleway',
          fontWeight: 'bold',
          letterSpacing: '0.9px',
          textTransform: 'capitalize',
          color: theme => theme.palette.primary.main,
          marginLeft: '12px',
          alignSelf: 'start',
        }}
      >
        {t(`${title}.title`)}
      </Box>
      <Grid
        sx={{
          width: '100%',
          background: theme => theme.palette.background.default,
          borderRadius: '5px',
          padding: '0px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '9px',
          marginBottom: getMargin(title),
        }}
      >
        <Grid
          sx={{
            width: '100%',
            height: '5px',
            background: theme =>
              color === 'info'
                ? theme.palette.info.main
                : color === 'success'
                ? theme.palette.success.main
                : theme.palette.warning.main,
          }}
        />
        {educationsMap && educationsMap.length > 0 ? educationsMap : null}
        {coursesMap && coursesMap.length > 0 ? coursesMap : null}
        {certificatesMap && certificatesMap.length > 0 ? certificatesMap : null}
      </Grid>
    </>
  )
}

export default EducationItemCard
