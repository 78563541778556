import { Dialog, DialogTitle, Divider, Grid } from '@mui/material'
import { cvAPI } from 'api/CvAPI'
import { personAPI } from 'api/PersonAPI'
import Allocation from 'pages/Allocation'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { getCvCopyInitialData } from 'Components/reusable/DataContext/InitialData'
import { copyProfileSchema } from 'Components/reusable/DataContext/ValidationSchema'
import CloseButton from 'Components/reusable/IconButtons/CloseButton'
import { useCompanyPermissions, useUser } from 'hooks'
import { useAccountNetworks, useBrokerNetworks } from 'hooks/networks'
import _ from 'lodash'
import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ICv } from 'types/cvsInterfaces'
import { IError } from 'types/error'
import { CvId } from 'types/ids'
import { IPerson } from 'types/userInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import ContactSalesCard from './ContactSalesCard/ContactSalesCard'
import CopyProfileModal from './CopyProfileModal/CopyProfileModal'
import ProfileFillingWizard from './ProfileFillingWizard'
import ProfilePageCards from './ProfilePageCards/ProfilePageCards'
import ProfileProgressModal from './ProfileProgressModal/ProfileProgressModal'
import ProfileToolbarItems from './ProfileToolbarItems/ProfileToolbarItems'
import CvUpdateHistoryModal from './CvUpdateHistoryModal/CvUpdateHistoryModal'
import { permissionAPI } from 'api/PermissionAPI'
import CvParseModal from './CvParseModal'
import { cvParserAPI } from 'api/CvParserAPI'
import { useIsComponentMounted } from 'hooks/util'
import colors from 'constants/colors'
import { useLocation, useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

export interface CVOption {
  key: number
  label: string
  data: ICv | undefined
}

const Profile = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user, ready, groups } = useUser()
  const { t, i18n } = useTranslation()
  const [personData, setPersonData] = useState<IPerson>()
  const [selectedCV, setSelectedCV] = useState<ICv>()
  const [wizardCvId, setWizardCvId] = useState<CvId>()
  const [backendError, setBackendError] = useState<IError>()
  const [cvUpdatedAt, setCvUpdatedAt] = useState<Date>(new Date())
  const [isEmpty, setIsEmpty] = useState<boolean>(true)
  const [cvLoading, setCvLoading] = useState<boolean>(true)
  const [copy, setCopy] = useState<boolean>(false)
  const [edit, setEdit] = useState<ICv>()
  const [refresh, setRefresh] = useState<boolean>(false)
  const [original, setOriginal] = useState<ICv>()
  const [progressModal, setProgressModal] = useState<boolean>(false)
  const [profileWizard, setProfileWizard] = useState<boolean>(false)
  const [profileProgress, setProfileProgress] = useState<number>()
  const [fullCV, setFullCV] = useState<ICv>()
  const [openHistory, setOpenHistory] = useState<number>()
  const [updateCvProgress, setUpdateCvProgress] = useState<Date>()
  const [progressCV, setProgressCV] = useState<ICv>()
  const [personTeamAccess, setPersonTeamAccess] = useState<boolean>(false)
  const [cvParse, setCvParse] = useState<boolean>(false)
  const [parseCredits, setParseCredits] = useState<number>(0)
  const { salesAccess } = useCompanyPermissions(personData?.organizationId ?? undefined)
  const { networks: viewerNetworks } = useBrokerNetworks()
  const { networks: accountNetworks } = useAccountNetworks(personData?.accountId)
  const isComponentMounted = useIsComponentMounted()
  const { profileId: id } = useParams()

  // Calculate effective personId. Use `profileId` from the URL and
  // fall back to the user's personId. If the computed id is null, we
  // can throw a 404
  const personId = id ? parseInt(id, 10) : user?.Person?.id

  // FIXME: Race condition here. If the computed `personId` changes
  // quickly enough, the async path might resolve after the undefined
  // case.
  useEffect(() => {
    const controller = new AbortController()

    if (personId === null || typeof personId === 'undefined') {
      if (ready) {
        navigate('/404', { replace: true })
      }
      return
    }

    ;(async () => {
      try {
        setCvLoading(true)
        const data = await personAPI.getPersonData(personId, controller)

        if (!isComponentMounted.current) return

        const CVs = _.orderBy(data.CVs, ['id'], ['asc'])
        data.CVs = CVs
        setPersonData(data)
        setOriginal(CVs.find(cv => cv.original === true))
        if (location.state && location.state.search) {
          setSelectedCV(CVs.find(cv => cv.id === location.state.search))
        } else {
          if (data.defaultCvId && !!CVs.find(cv => cv.id === data.defaultCvId)) {
            setSelectedCV(CVs.find(cv => cv.id === data.defaultCvId))
          } else {
            const original = CVs.find(cv => cv.original)
            if (original) {
              setSelectedCV(original)
            } else {
              setSelectedCV(CVs[0])
            }
          }
        }
        if (refresh) setRefresh(false)
      } catch (err) {
        setBackendError(err as IError)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [personId, ready, history, refresh])

  useEffect(() => {
    if (personData && selectedCV && ready && fullCV) {
      ;(async () => {
        setCvUpdatedAt(new Date(selectedCV.updatedAt))
        setProfileProgress(checkProfileProgress(fullCV))
        const empty = await isCvEmpty()
        setIsEmpty(empty)
        if (empty) {
          setCvLoading(false)
        }
      })()
    }
  }, [personData, selectedCV, ready, fullCV])

  useEffect(() => {
    const controller = new AbortController()

    if (selectedCV) {
      ;(async () => {
        try {
          setCvLoading(true)
          const cv = await cvAPI.getCvData(selectedCV.id, controller)

          if (!isComponentMounted.current) return
          setFullCV(cv)
          setProgressCV(cv)
        } catch (err) {
          setBackendError(err as IError)
        }
      })()
    }

    return () => {
      controller.abort()
    }
  }, [selectedCV])

  useEffect(() => {
    const controller = new AbortController()

    if (wizardCvId) {
      ;(async () => {
        try {
          setCvLoading(true)
          const updatedCv = await cvAPI.getCvData(wizardCvId, controller)

          if (!isComponentMounted.current) return
          setFullCV(updatedCv)
          setSelectedCV(updatedCv)
        } catch (err) {
          setBackendError(err as IError)
        }
      })()
    }

    return () => {
      controller.abort()
    }
  }, [wizardCvId])

  useEffect(() => {
    const controller = new AbortController()

    if (selectedCV) {
      ;(async () => {
        try {
          const cv = await cvAPI.getCvData(selectedCV.id, controller)

          if (cv) {
            if (!isComponentMounted.current) return
            setProfileProgress(checkProfileProgress(cv))
            setProgressCV(cv)
          }
        } catch (err) {
          setBackendError(err as IError)
        }
      })()
    }

    return () => {
      controller.abort()
    }
  }, [updateCvProgress])

  useEffect(() => {
    const controller = new AbortController()

    if (personData) {
      ;(async () => {
        try {
          const personTeamAccessData = await permissionAPI.getPersonTeamAccess(personData.id, controller)
          if (!isComponentMounted.current) return
          setPersonTeamAccess(personTeamAccessData)
          if (personData.organizationId) {
            const parseCreditsData = await cvParserAPI.getParseCredits(personData.organizationId, controller)
            if (!isComponentMounted.current) return
            setParseCredits(parseCreditsData)
          }
        } catch (err) {
          setBackendError(err as IError)
        }
      })()
    }

    return () => {
      controller.abort()
    }
  }, [personData])

  useEffect(() => {
    if (progressCV) {
      setProfileProgress(checkProfileProgress(progressCV))
    }
  }, [i18n.language])

  const allSkillIds = useMemo(() => {
    const allSkills: number[] = []

    if (fullCV && fullCV.PersonSkills && fullCV.PersonSkills.length > 0) {
      for (const personSkill of fullCV.PersonSkills) {
        allSkills.push(personSkill.Skill.id)
      }
    }
    if (fullCV && fullCV.Roles && fullCV.Roles.length > 0) {
      for (const role of fullCV.Roles) {
        allSkills.push(role.Skill.id)
      }
    }
    if (fullCV && fullCV.Industries && fullCV.Industries.length > 0) {
      for (const industry of fullCV.Industries) {
        allSkills.push(industry.Skill.id)
      }
    }

    return allSkills
  }, [fullCV])

  const chooseName = (cv: ICv, index: number) => {
    const translation = chooseDBTranslation(i18n, cv)
    const translationName = translation.name
    const cvName = cv.translations.find(tr => tr.name !== undefined && tr.name !== null && tr.name.length > 0)?.name

    return translationName ? translationName : cvName ?? 'CV_' + index
  }

  const CVOptions: CVOption[] = useMemo(() => {
    let options: CVOption[] = []

    if (personData && personData.CVs) {
      for (let i = 0; i < personData.CVs.length; i++) {
        const cv = personData.CVs[i]
        options.push({
          key: cv.id,
          label: chooseName(cv, i),
          data: cv,
        })
      }
    }

    options = _.orderBy(options, ['data.id'], ['asc'])

    return options
  }, [personData, i18n.language])

  // Set the page title
  if (personData) {
    document.title = 'Caleo | ' + personData?.firstName + ' ' + personData?.lastName
  } else {
    document.title = 'Caleo | ' + t('loading')
  }

  const checkProfileProgress = (cv: ICv): number => {
    let progress = 0
    const sectionProgress = 100 / 7
    if (chooseDBTranslation(i18n, cv)?.primaryRole?.length > 0) progress += sectionProgress
    if (chooseDBTranslation(i18n, cv)?.about?.length > 0) progress += sectionProgress
    if (
      (cv.Education.length > 0 &&
        cv.Education.every(item => item.translations.find(tr => tr.Language.name === i18n.language))) ||
      (cv.Courses.length > 0 &&
        cv.Courses.every(item => item.translations.find(tr => tr.Language.name === i18n.language))) ||
      (cv.Certificates.length > 0 &&
        cv.Certificates.every(item => item.translations.find(tr => tr.Language.name === i18n.language)))
    ) {
      progress += sectionProgress
    }
    if (
      cv.Employers.length > 0 &&
      cv.Employers.every(item => item.translations.find(tr => tr.Language.name === i18n.language))
    ) {
      progress += sectionProgress
    }
    if (
      cv.Projects.length > 0 &&
      cv.Projects.every(item => item.translations.find(tr => tr.Language.name === i18n.language))
    ) {
      progress += sectionProgress
    }
    if (cv.PersonSkills.length > 0) progress += sectionProgress
    if (cv.LanguageSkills.length) progress += sectionProgress
    return progress
  }

  const isCvEmpty = async (): Promise<boolean> => {
    if (
      personData &&
      selectedCV &&
      ((user && user.Person && selectedCV.personId === user.Person.id) ||
        (groups && groups.includes('sales') && !selectedCV.original))
    ) {
      if (fullCV) {
        return (
          fullCV.Education.length <= 0 &&
          fullCV.Courses.length <= 0 &&
          fullCV.Certificates.length <= 0 &&
          fullCV.Projects.length <= 0 &&
          fullCV.Employers.length <= 0 &&
          fullCV.LanguageSkills.length <= 0 &&
          fullCV.PersonSkills.length <= 0 &&
          fullCV.Industries.length <= 0 &&
          fullCV.Roles.length <= 0 &&
          fullCV.References.length <= 0
        )
      }
    }
    return false
  }

  const closeCopyModal = (type: string, data?: ICv) => {
    setCvLoading(true)
    setEdit(undefined)
    setCopy(false)
    if ((type === 'edit' && data) || (type === 'create' && data)) {
      updateCvList(type, data)
      setSelectedCV(data)
    } else if (type === 'delete' && data && personData && personData.CVs) {
      updateCvList(type, data)
    }
  }

  const updateCvList = (type: string, data: ICv) => {
    if (personData && personData.CVs && data) {
      if (type === 'edit') {
        const editedData = personData
        editedData.CVs = personData.CVs.map(item => (item.id === data.id ? data : item))
        setPersonData(editedData)
      }
      if (type === 'create') {
        const newData = _.cloneDeep(personData)
        if (newData && newData.CVs) {
          newData.CVs.push(data)
          setPersonData(newData)
        }
      }
      if (type === 'delete') {
        const newData = _.cloneDeep(personData)
        if (newData && newData.CVs) {
          newData.CVs = newData.CVs.filter(item => item.id !== data.id)
          setPersonData(newData)
          if (selectedCV && selectedCV.id === data.id) {
            setSelectedCV(newData.CVs[0])
          }
        }
      }
    }
  }

  const getProfilePageContent = (selectedCv?: ICv) => {
    return (
      <>
        {!isEmpty && selectedCv ? (
          <ProfilePageCards
            selectedCv={selectedCv}
            personData={personData}
            cvLoading={cvLoading}
            setCvLoading={setCvLoading}
            setCvUpdatedAt={setCvUpdatedAt}
            salesAccess={salesAccess || personTeamAccess}
            viewerNetworks={viewerNetworks}
            accountNetworks={accountNetworks}
            setUpdateCvProgress={(update: Date) => {
              setUpdateCvProgress(update)
            }}
          />
        ) : (
          !cvLoading &&
          selectedCV?.id && (
            <ProfileFillingWizard
              id={selectedCV?.id}
              open={setIsEmpty}
              onSaveClose={(id: CvId) => setWizardCvId(id)}
              isDialog={false}
              oldSkills={allSkillIds}
            />
          )
        )}
      </>
    )
  }

  const isBroker = (): boolean => {
    const commonNetworks = viewerNetworks?.filter(
      network => network.id === accountNetworks?.find(n => n.id === network.id)?.id
    )
    if (commonNetworks) {
      for (const commonNetwork of commonNetworks) {
        if (
          commonNetwork?.NetworkBrokers?.find(broker => broker.accountId === user?.id) &&
          commonNetwork.salesContactsVisible
        ) {
          return true
        }
      }
    }
    return false
  }

  const additionalItems = () => {
    return (
      <Grid>
        {personData?.organizationId && user?.organizationId !== personData.organizationId && isBroker() && (
          <ContactSalesCard personData={personData} />
        )}
        {personId != user?.Person?.id && <Allocation />}
        {(personId != user?.Person?.id ||
          (personData?.organizationId && user?.organizationId !== personData.organizationId && isBroker())) && (
          <Divider sx={{ mb: 10, mt: 2 }} />
        )}
      </Grid>
    )
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <>
      {!cvLoading && additionalItems()}
      {selectedCV && (
        <>
          {getProfilePageContent(fullCV)}
          <ProfileToolbarItems
            cvLoading={cvLoading}
            cvUpdatedAt={cvUpdatedAt}
            profileProgress={profileProgress}
            setCopy={setCopy}
            setEdit={setEdit}
            setProgressModal={setProgressModal}
            selectedCV={selectedCV}
            setSelectedCV={setSelectedCV}
            personData={personData}
            setPersonData={setPersonData}
            salesAccess={salesAccess || personTeamAccess}
            setProfileWizard={setProfileWizard}
            setOpenHistory={setOpenHistory}
            setCvParse={setCvParse}
            CVOptions={CVOptions}
            parseCredits={parseCredits}
            updateCvList={updateCvList}
          />
          {copy && personData && personData.CVs && (
            <CopyProfileModal
              item={edit}
              setOriginal={() => setSelectedCV(original)}
              onClose={() => {
                setCopy(false)
                setEdit(undefined)
              }}
              onAdvancedClose={closeCopyModal}
              schema={copyProfileSchema()}
              initialData={getCvCopyInitialData(selectedCV)}
              maxWidth="xs"
              fullWidth={true}
              localeBase="profile"
              api={personAPI}
              cvId={selectedCV.id}
              disableDelete={personData.CVs.length <= 1 || personData.defaultCvId === selectedCV.id}
              noEscClose
            />
          )}
          {progressModal && (
            <ProfileProgressModal
              onClose={() => setProgressModal(false)}
              cv={progressCV ?? null}
              header={t('profile.progressTitle')}
              maxWidth="xs"
              fullWidth={true}
              noEscClose
            />
          )}
          <Dialog
            open={profileWizard}
            onClose={() => {
              return
            }}
            maxWidth="xl"
            fullWidth={true}
            sx={{
              '.MuiDialog-paper': {
                backgroundColor: colors.secondaryBackground,
                borderRadius: 1,
                minHeight: '50vh',
              },
            }}
          >
            <DialogTitle sx={{ backgroundColor: colors.secondaryBackground }}>
              <span id="form-dialog-title">{t(`profile.fillingWizard.title`)}</span>
              <CloseButton clickAction={() => setProfileWizard(false)} />
            </DialogTitle>
            <ProfileFillingWizard
              id={selectedCV?.id}
              open={setProfileWizard}
              onSaveClose={(id: CvId) => {
                setProfileWizard(false)
                setWizardCvId(id)
              }}
              isDialog={true}
              oldSkills={allSkillIds}
            />
          </Dialog>
          {openHistory && (
            <CvUpdateHistoryModal
              cvId={openHistory}
              onClose={() => setOpenHistory(undefined)}
              header={t('cvHistory.header')}
              noEscClose
            />
          )}
          {cvParse && (
            <CvParseModal
              personId={personId}
              onClose={(cv?: ICv) => {
                setCvParse(false)
                if (cv && cv.id) {
                  updateCvList('create', cv)
                  setSelectedCV(cv)
                }
              }}
              header={t('profile.parseModal')}
              maxWidth="lg"
              fullWidth={true}
              noEscClose
            />
          )}
        </>
      )}
    </>
  )
}

export default Profile
