import { CircularProgress, Grid, Pagination, Typography } from '@mui/material'
import { useSearchData } from 'Components/General/SearchProvider/SearchProvider'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ResultCard from './ResultCard'
import NotFound from './NotFound'
import { ISearchResult } from 'types/searchInterfaces'
import { formatDistanceToNow } from 'date-fns'
import { enGB, fi } from 'date-fns/locale'
import i18next from 'i18next'
import { useCountryData } from 'hooks'
import { searchEmployeesApi } from 'api/SearchEmployeesAPI'
import { IError } from 'types/error'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { PersonId } from 'types/ids'
import { IPerson } from 'types/userInterfaces'

/**
 * React functional component for displaying search results.
 */
const Results: React.FC = () => {
  const { t } = useTranslation()
  const { searchData } = useSearchData()
  const { countries } = useCountryData()

  const [results, setResults] = useState<ISearchResult[]>([])
  const [resultsCount, setResultsCount] = useState<number>(10)
  const [backendError, setBackendError] = useState<IError>()
  const [proposeCard, setProposeCard] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const controller = new AbortController()

    if (searchData.results) {
      ;(async () => {
        try {
          if (searchData.results) {
            setLoading(true)
            const tokens = searchData.terms.skills.map(skill => skill.skillId)
            const pageData = searchData.results.slice(0, 10)
            const data = await searchEmployeesApi.postSearchResults(pageData, tokens, controller)

            if (data.length < 10) {
              data.push({ kind: 'partial', anonymous: true, personId: 0 as PersonId, Person: {} as IPerson })
              setProposeCard(true)
            }

            setResults(data)
            setLoading(false)
          }
        } catch (error) {
          setBackendError(error as IError)
        }
      })()
    }

    return () => {
      controller.abort()
    }
  }, [searchData.results])

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  const localeMap = {
    en: enGB,
    fi: fi,
  }

  const fetched = `${
    searchData.lastFetched ? formatDistanceToNow(searchData.lastFetched, { locale: localeMap[i18next.language] }) : null
  }`

  const changePage = async (_e: ChangeEvent<unknown>, newPage: number) => {
    if (searchData.results) {
      try {
        setLoading(true)

        const tokens = searchData.terms.skills.map(skill => skill.skillId)
        const pageData = searchData.results?.slice((newPage - 1) * 10, newPage * 10)
        const data = await searchEmployeesApi.postSearchResults(pageData, tokens)
        setProposeCard(false)

        if (data.length < 10) {
          data.push({ kind: 'partial', anonymous: true, personId: 0 as PersonId, Person: {} as IPerson })
        }

        setResults(data)
        setResultsCount(newPage * 10)

        window.scrollTo({ top: 0, behavior: 'smooth' })
        setLoading(false)
      } catch (error) {
        setBackendError(error as IError)
      }
    }
  }

  let to
  if (results.length < 10 && searchData.results) {
    to = searchData.results?.length
  } else {
    to = resultsCount
  }

  return (
    <Grid mt={4} container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
              {t('search.results')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography gutterBottom>{t('search.lastFetched', { time: fetched })}</Typography>
          </Grid>
        </Grid>
        {searchData.results && (
          <Typography>
            {t('search.resultsCount', {
              from: resultsCount - 10,
              to: proposeCard && to > 0 ? to - 1 : to,
              of: searchData.results?.length,
            })}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        {!loading &&
          results?.map(result => {
            if (result.kind === 'partial' && result.anonymous && result.personId === 0) {
              return <NotFound key={result.personId} />
            } else {
              return <ResultCard key={result.personId} result={result} terms={searchData.terms} countries={countries} />
            }
          })}
        {loading && (
          <Grid container justifyContent="center" sx={{ minHeight: '300px' }} alignContent="center">
            <CircularProgress size={60} />
          </Grid>
        )}
      </Grid>
      {searchData.results && (
        <Grid container justifyContent="center">
          <Pagination
            count={Math.ceil(searchData.results.length / 10)}
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
            siblingCount={2}
            onChange={changePage}
            color="primary"
          />
        </Grid>
      )}
    </Grid>
  )
}

export default Results
