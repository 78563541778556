import { Grid, useMediaQuery, useTheme } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import EditButton from 'Components/reusable/IconButtons/EditButton'
import { useUser } from 'hooks/user'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IAllocation } from 'types/allocationInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { convertToDisplayDate } from 'utils/utils'
import CustomTable from 'Components/reusable/Tables/CustomTable'
import DeleteButton from 'Components/reusable/IconButtons/DeleteButton'
import { useLocalStorage } from 'hooks/data'
import { ColumnDef, PaginationState } from '@tanstack/react-table'
import { sortByDateColumn } from 'Components/reusable/Tables/CustomTable/sortFunctions'

/** @notExported */
interface IAllocationTableProps {
  /** Allocations. */
  items: IAllocation[]
  /** Function to open allocation. */
  openItem: (item: IAllocation) => void
  /** Function to delete allocation. */
  deleteItem: (item: IAllocation) => void
  /** Person ID. */
  personId: number
  /** Access to sales. */
  salesAccess: boolean
  /** Number of rows. */
  rows?: number
}

/**
 * Component for allocation table.
 *
 * @returns Table component.
 * @notExported
 */
const AllocationTable: React.FC<IAllocationTableProps> = ({
  items,
  openItem,
  deleteItem,
  personId,
  salesAccess,
  rows,
}) => {
  const { t, i18n } = useTranslation()
  const { groups } = useUser()
  const [allocationCount, setAllocationCount] = useLocalStorage('allocationCount', 10)
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))
  const [columnVisibility, setColumnVisibility] = useLocalStorage('allocationColumns', {})
  const [globalFilter, setGlobalFilter] = React.useState<string>('')

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: allocationCount ? allocationCount : 25,
  })

  const columns = React.useMemo<ColumnDef<IAllocation>[]>(() => {
    const cols = [
      {
        id: 'project',
        header: t('allocation.project'),
        cell: ({ row }) => {
          if (row.original.type === 'project') {
            return chooseDBTranslation(i18n, row.original.Assignment).name
          } else if (row.type === 'personal') {
            return t('allocation.ownProject')
          } else {
            return t('allocation.other')
          }
        },
      },
      {
        id: 'roleId',
        header: t('allocation.roleId'),
        accessorFn: row => (row.AssignmentRole ? chooseDBTranslation(i18n, row.AssignmentRole).name : ''),
        cell: ({ row }) => {
          if (row.original.AssignmentRole) {
            return chooseDBTranslation(i18n, row.original.AssignmentRole).name
          }
          return <RemoveIcon />
        },
      },
      {
        id: 'customer',
        header: t('allocation.customer'),
        accessorFn: row => (row.Assignment ? chooseDBTranslation(i18n, row.Assignment).customer : ''),
        cell: ({ row }) => {
          if (row.original.Assignment) {
            return chooseDBTranslation(i18n, row.original.Assignment).customer
          }
          return <RemoveIcon />
        },
      },
      {
        id: 'information',
        header: t('allocation.information'),
        accessorFn: row => chooseDBTranslation(i18n, row).information,
        cell: ({ row }) => {
          return (
            <CardContentText type="description">{chooseDBTranslation(i18n, row.original).information}</CardContentText>
          )
        },
      },
      {
        id: 'percent',
        header: t('allocation.percent'),
        accessorFn: row => `${row.percent}`,
        cell: ({ row }) => {
          const value = row.original.type

          let sx
          if (value === 'personal') {
            sx = {
              width: '19px',
              height: '19px',
              borderRadius: '50%',
              display: 'inline-block',
              backgroundColor: theme.palette.secondary.main,
            }
          } else if (row.original.percent >= 100) {
            sx = {
              width: '19px',
              height: '19px',
              borderRadius: '50%',
              display: 'inline-block',
            }
          } else if (row.original.percent < 100 && row.original.percent > 0) {
            sx = {
              width: '19px',
              height: '19px',
              borderRadius: '50%',
              display: 'inline-block',
              backgroundColor: theme.palette.warning.main,
            }
          } else {
            sx = {
              width: '17px',
              height: '17px',
              borderRadius: '50%',
              color: theme.palette.neutral.main,
              display: 'inline-block',
              backgroundColor: theme.palette.background.default,
            }
          }

          return (
            <Grid container>
              <Grid sx={sx} />{' '}
              <Grid
                sx={{
                  marginRight: '25px',
                  marginLeft: '5px',
                }}
              >
                {row.original.percent}
              </Grid>
            </Grid>
          )
        },
      },
      {
        id: 'status',
        header: t('allocation.status'),
        cell: ({ row }) => t(`allocation.state.${row.original.state}`),
      },
      {
        id: 'startDate',
        header: t('allocation.startDate'),
        sortingFn: (rowA, rowB, columnId) => sortByDateColumn(rowA, rowB, columnId),
        accessorFn: row => {
          return convertToDisplayDate(row.startDate)
        },
      },
      {
        id: 'endDate',
        header: t('allocation.endDate'),
        sortingFn: (rowA, rowB, columnId) => sortByDateColumn(rowA, rowB, columnId),
        accessorFn: row => {
          if (row.endDate) {
            return convertToDisplayDate(row.endDate)
          } else {
            return ''
          }
        },
      },
      {
        id: 'controls',
        enableHiding: false,
        cell: ({ row }) => {
          let editable = false
          if (
            (salesAccess || groups.includes('admin')) &&
            (row.original.type === 'project' || row.original.type === 'other') &&
            !row.original.assignmentRoleProposalId
          ) {
            editable = true
          }

          if (
            row.original.type === 'personal' &&
            (salesAccess || (personId === row.original.personId && row.original.type === 'personal'))
          ) {
            editable = true
          }

          if (editable) {
            return <EditButton clickAction={() => openItem(row.original)} />
          } else if (row.original.assignmentRoleProposalId && (salesAccess || groups.includes('admin'))) {
            return (
              <DeleteButton
                clickAction={() => deleteItem(row.original)}
                tooltip={t('allocation.proposal.remove', {
                  assignmentName: chooseDBTranslation(i18n, row.original.Assignment).name,
                })}
              />
            )
          }

          return null
        },
      },
    ]

    if (mobileView) {
      return cols.filter(
        col => col.id === 'project' || col.id === 'startDate' || col.id === 'endDate' || col.id === 'controls'
      )
    }

    return cols
  }, [personId, i18n.language, mobileView])

  const limitedColumns = React.useMemo<ColumnDef<IAllocation>[]>(() => {
    const cols = [
      {
        id: 'percent',
        header: t('allocation.percent'),
        accessorFn: row => `${row.percent}`,
        cell: ({ row }) => {
          const value = row.original.type

          let sx
          if (value === 'personal') {
            sx = {
              width: '19px',
              height: '19px',
              borderRadius: '50%',
              display: 'inline-block',
              backgroundColor: theme.palette.secondary.main,
            }
          } else if (row.original.percent >= 100) {
            sx = {
              width: '19px',
              height: '19px',
              borderRadius: '50%',
              display: 'inline-block',
            }
          } else if (row.original.percent < 100 && row.original.percent > 0) {
            sx = {
              width: '19px',
              height: '19px',
              borderRadius: '50%',
              display: 'inline-block',
              backgroundColor: theme.palette.warning.main,
            }
          } else {
            sx = {
              width: '17px',
              height: '17px',
              borderRadius: '50%',
              color: theme.palette.neutral.main,
              display: 'inline-block',
              backgroundColor: theme.palette.background.default,
            }
          }

          return (
            <Grid container>
              <Grid sx={sx} />
              <Grid
                sx={{
                  marginRight: '25px',
                  marginLeft: '5px',
                }}
              >
                {row.original.percent}
              </Grid>
            </Grid>
          )
        },
      },
      {
        id: 'status',
        header: t('allocation.status'),
        accessorFn: ({ row }) => t(`allocation.state.${row.original.state}`),
      },
      {
        id: 'startDate',
        header: t('allocation.startDate'),
        sortingFn: (rowA, rowB, columnId) => sortByDateColumn(rowA, rowB, columnId),
        cell: ({ row }) => {
          return convertToDisplayDate(row.original.startDate)
        },
      },
      {
        id: 'endDate',
        header: t('allocation.endDate'),
        sortingFn: (rowA, rowB, columnId) => sortByDateColumn(rowA, rowB, columnId),
        cell: ({ row }) => {
          return convertToDisplayDate(row.original.endDate)
        },
      },
    ]

    if (mobileView) {
      return cols.filter(col => col.id === 'percent' || col.id === 'status')
    }

    return cols
  }, [personId, i18n.language, mobileView])

  return (
    <div style={{ width: '100%' }}>
      <CustomTable
        columns={items[0]?.Person ? columns : limitedColumns}
        data={items}
        search
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        rowCount={rows ? rows : allocationCount}
        setRowCount={setAllocationCount}
        initialPageSize={10}
        columnVisibility={columnVisibility}
        setColumnVisibility={setColumnVisibility}
        setPagination={setPagination}
        pageIndex={pageIndex}
        pageSize={pageSize}
        elevation={0}
        newStyle
      />
    </div>
  )
}

export default AllocationTable
