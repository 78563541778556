import { StyleSheet } from '@react-pdf/renderer'
import colors from 'constants/colors'

/**
 * Generates the styles object using the StyleSheet.create method.
 *
 * @return {Object} The styles object.
 */
export const useStyles = () => {
  const styles = StyleSheet.create({
    container: {
      marginHorizontal: '20pt',
    },
    title: {
      fontSize: '18.8pt',
      fontFamily: 'Raleway',
      fontWeight: 800,
      marginBottom: '8pt',
      color: colors.black,
      letterSpacing: 0,
      textTransform: 'capitalize',
      borderBottom: `2pt solid ${colors.black}`,
      marginTop: '18pt',
      minWidth: '100%',
    },
    text: {
      fontSize: '11.8pt',
      lineHeight: 1.7,
      color: colors.black,
    },
    description: {
      fontSize: '10.1pt',
      lineHeight: 1.5,
      color: colors.black,
    },
    itemHeader: {
      fontSize: '11.8pt',
      fontFamily: 'Raleway',
      fontWeight: 'bold',
      lineHeight: 1.7,
      maxWidth: '400pt',
      color: colors.black,
    },
    dateText: {
      fontSize: '10.1pt',
      lineHeight: 1.5,
    },
    skillContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginBottom: '20pt',
    },
    columnContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    skillColumn: {
      width: '49%',
      marginRight: '15px',
    },
    item: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '8pt',
      lineHeight: 1,
    },
    levelsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      marginTop: '10pt',
    },
    levelText: {
      textAlign: 'center',
      marginHorizontal: '10pt',
      color: colors.gray,
    },
    skillText: {
      fontSize: '10.1pt',
      maxWidth: '120pt',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    skillData: {
      width: 100,
      justifyContent: 'center',
    },
    skillRow: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      minWidth: '100%',
    },
    languageContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      marginBottom: '20pt',
    },
    itemContainer: {
      width: '100%',
      marginBottom: '20pt',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      marginRight: '15pt',
      minWidth: '100%',
    },
    languageItem: {
      width: '33%',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '5pt',
    },
    languageCode: {
      fontSize: '11.8pt',
      fontFamily: 'Raleway',
      fontWeight: 'bold',
      lineHeight: 1.5,
    },
    starsContainer: {
      marginBottom: '2pt',
    },
    page: {
      paddingTop: '30pt',
      paddingBottom: '30pt',
      paddingLeft: '10pt',
      paddingRight: '10pt',
      fontSize: 10,
      display: 'flex',
      flexWrap: 'nowrap',
      color: colors.black,
    },
    profileImage: {
      display: 'flex',
      height: '184pt',
      width: '184pt',
      borderRadius: 100,
      position: 'absolute',
    },
    orgImage: {
      position: 'absolute',
      top: '65pt',
      right: '20pt',
      width: '100pt',
      height: '100pt',
      objectFit: 'scale-down',
    },
    name: {
      fontFamily: 'Raleway',
      fontSize: '16.8pt',
      lineHeight: 1.5,
      fontWeight: 900,
      marginTop: '25pt',
      marginBottom: '8pt',
    },
    date: {
      color: colors.background,
      fontSize: '8.5pt',
      marginTop: '10pt',
      marginRight: '10pt',
      fontWeight: 'bold',
    },
    colorTop: {
      width: '483pt',
      height: '54pt',
      marginLeft: '92pt',
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    fullWithTop: {
      width: '100%',
      height: '54pt',
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    containerTop: {
      borderTopLeftRadius: 100,
      borderBottomLeftRadius: 100,
      width: '100%',
      height: 230,
    },
    fullContainerTop: {
      borderTopLeftRadius: 100,
      borderBottomLeftRadius: 100,
      width: '100%',
    },
    personalDetails: {
      marginLeft: '219pt',
      width: '230pt',
      display: 'flex',
    },
    fullPersonalDetails: {
      marginLeft: '20pt',
      width: '230pt',
      display: 'flex',
    },
    pageNumber: {
      marginTop: '30pt',
      position: 'absolute',
      bottom: '10',
      right: '10',
    },
    number: {
      color: colors.gray,
    },
    flex: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
    },
    projectSkillsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: '5pt',
    },
    projectSkillItem: {
      fontSize: '11.8pt',
      lineHeight: 1.5,
      color: colors.black,
      marginRight: '15pt',
    },
  })
  return styles
}
