import { Autocomplete, Box, Link, Chip, Grid, TextField } from '@mui/material'
import { useSearchData } from 'Components/General/SearchProvider/SearchProvider'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ISkillOrIndustryOrRole } from 'types/cvsInterfaces'
import { ISearchSkill } from 'types/searchInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { fuseFiltering } from 'utils/utils'
import EditIcon from '@mui/icons-material/Edit'
import SearchIcon from '@mui/icons-material/Search'
import EditPopover from '../SearchComponents/EditPopover'
import colors from 'constants/colors'

/** @notExported */
interface ISkillSearchRequirementProps {
  /** Function to add requirement. */
  onAdd: (newItem: ISearchSkill) => void
  /** Function to add text parameter. */
  addTextParameter: (text: string) => void
  /** The skills that can be selected. */
  skills: ISkillOrIndustryOrRole[]
  /** Whether anonymous network is selected. */
  anonymous?: boolean
}

/**
 * Search requirement field for skills, roles, industries and text.
 *
 * @returns Skill search requirement component.
 * @notExported
 */
const SkillOrTextRequirement: React.FC<ISkillSearchRequirementProps> = ({
  onAdd,
  addTextParameter,
  skills,
  anonymous,
}) => {
  const { i18n, t } = useTranslation()
  const { searchData, setSearchData, resetSearch } = useSearchData()

  const [anchorElement, setAnchorElement] = useState<HTMLElement>()
  const [item, setItem] = useState<ISearchSkill>()
  const [updatedItem, setUpdatedItem] = useState<ISearchSkill>()

  const selectable = useMemo(() => {
    return skills.filter(option => {
      if (
        searchData.terms.skills.find(item => item.skillId === option.id) ||
        searchData.terms.industries.find(item => item.skillId === option.id) ||
        searchData.terms.roles.find(item => item.skillId === option.id)
      ) {
        return false
      } else {
        return true
      }
    })
  }, [skills, searchData.terms.skills, searchData.terms.industries, searchData.terms.roles])

  /**
   * Removes a skill, industry, or role from the searchData state.
   *
   * @param {ISearchSkill} item - the item to be removed
   * @return {() => void} a function that removes the item from searchData
   */
  const removeSkill = (item: ISearchSkill) => () => {
    const { terms } = searchData
    switch (item.Skill.kind) {
      case 'skill':
        setSearchData({
          ...searchData,
          terms: {
            ...terms,
            skills: terms.skills.filter(skill => skill !== item),
          },
        })
        break
      case 'industry':
        setSearchData({
          ...searchData,
          terms: {
            ...terms,
            industries: terms.industries.filter(industry => industry !== item),
          },
        })
        break
      case 'role':
        setSearchData({
          ...searchData,
          terms: {
            ...terms,
            roles: terms.roles.filter(role => role !== item),
          },
        })
        break
    }
  }

  /**
   * Sets the anchor element, item, and updated item based on the event and data.
   *
   * @param {Event} event - the event object
   * @param {ISearchSkill} data - the data to be set
   * @return {void}
   */
  const handleClick = (event, data: ISearchSkill) => {
    setAnchorElement(event.currentTarget)
    setItem(data)
    setUpdatedItem(data)
  }

  /**
   * Renders a Chip component for the given search skill item.
   *
   * @param {ISearchSkill} item - the search skill item to be displayed
   * @return {JSX.Element} the rendered Chip component
   */
  const showChip = (item: ISearchSkill) => (
    <Chip
      key={item.skillId}
      onDelete={removeSkill(item)}
      onClick={e => handleClick(e, item)}
      label={
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid
            item
            sx={{
              color: colors.black,
              fontWeight: 'bold',
            }}
          >
            <span
              style={{
                color: colors.fontNormalColor,
                fontWeight: 'normal',
              }}
            >
              {t(`search.${item.Skill.kind}.name`)}
            </span>{' '}
            {item.name}
          </Grid>
          <Grid item>
            <EditIcon color="newPrimary" fontSize="inherit" />
          </Grid>
        </Grid>
      }
      sx={{
        mr: 0.5,
        borderRadius: 0.5,
        backgroundColor: colors.chipBackground,
        color: colors.black,
        fontWeight: 'bold',
      }}
      size="small"
    />
  )

  /**
   * Saves a skill item in the searchData state based on the kind of skill.
   *
   * @param {ISearchSkill} item - the skill item to be saved
   * @return {void}
   */
  const saveSkill = (item: ISearchSkill) => {
    let index: number
    let array: ISearchSkill[]

    switch (item.Skill.kind) {
      case 'skill':
        index = searchData.terms.skills.findIndex(skill => skill.skillId === item.skillId)
        array = searchData.terms.skills
        setSearchData({
          ...searchData,
          terms: {
            ...searchData.terms,
            skills: array.map((skill, i) => (i === index ? item : skill)),
          },
        })
        break
      case 'industry':
        index = searchData.terms.industries.findIndex(industry => industry.skillId === item.skillId)
        array = searchData.terms.industries
        setSearchData({
          ...searchData,
          terms: {
            ...searchData.terms,
            industries: array.map((industry, i) => (i === index ? item : industry)),
          },
        })
        break
      case 'role':
        index = searchData.terms.roles.findIndex(role => role.skillId === item.skillId)
        array = searchData.terms.roles
        setSearchData({
          ...searchData,
          terms: {
            ...searchData.terms,
            roles: array.map((role, i) => (i === index ? item : role)),
          },
        })
        break
    }

    setAnchorElement(undefined)
  }

  const { terms } = searchData

  const hasTerms = Object.values(terms).filter(term => (Array.isArray(term) ? term.length > 0 : term)).length > 0

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Autocomplete
          value={[]}
          noOptionsText={t('controls.noOptionsText')}
          options={selectable}
          multiple
          freeSolo={anonymous ? false : true}
          getOptionLabel={option => {
            if (typeof option === 'string') {
              return option
            }
            return chooseDBTranslation(i18n, option).name
          }}
          isOptionEqualToValue={(option, value) => {
            if (value && option.id === value.id) {
              return true
            }
            return false
          }}
          onChange={(_e, values) => {
            for (const value of values) {
              if (typeof value === 'string') {
                addTextParameter(value)
              } else {
                onAdd({
                  name: chooseDBTranslation(i18n, value).name,
                  skillId: value.id,
                  Skill: value,
                })
              }
            }
          }}
          filterOptions={(options, { inputValue }) => {
            if (inputValue === '') {
              return options
            }
            return fuseFiltering(options, inputValue, ['translations.name'])
          }}
          renderInput={params => (
            <TextField
              {...params}
              margin="dense"
              variant="outlined"
              size="small"
              name="SkillOrTextRequirement"
              InputProps={{ ...params.InputProps, startAdornment: <SearchIcon /> }}
            />
          )}
          renderOption={(props, option) => (
            <Box {...props} key={option.id} component="li">
              <span
                style={{
                  fontWeight: 'normal',
                  marginRight: 5,
                }}
              >
                {t(`search.${option.kind}.name`)}
              </span>
              <span style={{ fontWeight: 'bold' }}>{chooseDBTranslation(i18n, option).name}</span>
            </Box>
          )}
        />
      </Grid>
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item xs={10}>
          {[...searchData.terms.skills, ...searchData.terms.industries, ...searchData.terms.roles].map(skill =>
            showChip(skill)
          )}
          {searchData.terms.name && searchData.terms.name.length > 0 && (
            <Chip
              label={
                <>
                  <span
                    style={{
                      color: colors.fontNormalColor,
                      fontWeight: 'normal',
                    }}
                  >
                    {t(`search.name`)}
                  </span>{' '}
                  {searchData.terms.name}
                </>
              }
              onDelete={() =>
                setSearchData({
                  ...searchData,
                  terms: {
                    ...searchData.terms,
                    name: '',
                  },
                })
              }
              sx={{
                mr: 0.5,
                borderRadius: 0.5,
                backgroundColor: colors.chipBackground,
                color: colors.black,
                fontWeight: 'bold',
              }}
              size="small"
            />
          )}
          {item && updatedItem && (
            <EditPopover
              item={item}
              anchorEl={anchorElement}
              updatedItem={updatedItem}
              setUpdatedItem={setUpdatedItem}
              saveItem={saveSkill}
              onClose={() => {
                setItem(undefined)
                setUpdatedItem(undefined)
                setAnchorElement(undefined)
              }}
            />
          )}
        </Grid>
        <Grid item xs="auto">
          {hasTerms && (
            <Link
              sx={{
                color: colors.newPrimary,
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
              underline="hover"
              onClick={() => resetSearch()}
            >
              {t('search.reset')}
            </Link>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SkillOrTextRequirement
