import { Autocomplete, Button, Chip, Grid, TextField } from '@mui/material'
import { useSearchData } from 'Components/General/SearchProvider/SearchProvider'
import DataContext from 'Components/reusable/DataContext'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ISearchSkill } from 'types/searchInterfaces'
import { useNotification } from 'Components/reusable/Notification'
import { chooseDBTranslation } from 'utils/translations'
import { fuseFiltering } from 'utils/utils'
import SkillOrTextRequirement from './SkillOrTextRequirement'
import { ISkillOrIndustryOrRole } from 'types/cvsInterfaces'

/** @notExported */
interface IQuickSearchProps {
  /** Function to submit search. */
  onSubmit: () => void
  /** Whether loading. */
  loading: boolean
  /** The skills that can be selected. */
  options: ISkillOrIndustryOrRole[]
}

/**
 * React functional component for QuickSearch.
 *
 * @param {IQuickSearchProps} onSubmit - Function to call on form submission
 * @param {boolean} loading - Loading state indicator
 * @param {ISkillOrIndustryOrRole[]} options - Skill options for the component
 * @return {JSX.Element} The QuickSearch component
 */
const QuickSearch: React.FC<IQuickSearchProps> = ({ onSubmit, loading, options }) => {
  const { t, i18n } = useTranslation()
  const { searchData, setSearchData, searchableNetworks } = useSearchData()
  const { setNotification } = useNotification()

  const anonymous = useMemo(() => {
    const networks = searchData.terms.networks
    return !!networks.find(network => network.anonymouslySearchable === true)
  }, [searchData.terms.networks])

  const { terms } = searchData
  const { networks } = terms

  /**
   * Adds a new skill to the search data if it does not already exist.
   *
   * @param {ISearchSkill} newItem - the new skill to be added
   * @return {void}
   */
  const addSkill = (newItem: ISearchSkill): void => {
    if (!newItem) {
      return
    }

    const { skillId, name } = newItem
    const { kind } = newItem.Skill
    const { terms } = searchData

    const skillExists = terms.skills.some(skill => skill.skillId === skillId)
    const industryExists = terms.industries.some(industry => industry.skillId === skillId)
    const roleExists = terms.roles.some(role => role.skillId === skillId)

    if (kind === 'skill') {
      if (skillExists) {
        setNotification({
          message: t('search.skillAlreadyExists'),
          type: 'warning',
          duration: 'short',
        })
        return
      }

      if (name.length > 0) {
        setSearchData({
          ...searchData,
          terms: {
            ...terms,
            skills: [...terms.skills, newItem],
          },
        })
      }
    } else if (kind === 'industry') {
      if (industryExists) {
        setNotification({
          message: t('search.industryAlreadyExists'),
          type: 'warning',
          duration: 'short',
        })
        return
      }

      if (name.length > 0) {
        setSearchData({
          ...searchData,
          terms: {
            ...terms,
            industries: [...terms.industries, newItem],
          },
        })
      }
    } else {
      if (roleExists) {
        setNotification({
          message: t('search.roleAlreadyExists'),
          type: 'warning',
          duration: 'short',
        })
        return
      }

      if (name.length > 0) {
        setSearchData({
          ...searchData,
          terms: {
            ...terms,
            roles: [...terms.roles, newItem],
          },
        })
      }
    }
  }

  /**
   * Adds a text parameter to the search data object if the newItem is not empty.
   *
   * @param {string} newText - the new text parameter to add
   */
  const addTextParameter = (newText: string) => {
    if (newText.length > 0) {
      setSearchData({
        ...searchData,
        terms: {
          ...searchData.terms,
          name: newText,
        },
      })
    }
  }

  /**
   * A function to handle the option change.
   *
   * @param {event} e - the event object
   * @param {any} value - the value of the option
   * @return {void}
   */
  const handleOptionChange = (e, value) => {
    if (!value) return
    setSearchData({ ...searchData, terms: { ...terms, networks: value } })
  }

  /**
   * Checks if the option is disabled based on the networks list.
   *
   * @param {Object} option - The option to check for disabled status
   * @return {boolean} True if the option is disabled, false otherwise
   */
  const isOptionDisabled = option => {
    return networks.find(item => item.id === option.id) ? true : false
  }

  /**
   * Renders tags based on the provided value and getTagProps function.
   *
   * @param {any} value - The value to render tags for
   * @param {function} getTagProps - The function to retrieve tag properties
   * @return {JSX.Element[]} An array of JSX elements representing the rendered tags
   */
  const renderTags = (value, getTagProps) => {
    return value.map((option, index) => (
      <Chip
        {...getTagProps({ index })}
        key={option.id}
        sx={{ mr: 0.5, borderRadius: 0.5 }}
        variant="outlined"
        size="small"
        label={chooseDBTranslation(i18n, option).name}
      />
    ))
  }

  return (
    <DataContext
      data={terms}
      onChange={values => setSearchData({ ...searchData, terms: { ...values } })}
      onSubmit={onSubmit}
      localeBase="search.quickSearch"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item sx={{ fontWeight: 'bold' }}>
              {t('search.from')}
            </Grid>
            <Grid item>
              <Autocomplete
                noOptionsText={t('controls.noOptionsText')}
                options={searchableNetworks ?? []}
                getOptionLabel={option => chooseDBTranslation(i18n, option).name}
                value={networks}
                isOptionEqualToValue={(option, value) => value && option.id === value.id}
                onChange={handleOptionChange}
                renderInput={params => (
                  <TextField
                    {...params}
                    sx={{ minWidth: 200 }}
                    name="networks"
                    size="small"
                    variant="standard"
                    margin="dense"
                  />
                )}
                filterOptions={(options, { inputValue }) => fuseFiltering(options, inputValue, ['translations.name'])}
                getOptionDisabled={isOptionDisabled}
                renderTags={renderTags}
                multiple
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SkillOrTextRequirement
            onAdd={addSkill}
            addTextParameter={addTextParameter}
            skills={options}
            anonymous={anonymous}
          />
        </Grid>
        <Grid item xs={12}>
          {t('search.keywords')}
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={onSubmit}
            disabled={!(!loading && networks.length > 0)}
            color="newPrimary"
          >
            {t('resources.searchEmployees.search')}
          </Button>
        </Grid>
      </Grid>
    </DataContext>
  )
}

export default QuickSearch
