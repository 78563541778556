import { IChangePassword } from 'types/accountInterfaces'
import { ILogin, IActivationUserData, IUserIdStatus } from 'types/userInterfaces'
import { SubAPI } from './API'

/**
 * Authentication API.
 * @notExported
 */
class AuthAPI extends SubAPI {
  /**
   * Log user in to the system.
   *
   * @param requestBody - Request body for the login.
   * @returns Authentication data or error message.
   */
  public login(requestBody: unknown): Promise<ILogin | string> {
    return this.api.post('login', requestBody)
  }

  /**
   * Log user out of the system.
   *
   * @returns Promise of user log out.
   */
  public logout(): Promise<void> {
    return this.api.get('/logout')
  }

  /**
   * Activates a user account.
   *
   * @param requestBody - Activation data.
   * @returns Promise of account activation.
   */
  public activate(requestBody: unknown): Promise<void> {
    return this.api.post('account/verify', requestBody)
  }

  /**
   * Sends a password reset email to the user.
   *
   * @param requestBody - Password reset data.
   * @returns Promise of password reset email.
   */
  public forgotPassword(requestBody: unknown, controller?: AbortController): Promise<void> {
    return this.api.post('account/password', requestBody, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Resets a user's password.
   *
   * @param requestBody - Password reset data.
   * @returns Promise of password reset.
   */
  public resetPassword(requestBody: unknown, controller?: AbortController): Promise<void> {
    return this.api.put('account/password/', requestBody, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get user data by token.
   *
   * @param token - Activation token.
   * @returns User data for activation.
   */
  public getUserDataByToken(token: string, controller?: AbortController): Promise<IActivationUserData> {
    return this.api.get(`/account/${token}/settings`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get user ID with Azure AD access token.
   *
   * @returns User ID and activation status.
   */
  public getUserId(): Promise<IUserIdStatus> {
    return this.api.get(`/azure/id`)
  }

  /**
   * Changes the password for the authenticated user.
   *
   * @param {IChangePassword} data - The change password data.
   * @param {AbortController} [controller] - An optional AbortController to cancel the request.
   * @return {Promise<void>} A promise that resolves when the password is successfully changed.
   */
  public changePassword(data: IChangePassword, controller?: AbortController): Promise<void> {
    return this.api.put('account/password/change', data, controller ? { signal: controller.signal } : undefined)
  }
}

export const authAPI = new AuthAPI()
