import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IPersonSkillOrIndustryOrRole } from 'types/cvsInterfaces'
import { IItemVisibility } from 'types/layoutInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { skillLevels } from 'utils/utils'
import SkillChip from '../SkillChip'
import VisibilityCheckbox from '../VisibilityCheckbox'
import colors from 'constants/colors'

/**
 * Renders the Skills component.
 *
 * @param {Object[]} items - An array of items.
 * @param {string} layout - The layout of the component.
 * @param {boolean} hideSection - Whether to hide the section.
 * @param {boolean} hideItem - Whether to hide the item.
 * @param {boolean} expanded - Whether the component is expanded.
 * @param {Function} setStatus - A function to set the status.
 * @param {string} type - The type of the component.
 * @return {JSX.Element} The rendered Skills component.
 * @notExported
 */
const Skills = ({ items, layout, hideSection, hideItem, expanded, setStatus, type }) => {
  const { t, i18n } = useTranslation()

  let hidden = false
  if (layout && layout.ItemVisibilities && layout.ItemVisibilities.length > 0) {
    const visibilities = layout.ItemVisibilities.filter((visibility: IItemVisibility) => visibility.type === type)
    if (visibilities.length > 0) {
      const visibilityMap = new Map(visibilities.map(item => [item.itemId, item.hidden]))
      hidden = items.every(value => visibilityMap.get(value.id) === true)
    }
  }

  const expert: IPersonSkillOrIndustryOrRole[] = []
  const advanced: IPersonSkillOrIndustryOrRole[] = []
  const intermediate: IPersonSkillOrIndustryOrRole[] = []
  const novice: IPersonSkillOrIndustryOrRole[] = []
  const fundamentals: IPersonSkillOrIndustryOrRole[] = []
  const noLevel: IPersonSkillOrIndustryOrRole[] = []

  for (const item of items) {
    if (item.level === 1) {
      fundamentals.push(item)
    }
    if (item.level === 2) {
      novice.push(item)
    }
    if (item.level === 3) {
      intermediate.push(item)
    }
    if (item.level === 4) {
      advanced.push(item)
    }
    if (item.level === 5) {
      expert.push(item)
    }
    if (!item.level) {
      noLevel.push(item)
    }
  }

  const getItemsSection = (count, array) => {
    return (
      <>
        {count > 0 && (
          <Box display="flex" flexDirection="row">
            <Box fontWeight={'bold'}>{t(skillLevels()[count - 1].label)}</Box>
          </Box>
        )}
        <Box display="block" width="100%" my={2}>
          {array.map(item => {
            let hiddenItem = false
            if (layout && layout.ItemVisibilities && layout.ItemVisibilities.length > 0) {
              const visibilities = layout.ItemVisibilities.filter(
                (visibility: IItemVisibility) => visibility.type === type
              )
              if (visibilities.length > 0) {
                hiddenItem = visibilities.find(
                  (value: IItemVisibility) => value.itemId === item.id && value.hidden === true
                )
              }
            }
            return (
              <SkillChip
                key={item.id}
                hidden={!!hiddenItem}
                name={chooseDBTranslation(i18n, item.Skill).name}
                type={type}
                itemId={item.id}
                onClick={hideItem}
              />
            )
          })}
        </Box>
        {count > 0 && <Divider />}
      </>
    )
  }

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={(e, expanded) => setStatus(expanded)}
      elevation={0}
      sx={{ borderRadius: '5px', border: `1px solid ${colors.borderColor}` }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          fontSize: '20px',
          color: theme => theme.palette.primary.main,
          fontWeight: 'bold',
          textTransform: 'capitalize',
        }}
      >
        <Grid container alignItems="center">
          <Grid item>
            <VisibilityCheckbox
              checked={hidden}
              onChange={(checked: boolean) => {
                hideSection(checked, type)
                hidden = !hidden
              }}
            />
          </Grid>
          <Grid item>{t(`print.layout.${type}`)}</Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid item xs={12}>
          {getItemsSection(5, expert)}
          {getItemsSection(4, advanced)}
          {getItemsSection(3, intermediate)}
          {getItemsSection(2, novice)}
          {getItemsSection(1, fundamentals)}
          {getItemsSection(0, noLevel)}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default React.memo(Skills)
