import { CardHeader, Grid, Typography } from '@mui/material'
import { cvAPI } from 'api/CvAPI'
import { layoutAPI } from 'api/LayoutAPI'
import { organizationAPI } from 'api/OrganizationAPI'
import { personAPI } from 'api/PersonAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { ToolbarItems } from 'Components/General/Toolbar'
import { getLayoutInitialData } from 'Components/reusable/DataContext/InitialData'
import { cvPrintLayoutSchema } from 'Components/reusable/DataContext/ValidationSchema'
import BackButton from 'Components/reusable/IconButtons/BackButton'
import colors from 'constants/colors'
import { useCountryData, useLanguageData, useUser } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, Link, useParams } from 'react-router-dom'
import { ICv } from 'types/cvsInterfaces'
import { IError } from 'types/error'
import { ILayout, IOrganizationColors } from 'types/layoutInterfaces'
import { IPerson } from 'types/userInterfaces'
import LayoutControls from './LayoutControls'
import LayoutModal from './LayoutModal'
import Preview from './Preview'
import PrintControls from './PrintControls'
import { usePreviewData } from './PrintPreviewProvider'
import LoadingIndicator from 'Components/reusable/LoadingIndicator'
import { useIsComponentMounted } from 'hooks/util'

/**
 * Resolves the image data from the given image source.
 *
 * @param {string} imageSrc - The source of the image.
 * @return {Promise<string>} A promise that resolves to the image data.
 */
export async function resolveImageData(
  imageSrc: string,
  isComponentMounted?: React.MutableRefObject<boolean>
): Promise<string> {
  if (imageSrc && imageSrc.startsWith('http')) {
    const blob = await fetch(imageSrc)
      .then(r => r.blob())
      .catch(err => {
        console.error(err)
      })
    if (isComponentMounted && !isComponentMounted.current) return ''
    if (blob) {
      const reader = new FileReader()
      return new Promise((resolve, reject) => {
        reader.onload = () => {
          if (!reader.result) {
            reject('No result from reader')
            return
          }
          resolve(reader.result.toString())
        }
        reader.onerror = reject
        reader.readAsDataURL(blob)
      })
    }
  }

  return imageSrc
}

/**
 * Generates the function comment for the given function body.
 *
 * @return {JSX.Element} - The JSX element.
 * @notExported
 */
const CVPrint: React.FC = () => {
  const isComponentMounted = useIsComponentMounted()
  const { t } = useTranslation()
  const location = useLocation()
  const { user, ready } = useUser()
  const [backendError, setBackendError] = useState<IError>()
  const [cvLayouts, setCvLayouts] = useState<ILayout[]>()
  const [selectedLayout, setSelectedLayout] = useState<ILayout>()
  const [loading, setLoading] = useState<boolean>()
  const [cvId, setCvId] = useState<number>()
  const [personId, setPersonId] = useState<number>()
  const [cvs, setCvs] = useState<ICv[]>()
  const [open, setOpen] = useState<boolean>(false)
  const [refreshLayout, setRefreshLayout] = useState<Date>()
  const [profileImage, setProfileImage] = useState<string | null>(null)
  const [orgLogo, setOrgLogo] = useState<string | null>(null)
  const [orgColors, setOrgColors] = useState<IOrganizationColors>({
    color: colors.primary,
    accentColor: colors.secondary,
  })
  const [ownCompanyBranding, setOwnCompanyBranding] = useState<boolean>(false)
  const [about, setAbout] = useState<IPerson>()
  const { countries } = useCountryData()
  const { languages } = useLanguageData()
  const { data, setVisibleLayout, setData, loading: layoutLoading } = usePreviewData()
  const { cvId: matchCvId } = useParams()

  useEffect(() => {
    if (layoutLoading) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [layoutLoading])

  useEffect(() => {
    setVisibleLayout(selectedLayout ?? cvLayouts?.find(layout => layout.id === 0))
  }, [selectedLayout, cvLayouts])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (matchCvId) {
        try {
          const about: IPerson = await cvAPI.getCvAbout(parseInt(matchCvId, 10), controller)
          if (!isComponentMounted.current) return
          setAbout(about)
          setPersonId(about.id)
          setCvId(parseInt(matchCvId, 10))
        } catch (error) {
          setBackendError(error as IError)
        }
      } else {
        setPersonId(user?.Person?.id)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [matchCvId])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (personId !== null && typeof personId !== 'undefined' && ready) {
        try {
          setLoading(true)
          const personData = await personAPI.getPersonData(personId, controller)
          if (!isComponentMounted.current) return
          if (personData && personData.CVs && matchCvId) {
            setCvs(personData.CVs)
          } else if (personData && personData.CVs && personData.CVs.length > 0) {
            const defaultCV = personData.CVs.find((cv: ICv) =>
              personData.defaultCvId ? cv.id === personData.defaultCvId : cv.original === true
            )
            if (defaultCV) {
              setCvId(defaultCV.id)
            } else {
              setCvId(personData.CVs[0].id)
            }
            const about: IPerson = await cvAPI.getCvAbout(personData.CVs[0].id, controller)
            if (!isComponentMounted.current) return
            setAbout(about)
            setCvs(personData.CVs)
          }
        } catch (err) {
          setBackendError(err as IError)
        } finally {
          if (isComponentMounted.current) setLoading(false)
        }
      }
    })()

    return () => {
      controller.abort()
    }
  }, [matchCvId, ready, personId])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (cvId && about) {
        try {
          setLoading(true)
          const cv: ICv | undefined = await cvAPI.getCvData(cvId, controller)
          if (cv) {
            if (!isComponentMounted.current) return
            const CvData = {
              about: {
                cvId,
                firstName: about.firstName,
                lastName: about.lastName,
                telephone: about.telephone,
                email: about.Account?.email,
                streetAddress: about.streetAddress,
                postalCode: about.postalCode,
                city: about.city,
                country: about.country,
                Organization: about.Site?.Organization,
                translations: cv.translations,
              },
              degrees: cv.Education,
              courses: cv.Courses,
              certificates: cv.Certificates,
              employments: cv.Employers,
              projects: cv.Projects,
              references: cv.References,
              skills: cv.PersonSkills,
              industries: cv.Industries,
              roles: cv.Roles,
              languages: cv.LanguageSkills,
              urls: cv.Urls,
            }
            setData(CvData)
          }
        } catch (err) {
          setBackendError(err as IError)
        }
        if (!isComponentMounted.current) return
        setLoading(false)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [cvId, about])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (cvId && user && !selectedLayout) {
        try {
          const layouts = cvLayouts?.length ? cvLayouts : await getLayouts(cvId, controller)
          if (!isComponentMounted.current) return

          if (layouts) setSelectedLayout(layouts[0])
        } catch (err) {
          setBackendError(err as IError)
        }
      }
    })()

    return () => {
      controller.abort()
    }
  }, [cvId, open, user])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (cvId && user && selectedLayout) {
        setLoading(true)
        try {
          const layouts = await getLayouts(cvId, controller)
          if (!isComponentMounted.current) return

          if (layouts) setSelectedLayout(layouts.find(layout => layout.id === selectedLayout.id))
        } catch (err) {
          setBackendError(err as IError)
        }
        setLoading(false)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [refreshLayout, cvId, user])

  useEffect(() => {
    const controller = new AbortController()

    if (personId && data && selectedLayout && !profileImage) {
      ;(async () => {
        try {
          setLoading(true)

          if (profileImage === null) {
            const imageSrc = await personAPI.getProfileImage(personId)
            const imageData = await resolveImageData(imageSrc, isComponentMounted)
            if (!isComponentMounted.current) return
            setProfileImage(imageData)
          }

          if (data.about.Organization) {
            await getOrganizationLogoAndColors(data.about.Organization.id, data.about.Organization, controller)
          } else {
            setOrgLogo('')
          }
        } catch (error) {
          setBackendError(error as IError)
        } finally {
          if (isComponentMounted.current) setLoading(false)
        }
      })()
    }

    return () => {
      controller.abort()
    }
  }, [personId, data, selectedLayout, user])

  useEffect(() => {
    const controller = new AbortController()

    if (selectedLayout) {
      ;(async () => {
        if (user && ownCompanyBranding) {
          await getOrganizationLogoAndColors(user.organizationId, user.Organization, controller)
        } else {
          await getOrganizationLogoAndColors(data?.about?.Organization?.id, data?.about?.Organization, controller)
        }
      })()
    }

    return () => {
      controller.abort()
    }
  }, [ownCompanyBranding])

  const getOrganizationLogoAndColors = async (organizationId, organization, controller?: AbortController) => {
    try {
      const imageSrc = await organizationAPI.getOrgLogo(organizationId, controller)
      const imageData = await resolveImageData(imageSrc, isComponentMounted)
      if (!isComponentMounted.current) return
      setOrgLogo(imageData)
      setOrgColors({ color: organization.color, accentColor: organization.accentColor })
    } catch (error) {
      setBackendError(error as IError)
    }
  }

  const getLayouts = async (cvId: number, controller?: AbortController) => {
    setLoading(true)
    const layouts: ILayout[] = await layoutAPI.getAll(cvId, controller)
    if (!isComponentMounted.current) return
    layouts.unshift({
      id: 0,
      name: 'default',
      accountId: 0,
      cvId,
      ItemVisibilities: [],
      ItemOrders: [],
      showContactInfo: false,
      hideProfileImage: false,
      hideName: false,
      hideLastUsed: false,
      showCertificateExpiry: false,
    })
    if (layouts !== cvLayouts) {
      setCvLayouts(layouts)
    }
    setLoading(false)
    return layouts
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  if (!cvId) {
    return <div>{t('print.noCV')}</div>
  }

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        style={{ padding: '34px', paddingBottom: '130px' }}
      >
        {personId && (
          <ToolbarItems>
            <Link to={user?.Person?.id === personId ? '/profile' : `/profile/${personId}`} state={location?.state}>
              <BackButton />
            </Link>
          </ToolbarItems>
        )}
        <Grid item xs={12} md={12} lg={4}>
          <CardHeader title={t('print.layout.title')} />
          <PrintControls
            setOpen={setOpen}
            change={setSelectedLayout}
            layouts={cvLayouts}
            selected={selectedLayout}
            refresh={date => {
              setRefreshLayout(date)
            }}
            setOwnCompanyBranding={setOwnCompanyBranding}
            ownCompanyBranding={ownCompanyBranding}
            organizationId={data?.about?.Organization?.id}
            setCv={setCvId}
            cvs={cvs}
            selectedId={cvId}
          />
          {selectedLayout?.name === 'default' ? (
            <Typography style={{ color: colors.gray }}>{t('printpreview')}</Typography>
          ) : data ? (
            <LayoutControls data={data} setOpen={setOpen} />
          ) : null}
        </Grid>
        {data && profileImage !== null && orgLogo !== null && countries && languages && (
          <Grid item xs={12} md={12} lg={8}>
            <Preview
              countries={countries}
              languages={languages}
              profileImage={profileImage}
              orgLogo={orgLogo}
              orgColors={orgColors}
              loading={loading}
            />
          </Grid>
        )}
      </Grid>
      {open && (
        <LayoutModal
          onClose={() => setOpen(false)}
          cvId={cvId}
          setSelectedLayout={setSelectedLayout}
          refresh={setRefreshLayout}
          initialData={getLayoutInitialData()}
          schema={cvPrintLayoutSchema()}
          localeBase="print.layout"
          hideLanguageHelperText={true}
          maxWidth="xs"
          fullWidth={true}
          submitOnModal={true}
          noEscClose
        />
      )}
    </>
  )
}

export default CVPrint
