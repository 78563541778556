import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import * as serviceWorker from './serviceWorker'
import CaleoRouter from './Routes'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import './i18n'
import { LoginProvider } from 'hooks/login'
import { UserProvider } from 'hooks/user'
import { SalesPoolProvider } from 'Components/General/SalesPoolProvider/SalesPoolProvider'
import CaleoThemeProvider from './ThemeProvider'
import { SearchProvider } from 'Components/General/SearchProvider/SearchProvider'
import { MenuProvider } from 'Components/General/NavigationMenu/menu'
import { PrintPreviewProvider } from 'pages/CVPrint/PrintPreviewProvider'
import { LoadingProvider } from 'Components/reusable/LoadingProvider'
import { ProposalSearchProvider } from 'Components/General/ProposalSearchProvider/ProposalSearchProvider'
import { NotificationProvider } from 'Components/reusable/Notification'
import ChatProvider from 'Components/General/ChatContext'
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from 'api/authConfig'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from 'Routes/ErrorFallback'
import ChatNotification from 'Components/General/ChatContext/ChatNotification'
import history from './browserHistory'

// MSAL configuration for Azure AD login
// MSAL needs to be initialized before use
export const msalInstance = new PublicClientApplication(msalConfig)
await msalInstance.initialize()

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    {window.location.pathname !== '/healt' ? (
      // @ts-expect-error enstable_HistoryRouter does not require all the same props as other V6 routers
      <HistoryRouter history={history}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <MsalProvider instance={msalInstance}>
            <CaleoThemeProvider>
              <NotificationProvider>
                <LoginProvider>
                  <LoadingProvider>
                    <UserProvider>
                      <SalesPoolProvider>
                        <SearchProvider>
                          <ProposalSearchProvider>
                            <PrintPreviewProvider>
                              <MenuProvider>
                                <ChatProvider>
                                  <CaleoRouter />
                                  <ChatNotification />
                                </ChatProvider>
                              </MenuProvider>
                            </PrintPreviewProvider>
                          </ProposalSearchProvider>
                        </SearchProvider>
                      </SalesPoolProvider>
                    </UserProvider>
                  </LoadingProvider>
                </LoginProvider>
              </NotificationProvider>
            </CaleoThemeProvider>
          </MsalProvider>
        </ErrorBoundary>
      </HistoryRouter>
    ) : null}
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
