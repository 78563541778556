import { CssBaseline } from '@mui/material'
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import colors from 'constants/colors'
import React, { useMemo } from 'react'
import { TEXT_FIELD_MAX_LENGTH } from './constants/caleo-constants'
import { fiFI, enUS } from '@mui/material/locale'
import { useTranslation } from 'react-i18next'

/**
 * This file is the ThemeProvider component.
 * It provides a theme for the entire application.
 * The theme can be customized by modifying the `customTheme` object.
 * @notExported
 */
declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary']
    newPrimary: Palette
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary']
    newPrimary: PaletteOptions
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    newPrimary: true
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    newPrimary: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    newPrimary: true
  }
}

declare module '@mui/material/Link' {
  interface LinkPropsColorOverrides {
    newPrimary: true
  }
}

declare module '@mui/material/Tabs' {
  interface TabsPropsIndicatorColorOverrides {
    newPrimary: true
  }
}

const theme = createTheme()

/**
 * Render a CaleoThemeProvider component.
 *
 * @param {React.FC} children - The child components to be rendered.
 * @return {void}
 * @notExported
 */
const CaleoThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { i18n } = useTranslation()

  const customTheme = useMemo(
    () =>
      createTheme(
        theme,
        {
          palette: {
            primary: {
              main: colors.primary,
            },
            secondary: {
              light: colors.blueHoverHalo,
              main: colors.secondary,
              dark: colors.secondaryHoverHalo,
              contrastText: colors.background,
            },
            background: {
              paper: colors.background,
              default: colors.secondaryBackground,
            },
            text: {
              secondary: colors.description,
              primary: colors.description,
            },
            error: {
              main: colors.red,
              dark: colors.redButton,
              light: colors.redButtonHoverHalo,
              contrastText: colors.background,
            },
            warning: {
              main: colors.orange,
              dark: colors.orangeHover,
              light: colors.orangeButtonHoverHalo,
              contrastText: colors.background,
            },
            success: {
              main: colors.green,
              dark: colors.greenButtonHover,
              light: colors.greenButtonHoverHalo,
              contrastText: colors.background,
            },
            neutral: {
              main: colors.gray,
              dark: colors.black,
              light: colors.borders,
              contrastText: colors.hoverHalo,
            },
            info: {
              main: colors.purple,
              contrastText: colors.background,
            },
            newPrimary: theme.palette.augmentColor({
              color: {
                main: '#0d6aff',
              },
            }),
          },
          typography: {
            fontFamily: ['Helvetica', 'Roboto', 'sans-serif', 'Raleway'].join(','),
            h1: {
              fontSize: '2.375rem',
              textTransform: 'capitalize',
              fontFamily: 'Raleway',
            },
            h2: {
              fontSize: '1.75rem',
              textTransform: 'capitalize',
              fontFamily: 'Raleway',
            },
            h3: {
              fontSize: '1.75rem',
              textTransform: 'capitalize',
              fontFamily: 'Raleway',
            },
          },
          // Theming by specific components below overrides
          components: {
            MuiCssBaseline: {
              styleOverrides: {
                '@font-face': {
                  fontFamily: 'Raleway',
                  fontStyle: 'normal',
                  fontWeight: 900,
                  fontDisplay: 'swap',
                  src: 'url(https://fonts.gstatic.com/s/raleway/v22/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2) format("woff2")',
                  unicodeRange:
                    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
                },
              },
            },
            // Cards
            MuiCardHeader: {
              styleOverrides: {
                root: {
                  minHeight: '44px',
                },
                avatar: {
                  color: colors.primary,
                  height: '24px',
                },
                title: {
                  color: colors.primary,
                  fontSize: '1.75rem',
                  verticalAlign: 'middle',
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  fontFamily: 'Raleway',
                  letterSpacing: '1.4px',
                  whiteSpace: 'nowrap',
                },
              },
            },
            MuiCard: {
              styleOverrides: {
                root: {
                  display: 'flex',
                  padding: '18px',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                },
              },
            },
            // Buttons and IconButtons
            MuiButton: {
              styleOverrides: {
                root: {
                  textTransform: 'none',
                  fontSize: '0.938rem',
                },
              },
            },
            // Modals and Dialogs
            MuiDialogTitle: {
              styleOverrides: {
                root: {
                  minHeight: '76px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  fontSize: '1.75rem',
                  textTransform: 'capitalize',
                  fontFamily: 'Raleway',
                },
              },
            },
            MuiBackdrop: {
              styleOverrides: {
                root: {
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                },
              },
            },
            // Header and Footer
            MuiToolbar: {
              styleOverrides: {
                root: {
                  background: colors.secondaryBackground,
                  justifyContent: 'space-between',
                },
                regular: {
                  height: '55px',
                },
                dense: {
                  height: '74px',
                },
              },
            },
            // Navbar icons and texts
            MuiListItemIcon: {
              styleOverrides: {
                root: {
                  minWidth: '32px',
                  color: colors.secondaryBackground,
                },
              },
            },
            MuiListItemText: {
              styleOverrides: {
                root: {
                  color: colors.secondaryBackground,
                },
              },
            },
            MuiAccordion: {
              styleOverrides: {
                root: { backgroundColor: colors.secondaryBackground },
              },
            },
            MuiTextField: {
              defaultProps: {
                inputProps: {
                  maxLength: TEXT_FIELD_MAX_LENGTH,
                },
              },
            },
            MuiInputLabel: {
              styleOverrides: {
                root: {
                  fontSize: '1rem',
                  fontWeight: 'bold',
                },
              },
            },
          },
        },
        i18n.language === 'fi' ? fiFI : enUS
      ),
    [i18n.language]
  )

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default CaleoThemeProvider
