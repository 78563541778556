import React, { useEffect, useMemo } from 'react'
import { alpha, useTheme } from '@mui/material/styles'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import { Grid, InputAdornment, InputBase } from '@mui/material'
import { debounce } from 'lodash'
import { InputField } from 'Components/reusable/InputFields/InputField'

/**
 * Global filter component for custom table.
 *
 * @param rowCount - Row count.
 * @param globalFilter - Global filter value.
 * @param setGlobalFilter - Global filter value setter.
 * @param newStyle - New style.
 * @returns Global filter component.
 * @notExported
 */
const GlobalFilter = ({ rowCount, setGlobalFilter, newStyle }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  })

  const handleChange = e => {
    setGlobalFilter(e.target.value || undefined)
  }

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 300)
  }, [])

  return (
    <Grid
      sx={{
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: 'auto',
        },
      }}
    >
      {newStyle ? (
        <InputField
          onChange={debouncedResults}
          placeholder={`${rowCount} ${t('custom-table.records')}`}
          inputProps={{ 'aria-label': 'search' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          fullWidth
          variant="outlined"
        />
      ) : (
        <>
          <Grid
            sx={{
              width: theme.spacing(7),
              height: '100%',
              position: 'absolute',
              pointerEvents: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <SearchIcon />
          </Grid>
          <InputBase
            onChange={debouncedResults}
            placeholder={`${rowCount} ${t('custom-table.records')}`}
            sx={{
              root: { color: 'inherit' },
              input: {
                padding: theme.spacing(1, 1, 1, 7),
                transition: theme.transitions.create('width'),
                width: '100%',
                [theme.breakpoints.up('md')]: {
                  width: 200,
                },
              },
            }}
            inputProps={{ 'aria-label': 'search' }}
            fullWidth
          />
        </>
      )}
    </Grid>
  )
}

export default GlobalFilter
