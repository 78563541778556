import { getResetPasswordInitialData } from 'Components/reusable/DataContext/InitialData'
import { resetPasswordSchema } from 'Components/reusable/DataContext/ValidationSchema'
import { authAPI } from 'api/AuthAPI'
import { useNotification } from 'Components/reusable/Notification'
import { IResetPassword } from 'types/accountInterfaces'
import { Grid } from '@mui/material'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import DataContext, { NewOrExisting } from 'Components/reusable/DataContext'
import LoadingIndicator from 'Components/reusable/LoadingIndicator'
import { useLogin, useUser } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { IError } from 'types/error'
import CaleoPrimaryButton from 'Components/reusable/Buttons/CaleoPrimaryButton'
import LoginPageCard from 'Components/reusable/CaleoCustomComponents/LoginPageCard'
import LoginPageCardWrapper from 'Components/reusable/CaleoCustomComponents/LoginPageCardWrapper'
import { useTranslation } from 'react-i18next'
import { useIsComponentMounted } from 'hooks/util'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'

type T = IResetPassword

/**
 * Renders the ResetPassword component.
 *
 * @return {JSX.Element} The rendered ResetPasswordCard component.
 * @notExported
 */
const ResetPassword = () => {
  const isComponentMounted = useIsComponentMounted()
  const { t } = useTranslation()
  const { setNotification } = useNotification()
  const [submitError, setSubmitError] = useState<IError>()
  const navigate = useNavigate()

  const [data, setData] = useState<NewOrExisting<T>>(getResetPasswordInitialData())
  const schema = resetPasswordSchema()
  const localeBase = 'resetPassword'
  const [submitLocally, setSubmitLocally] = useState<boolean>(false)
  const { loggingIn, loggedIn, loginLoading } = useLogin()
  const location = useLocation()
  const { groupsReady, groups } = useUser()
  const { token } = useParams()
  const [passwordError, setPasswordError] = useState<string>()

  const buttonsEnabled = !loggingIn

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (submitLocally) {
        const submitData = {
          password: data.password,
          token: token,
        }
        try {
          await authAPI.resetPassword(submitData, controller)
          if (!isComponentMounted.current) return
          setNotification({
            message: t('password.activated'),
            type: 'success',
            duration: 'long',
          })
          navigate('/login')
        } catch (err) {
          const error = err as IError
          if (error.response?.status === 400 && error.response?.data === 'same-password-error') {
            setPasswordError(error.response?.data)
          } else {
            setSubmitError(error)
            setSubmitLocally(false)
          }
        }
      }
    })()

    return () => {
      controller.abort()
    }
  }, [submitLocally])

  useEffect(() => {
    if (loggedIn && groupsReady) {
      if (location.state?.url) {
        return navigate(location.state?.url)
      } else if (groups && groups.includes('sales')) {
        return navigate('/employees')
      } else {
        return navigate('/profile')
      }
    }
  }, [loggedIn, groupsReady])

  useEffect(() => {
    if (passwordError) {
      setPasswordError(undefined)
      setSubmitLocally(false)
    }
  }, [data.password, data.repeatPassword])

  if (!data) {
    return <LoadingIndicator />
  }

  if (submitError) {
    return <ErrorOverlay error={submitError} setOpen={setSubmitError} />
  }

  return (
    <LoginPageCardWrapper>
      {loginLoading ? (
        <LoadingIndicator />
      ) : (
        <DataContext
          data={data}
          onChange={setData}
          onSubmit={() => setSubmitLocally(true)}
          schema={schema}
          localeBase={localeBase}
        >
          <LoginPageCard header={t('resetPassword.header')}>
            <Grid container>
              <Grid item xs={12} mt={2} mb={passwordError ? 0 : 2}>
                <DataContext.PasswordField<IResetPassword>
                  field="password"
                  autoComplete="new-password"
                  name="new-password"
                  required
                  fullWidth
                />
                <DataContext.PasswordField<IResetPassword>
                  field="repeatPassword"
                  autoComplete="new-password2"
                  name="new-password2"
                  required
                  fullWidth
                />
              </Grid>
              {passwordError && (
                <Grid item xs={12} mb={2}>
                  <CardContentText type="error">{t(passwordError)}</CardContentText>
                </Grid>
              )}
            </Grid>
            <Grid container justifyContent="center" spacing={1} alignItems="center">
              <Grid item xs={12} container alignItems="center" spacing={1}>
                <Grid item xs="auto">
                  <DataContext.Validation>
                    {({ valid }) => (
                      <CaleoPrimaryButton
                        valid={(valid && !passwordError) || !buttonsEnabled}
                        clickAction={() => setSubmitLocally(true)}
                        label={t('password.reset')}
                        loading={false}
                      />
                    )}
                  </DataContext.Validation>
                </Grid>
              </Grid>
            </Grid>
          </LoginPageCard>
        </DataContext>
      )}
    </LoginPageCardWrapper>
  )
}

export default ResetPassword
