import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { BASE_URL } from '../constants/env'
import { getRefreshToken, getToken, setToken, getAzureToken } from '../utils/storage'
import browserHistory from '../browserHistory'
import { IError } from 'types/error'
import { msalInstance } from 'index'
import {
  STORAGE_AZURE_TOKEN_KEY_NAME,
  STORAGE_AZURE_ACCOUNT_ID,
  STORAGE_REFRESH_TOKEN_KEY_NAME,
  STORAGE_TOKEN_KEY_NAME,
} from '../constants/index'
import { scopes } from './authConfig'
import { removeNonCaleoStorage } from 'utils/utils'

/**
 * Headers type
 * @notExported
 */
type Headers = Record<string, string | undefined>

/**
 * Default headers
 * @notExported
 */
interface DefaultHeaders extends Headers {
  'Accept-Language': string
  'Content-Type': string
  Accept: string
  Authorization?: string
}

export abstract class SubAPI {
  api: API

  constructor() {
    this.api = api
  }
}

/**
 * API class for initialising axios and handlers for requests and responses.
 * @notExported
 * @class
 * @name API
 * @property {AxiosInstance}
 * @notExported
 */
class API {
  // Axios provider
  private apiProvider: AxiosInstance
  // backend base URL
  private baseUrl: string
  constructor() {
    this.baseUrl = BASE_URL
    this.apiProvider = axios.create({
      baseURL: this.baseUrl,
      timeout: 300000,
      withCredentials: true,
    })

    // interceptor for handling automatic token refreshing and error situations with azure token
    this.apiProvider.interceptors.response.use(
      (response: AxiosResponse) => {
        return response
      },
      async error => {
        const originalRequest: AxiosRequestConfig = error.config
        const refreshToken: string | null = await getRefreshToken()

        if (error.name === 'AbortError' || error.name === 'CanceledError') {
          return Promise.resolve('Aborted request!')
        }

        if (error?.response?.status === 403 && originalRequest.url === `/login/refresh`) {
          // remove tokens and redirect to login
          removeNonCaleoStorage()
          localStorage.removeItem(STORAGE_TOKEN_KEY_NAME)
          localStorage.removeItem(STORAGE_REFRESH_TOKEN_KEY_NAME)
          localStorage.removeItem(STORAGE_AZURE_TOKEN_KEY_NAME)
          localStorage.removeItem(STORAGE_AZURE_ACCOUNT_ID)
          browserHistory.push('/login')
          return Promise.reject(error)
        }

        if (error?.response?.status === 403 && originalRequest.url !== `/login/refresh`) {
          browserHistory.push('/403')
          return Promise.reject(error)
        }

        if (error?.response?.status === 404) {
          browserHistory.push('/404', { url: '/404' })
          return Promise.reject(error)
        }

        // if 401 refresh token and retry
        if (error?.response?.status === 401) {
          // if logout unsuccessful reject
          if (originalRequest.url === `/logout`) {
            browserHistory.push('/login')
            return Promise.reject(error)
          }

          // if refresh unsucessful reject
          if (originalRequest.url === `/login/refresh`) {
            removeNonCaleoStorage()
            localStorage.removeItem(STORAGE_TOKEN_KEY_NAME)
            localStorage.removeItem(STORAGE_REFRESH_TOKEN_KEY_NAME)
            localStorage.removeItem(STORAGE_AZURE_TOKEN_KEY_NAME)
            localStorage.removeItem(STORAGE_AZURE_ACCOUNT_ID)
            browserHistory.push('/login')
            return Promise.reject(error)
          }

          // if azure refresh unsucessful reject
          if (originalRequest.url === '/azure/id') {
            localStorage.clear()
            return Promise.reject(error)
          }

          try {
            const azureToken = await getAzureToken()
            const accountId = localStorage.getItem(STORAGE_AZURE_ACCOUNT_ID)

            if (azureToken && azureToken.length && accountId && accountId.length && !refreshToken) {
              localStorage.removeItem(STORAGE_REFRESH_TOKEN_KEY_NAME)
              localStorage.removeItem(STORAGE_TOKEN_KEY_NAME)

              const account = accountId ? msalInstance.getAccount({ homeAccountId: accountId }) : null
              if (account) {
                msalInstance.setActiveAccount(account)
                // request for new token
                const res = await msalInstance.acquireTokenSilent({
                  account: account,
                  scopes: scopes,
                  redirectUri: '/healt',
                })
                // set new token and retry
                if (res.accessToken && res.account.homeAccountId) {
                  localStorage.setItem(STORAGE_AZURE_TOKEN_KEY_NAME, res.accessToken)
                  localStorage.setItem(STORAGE_AZURE_ACCOUNT_ID, res.account.homeAccountId)
                  if (originalRequest.headers) {
                    originalRequest.headers['Authorization'] = `Bearer ${res.accessToken}`
                  } else {
                    localStorage.clear()
                    throw 'No headers in original request!'
                  }
                  // retry original request
                  try {
                    return await this.apiProvider.request(originalRequest)
                  } catch (error) {
                    if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                      localStorage.clear()
                      browserHistory.push('/login', { url: window.location.pathname })
                      // Since the original request can not be resolved
                      // due to authentication issues, we're going to
                      // never call its .then and .reject callbacks. This
                      // can be achieved by returning a forever-pending
                      // Promise.
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      return new Promise(() => {})
                    } else {
                      console.error('Request failed:')
                      localStorage.clear()
                      throw error
                    }
                  }
                } else {
                  console.error('Azure token refresh failed')
                  localStorage.clear()
                  return browserHistory.push('/login', { url: window.location.pathname })
                }
              } else {
                console.error('Azure account not found!')
                localStorage.clear()
                return browserHistory.push('/login', { url: window.location.pathname })
              }
            } else {
              localStorage.removeItem(STORAGE_AZURE_ACCOUNT_ID)
              localStorage.removeItem(STORAGE_AZURE_TOKEN_KEY_NAME)

              if (refreshToken) {
                // request for new token
                const res = await axios.post(`${originalRequest.baseURL}/login/refresh`, { token: refreshToken })
                // set new token and retry
                if (res.status === 200) {
                  await setToken(res.data)
                  if (originalRequest.headers) {
                    originalRequest.headers['Authorization'] = `Bearer ${res.data}`
                  } else {
                    localStorage.clear()
                    throw 'No headers in original request!'
                  }
                  // retry originalRequest
                  try {
                    return await this.apiProvider.request(originalRequest)
                  } catch (error) {
                    if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                      localStorage.clear()
                      browserHistory.push('/login', { url: window.location.pathname })
                      // Since the original request can not be resolved
                      // due to authentication issues, we're going to
                      // never call its .then and .reject callbacks. This
                      // can be achieved by returning a forever-pending
                      // Promise.
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      return new Promise(() => {})
                    } else {
                      console.error('Request failed:')
                      localStorage.clear()
                      throw error
                    }
                  }
                } else {
                  console.error('Token refresh failed')
                  localStorage.clear()
                  return browserHistory.push('/login', { url: window.location.pathname })
                }
              } else {
                console.error('Refresh token not found!')
                localStorage.clear()
                return browserHistory.push('/login', { url: window.location.pathname })
              }
            }
          } catch (error) {
            console.error('Error in token refresh:', error)
            localStorage.clear()
            return browserHistory.push('/login', { url: window.location.pathname })
          }
        }

        if (
          error?.response?.status === 400 &&
          error.response.data.key &&
          error.response.data.key.includes('azureLogin')
        ) {
          console.error('Azure login error!')
          return Promise.reject(error.response.data)
        }

        // if error is not yet handled, reject promise with error
        return Promise.reject(error)
      }
    )
  }

  /**
   * Asserts the response from the API. If the response is not valid, it throws an error.
   *
   * @param request - Request data
   * @returns Axios response
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static assert(request: AxiosResponse<any>) {
    try {
      const res = request
      return res.data
    } catch (error) {
      if (axios.isAxiosError(error)) throw API.errorHandler(error)
    }
  }

  /**
   * Handles errors from the API.
   *
   * @param error - Returned Axios error.
   * @returns Formatted error object.
   */
  private static errorHandler(error: AxiosError): IError {
    if (error && error.response) {
      const data = error.response.data as {
        status: number
        parentError: Error | string
        key: string
        id: string
      }

      return {
        status: data.status,
        error: data.parentError,
        key: data.key ?? '',
        id: data.id,
      }
    }
    return {
      status: 500,
      error: new Error('Error requesting data!'),
      key: 'error.generic',
      id: '',
    }
  }

  /**
   * Creates default headers for the API request.
   *
   * @returns Default headers including authorization with access token.
   */
  private static async getDefaultHeaders(): Promise<DefaultHeaders> {
    const headers: DefaultHeaders = {
      'Accept-Language': 'en_US',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }

    const azureToken = localStorage.getItem(STORAGE_AZURE_TOKEN_KEY_NAME)
    if (azureToken) {
      headers.Authorization = `Bearer ${azureToken}`
    } else {
      const token = await getToken()
      if (token) {
        headers.Authorization = `Bearer ${token}`
      }
    }

    return headers
  }

  /**
   * Creates headers for the API request.
   *
   * @param headers - Custom headers for the request.
   * @returns Request headers.
   */
  private static async getHeaders(headers: Headers | Record<string, string> | undefined = {}): Promise<Headers> {
    const defaultHeaders = await API.getDefaultHeaders()
    return { ...defaultHeaders, ...headers }
  }

  /**
   * Post request to the API.
   *
   * @param url - URL to send the request to.
   * @param body - Body of the request.
   * @param headers - Headers for the request.
   * @param baseUrl - Base API URL.
   * @param otherOptions - Additional request options.
   * @returns Axios response.
   */
  public async post<RequestBody, ResponseBody>(
    url: string,
    body: RequestBody,
    otherOptions?: AxiosRequestConfig,
    headers?: Headers,
    baseUrl: string = this.baseUrl
  ): Promise<ResponseBody> {
    const requestHeaders = await API.getHeaders(headers)

    return API.assert(
      await this.apiProvider.post(`${baseUrl}/${url}`, body, {
        headers: requestHeaders,
        ...otherOptions,
      })
    )
  }

  /**
   * Get request to the API.
   *
   * @param url - URL to send the request to.
   * @param params - Parameters of the request.
   * @param headers - Headers for the request.
   * @param otherOptions - Additional request options.
   * @returns Axios response.
   */
  public async get<ResponseBody, Params = undefined>(
    url: string,
    otherOptions?: AxiosRequestConfig,
    params?: Params,
    headers?: Headers
  ): Promise<ResponseBody> {
    const requestHeaders = await API.getHeaders(headers)
    return API.assert(
      await this.apiProvider.get(url, {
        params,
        headers: requestHeaders,
        ...otherOptions,
      })
    )
  }

  /**
   * Delete request to the API.
   *
   * @param url - URL to send the request to.
   * @param headers - Headers for the request.
   * @param otherOptions - Additional request options.
   * @returns Axios response.
   */
  public async delete<ResponseBody>(
    url: string,
    otherOptions?: AxiosRequestConfig,
    headers?: Headers
  ): Promise<ResponseBody> {
    const requestHeaders = await API.getHeaders(headers)
    return API.assert(
      await this.apiProvider.delete(url, {
        headers: requestHeaders,
        ...otherOptions,
      })
    )
  }

  /**
   * Put request to the API.
   *
   * @param url - URL to send the request to.
   * @param body - Body of the request.
   * @param headers - Headers of the request.
   * @param otherOptions - Additional request options.
   * @returns Axios response.
   */
  public async put<RequestBody, ResponseBody>(
    url: string,
    body: RequestBody,
    otherOptions?: AxiosRequestConfig,
    headers?: Headers
  ): Promise<ResponseBody> {
    const requestHeaders = await API.getHeaders(headers)
    return API.assert(
      await this.apiProvider.put(url, body, {
        headers: requestHeaders,
        ...otherOptions,
      })
    )
  }
}

const api = new API()
