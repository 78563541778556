import { Grid, useTheme } from '@mui/material'
import colors from 'constants/colors'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IItemVisibility } from 'types/layoutInterfaces'

/**
 * Renders the About component.
 *
 * @param {string} description - The description of the component.
 * @param {object} layout - The layout object.
 * @param {string} headingColor - The color of the heading.
 * @return {JSX.Element} The rendered About component.
 * @notExported
 */
const About = ({ description, layout, headingColor }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const hidden = useMemo(() => {
    if (layout && layout.ItemVisibilities && layout.ItemVisibilities.length > 0) {
      const visibilities = layout.ItemVisibilities.filter((visibility: IItemVisibility) => visibility.type === 'about')
      if (visibilities.length > 0) {
        return visibilities.every((value: IItemVisibility) => value.hidden === true)
      }
    }
  }, [layout])

  if (hidden) {
    return <></>
  } else {
    return (
      <Grid sx={{ paddingTop: '10pt', paddingLeft: '20pt', paddingRight: '20pt', fontSize: 10 }}>
        <Grid
          sx={{
            fontSize: '18.8pt',
            fontFamily: 'Raleway',
            fontWeight: 800,
            marginBottom: '8pt',
            letterSpacing: 0,
            textTransform: 'capitalize',
            borderBottom: `2pt solid ${theme.palette.neutral.dark}`,
            marginTop: '18pt',
            minWidth: '100%',
            color: headingColor ? headingColor : colors.secondary,
          }}
        >
          {t('print.about.title')}
        </Grid>
        <Grid
          sx={{ fontSize: '9.7pt', color: theme.palette.neutral.dark, whiteSpace: 'pre-wrap', textAlign: 'justify' }}
        >
          {description}
        </Grid>
      </Grid>
    )
  }
}

export default React.memo(About)
