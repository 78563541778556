import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IPersonRole, IProject } from 'types/cvsInterfaces'
import { IItemVisibility } from 'types/layoutInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { convertToDisplayDate } from 'utils/utils'
import VisibilityCheckbox from '../VisibilityCheckbox'
import colors from 'constants/colors'

/**
 * Renders a list of projects in an accordion component.
 *
 * @param {Object[]} projects - The array of project objects to render.
 * @param {string} layout - The layout configuration for the projects.
 * @param {function} hideSection - The function to hide or show the projects section.
 * @param {function} hideItem - The function to hide or show an individual project item.
 * @param {boolean} expanded - Whether the accordion is expanded or not.
 * @param {function} setStatus - The function to set the status of the accordion.
 * @return {JSX.Element} The rendered projects component.
 * @notExported
 */
const Projects = ({ projects, layout, hideSection, hideItem, expanded, setStatus }) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()

  let hidden = false
  if (layout && layout.ItemVisibilities && layout.ItemVisibilities.length > 0) {
    const visibilities = layout.ItemVisibilities.filter((visibility: IItemVisibility) => visibility.type === 'projects')
    if (visibilities.length > 0) {
      const visibilityMap = new Map(visibilities.map(item => [item.itemId, item.hidden]))
      hidden = projects.every(value => visibilityMap.get(value.id) === true)
    }
  }

  const Item = ({ project }) => {
    let hiddenItem = false
    if (layout && layout.ItemVisibilities && layout.ItemVisibilities.length > 0) {
      const visibilities = layout.ItemVisibilities.filter(
        (visibility: IItemVisibility) => visibility.type === 'projects'
      )
      if (visibilities.length > 0) {
        hiddenItem = visibilities.find((value: IItemVisibility) => value.itemId === project.id && value.hidden === true)
      }
    }

    return (
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        width="100%"
        my={2}
        sx={{
          padding: '2px',
          backgroundColor: theme.palette.background.default,
          '&:hover': {
            backgroundColor: theme.palette.background.paper,
            borderRadius: '5px',
          },
        }}
      >
        <Box width="100%">
          <Box display="flex" flexDirection="row" flexWrap="nowrap" width="100%" mb={1}>
            <VisibilityCheckbox
              checked={!!hiddenItem}
              onChange={(checked: boolean) => hideItem(checked, project.id, 'projects')}
            />
            <Box fontWeight="fontWeightBold" flexGrow={1}>
              {chooseDBTranslation(i18n, project).name}
            </Box>
            <Box justifyContent="flex-end">
              {convertToDisplayDate(project.startDate, project.dateResolution)} -{' '}
              {project.endDate ? convertToDisplayDate(project.endDate, project.dateResolution) : null}
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" flexWrap="nowrap" width="100%" mb={1}>
            <Box mr={1} fontWeight="fontWeightBold">
              {t('print.project.roles')}:
            </Box>
            <Box display="inline">
              {chooseDBTranslation(i18n, project).primaryRole.length > 0
                ? chooseDBTranslation(i18n, project).primaryRole
                : project.ProjectRoles.map((role: IPersonRole) => `${chooseDBTranslation(i18n, role.Skill).name} `)}
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" flexWrap="nowrap" width="100%" mb={1}>
            <Box mr={1} fontWeight="fontWeightBold">
              {t('print.project.customer')}:
            </Box>
            <Box display="inline">{chooseDBTranslation(i18n, project).customerName}</Box>
          </Box>
          <Box display="flex" flexDirection="row" flexWrap="nowrap" width="100%" mb={1}>
            <Box mr={1} fontWeight="fontWeightBold">
              {t('print.project.employer')}:
            </Box>
            <Box display="inline">{chooseDBTranslation(i18n, project).employerName}</Box>
          </Box>
          <Box>{chooseDBTranslation(i18n, project).description}</Box>
        </Box>
      </Box>
    )
  }

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={(e, expanded) => setStatus(expanded)}
      elevation={0}
      sx={{ borderRadius: '5px', border: `1px solid ${colors.borderColor}` }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          fontSize: '20px',
          color: theme.palette.primary.main,
          fontWeight: 'bold',
          textTransform: 'capitalize',
        }}
      >
        <Grid container alignItems="center">
          <Grid item>
            <VisibilityCheckbox checked={hidden} onChange={(checked: boolean) => hideSection(checked, 'projects')} />
          </Grid>
          <Grid item>{t('print.layout.projects')}</Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" flexWrap="wrap" width="100%">
          {projects.map((project: IProject, index: number) => {
            return (
              <Box key={`project-${project.id}`}>
                <Item project={project} />
                {index + 1 < projects.length ? <Divider variant="fullWidth" /> : null}
              </Box>
            )
          })}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default React.memo(Projects)
