import { Text, View } from '@react-pdf/renderer'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { chooseDBTranslation } from 'utils/translations'
import { convertToDisplayDate } from 'utils/utils'
import { useStyles } from './styles'
import { IProject, IEmployer, IEducation, ICourse, ICertificate, IReference, IUrl } from 'types/cvsInterfaces'
import colors from 'constants/colors'
import { ILayout } from 'types/layoutInterfaces'

/**
 * Renders a data layout based on the given data, type, and heading color.
 *
 * @param {object} data - the data to be rendered
 * @param {string} type - the type of data to be rendered ('projects', 'employments', 'degrees', 'courses', 'certificates', 'references', 'urls')
 * @param {string} headingColor - the color of the heading
 * @return {JSX.Element} the rendered data layout
 * @notExported
 */
const DataLayout: React.FC<{ data; type: string; headingColor: string; layout?: ILayout }> = ({
  data,
  type,
  headingColor,
  layout,
}) => {
  const { t, i18n } = useTranslation()
  const styles = useStyles()

  const hidden = useMemo(() => {
    if (data[type].length) {
      return false
    } else {
      return true
    }
  }, [data, type])

  let items: JSX.Element[] = []

  if (hidden) {
    return <></>
  }

  switch (type) {
    case 'projects':
      items = useMemo(
        () =>
          data.projects.map((item: IProject, index: number) => {
            return (
              <View wrap={false} key={item.id} style={styles.itemContainer}>
                {index === 0 ? (
                  <Text style={[styles.title, { color: headingColor ? headingColor : colors.secondary }]}>
                    {t(`print.projects.title`)}
                  </Text>
                ) : null}
                <View style={styles.flex}>
                  <Text style={styles.itemHeader}>{chooseDBTranslation(i18n, item).name}</Text>
                  <Text style={styles.dateText}>
                    {convertToDisplayDate(item.startDate, item.dateResolution)} -{' '}
                    {item.endDate ? convertToDisplayDate(item.endDate, item.dateResolution) : null}
                  </Text>
                </View>
                {chooseDBTranslation(i18n, item).primaryRole.length > 0 ? (
                  <View style={styles.projectSkillsContainer}>
                    <Text style={styles.itemHeader}>{t('print.project.role')}: </Text>
                    <Text style={styles.text}>{chooseDBTranslation(i18n, item).primaryRole}</Text>
                  </View>
                ) : item.ProjectRoles ? (
                  <View style={styles.projectSkillsContainer}>
                    <Text style={styles.itemHeader}>{t('print.project.roles')}: </Text>
                    {item.ProjectRoles.map(role => (
                      <Text key={role.id} style={styles.projectSkillItem}>
                        {chooseDBTranslation(i18n, role.Skill).name}
                      </Text>
                    ))}
                  </View>
                ) : null}
                <View style={styles.textContainer}>
                  <Text style={styles.itemHeader}>{t('print.project.customer')}: </Text>
                  <Text style={styles.text}>{chooseDBTranslation(i18n, item)?.customerName}</Text>
                </View>
                <View style={styles.textContainer}>
                  <Text style={styles.itemHeader}>{t('print.project.employer')}: </Text>
                  <Text style={styles.text}>{chooseDBTranslation(i18n, item)?.employerName}</Text>
                </View>
                <Text style={styles.description}>{chooseDBTranslation(i18n, item).description}</Text>
                {item.PersonSkills?.length ? (
                  <View style={styles.projectSkillsContainer}>
                    <Text style={styles.itemHeader}>{t('print.project.skills')}: </Text>
                    {item.PersonSkills.map(skill => (
                      <Text key={skill.id} style={styles.projectSkillItem}>
                        {chooseDBTranslation(i18n, skill.Skill).name}
                      </Text>
                    ))}
                  </View>
                ) : null}
                {item.Industries?.length ? (
                  <View style={styles.projectSkillsContainer}>
                    <Text style={styles.itemHeader}>{t('print.project.industries')}: </Text>
                    {item.Industries.map(industry => (
                      <Text key={industry.id} style={styles.projectSkillItem}>
                        {chooseDBTranslation(i18n, industry.Skill).name}
                      </Text>
                    ))}
                  </View>
                ) : null}
              </View>
            )
          }),
        [data.projects, headingColor]
      )
      break

    case 'employments':
      items = useMemo(
        () =>
          data.employments.map((item: IEmployer, index: number) => {
            return (
              <View wrap={false} key={item.id} style={styles.itemContainer}>
                {index === 0 ? (
                  <Text style={[styles.title, { color: headingColor ? headingColor : colors.secondary }]}>
                    {t(`print.employments.title`)}
                  </Text>
                ) : null}
                <View style={styles.flex}>
                  <Text style={styles.itemHeader}>{chooseDBTranslation(i18n, item).name}</Text>
                  <Text style={styles.dateText}>
                    {convertToDisplayDate(item.startDate, item.dateResolution)} -{' '}
                    {item.endDate ? convertToDisplayDate(item.endDate, item.dateResolution) : null}
                  </Text>
                </View>
                {chooseDBTranslation(i18n, item).primaryRole.length > 0 ? (
                  <View style={styles.projectSkillsContainer}>
                    <Text style={styles.itemHeader}>{t('print.project.role')}: </Text>
                    <Text style={styles.text}>{chooseDBTranslation(i18n, item).primaryRole}</Text>
                  </View>
                ) : item.EmployerRoles ? (
                  <View style={styles.projectSkillsContainer}>
                    <Text style={styles.itemHeader}>{t('print.employment.roles')}: </Text>
                    {item.EmployerRoles.map(role => (
                      <Text key={role.id} style={styles.projectSkillItem}>
                        {chooseDBTranslation(i18n, role.Skill).name}
                      </Text>
                    ))}
                  </View>
                ) : null}
                <Text style={styles.description}>{chooseDBTranslation(i18n, item).description}</Text>
              </View>
            )
          }),
        [data.employments, headingColor]
      )
      break

    case 'degrees':
      items = useMemo(
        () =>
          data.degrees.map((item: IEducation, index: number) => {
            let date = ''
            if (item.startDate) {
              date += `${convertToDisplayDate(item.startDate, item.dateResolution)} - `
            }
            if (item.endDate) {
              date += `${convertToDisplayDate(item.endDate, item.dateResolution)}`
            }

            return (
              <View wrap={false} key={item.id} style={styles.itemContainer}>
                {index === 0 ? (
                  <Text style={[styles.title, { color: headingColor ? headingColor : colors.secondary }]}>
                    {t(`print.degrees.title`)}
                  </Text>
                ) : null}
                <View style={styles.flex}>
                  <Text style={styles.itemHeader}>{chooseDBTranslation(i18n, item).school}</Text>
                  <Text style={styles.dateText}>{date}</Text>
                </View>
                <View style={styles.textContainer}>
                  <Text style={styles.itemHeader}>{t('print.degree')}: </Text>
                  <Text style={styles.text}>{chooseDBTranslation(i18n, item).degree}</Text>
                </View>
                <Text style={styles.description}>{chooseDBTranslation(i18n, item).description}</Text>
              </View>
            )
          }),
        [data.degrees, headingColor]
      )
      break

    case 'courses':
      items = useMemo(
        () =>
          data.courses.map((item: ICourse, index: number) => {
            let date = ''
            if (item.startDate) {
              date += `${convertToDisplayDate(item.startDate, item.dateResolution)} - `
            }
            if (item.endDate) {
              date += `${convertToDisplayDate(item.endDate, item.dateResolution)}`
            }

            return (
              <View wrap={false} key={item.id} style={styles.itemContainer}>
                {index === 0 ? (
                  <Text style={[styles.title, { color: headingColor ? headingColor : colors.secondary }]}>
                    {t(`print.courses.title`)}
                  </Text>
                ) : null}
                <View style={styles.flex}>
                  <Text style={styles.itemHeader}>{chooseDBTranslation(i18n, item).course}</Text>
                  <Text style={styles.dateText}>{date}</Text>
                </View>
                <View style={styles.textContainer}>
                  <Text style={styles.itemHeader}>{t('print.organizer')}: </Text>
                  <Text style={styles.text}>{chooseDBTranslation(i18n, item).organizer}</Text>
                </View>
                <Text style={styles.description}>{chooseDBTranslation(i18n, item).description}</Text>
              </View>
            )
          }),
        [data.courses, headingColor]
      )
      break

    case 'certificates':
      items = useMemo(
        () =>
          data.certificates.map((item: ICertificate, index: number) => {
            let date = ''
            if (item.issueDate) {
              date += `${convertToDisplayDate(item.issueDate, item.dateResolution)}`
            }
            let endDate = ''
            if (item.endDate) {
              endDate += `${convertToDisplayDate(item.endDate, item.dateResolution)}`
            }

            return (
              <View wrap={false} key={item.id} style={styles.itemContainer}>
                {index === 0 ? (
                  <Text style={[styles.title, { color: headingColor ? headingColor : colors.secondary }]}>
                    {t(`print.certificates.title`)}
                  </Text>
                ) : null}
                <View style={styles.flex}>
                  <Text style={styles.itemHeader}>{chooseDBTranslation(i18n, item).certificate}</Text>
                  <Text style={styles.dateText}>{date}</Text>
                </View>
                <View style={styles.textContainer}>
                  <Text style={styles.itemHeader}>{t('print.certifier')}: </Text>
                  <Text style={styles.text}>{chooseDBTranslation(i18n, item).certifier}</Text>
                </View>
                {endDate.length > 0 && layout && layout.showCertificateExpiry && (
                  <View style={styles.textContainer}>
                    <Text style={styles.itemHeader}>{t('print.certificateExpiry')}: </Text>
                    <Text style={styles.text}>{endDate}</Text>
                  </View>
                )}
                {item.url && (
                  <View style={styles.textContainer}>
                    <Text style={styles.itemHeader}>{t('print.url')}: </Text>
                    <Text style={styles.text}>{item.url}</Text>
                  </View>
                )}
                <Text style={styles.description}>{chooseDBTranslation(i18n, item).description}</Text>
              </View>
            )
          }),
        [data.certificates, headingColor]
      )
      break

    case 'references':
      items = useMemo(
        () =>
          data.references.map((item: IReference, index: number) => {
            return (
              <View key={item.id}>
                {index === 0 ? (
                  <Text style={[styles.title, { color: headingColor ? headingColor : colors.secondary }]}>
                    {t('print.reference.title')}
                  </Text>
                ) : null}
                <View style={styles.textContainer}>
                  <Text style={styles.itemHeader}>{t('print.reference.referee')}: </Text>
                  <Text style={styles.text}>{`${item.firstName} ${item.lastName}`}</Text>
                </View>
                <View style={styles.textContainer}>
                  <Text style={styles.itemHeader}>{t('print.reference.company')}: </Text>
                  <Text style={styles.text}>{item.company}</Text>
                </View>
                <Text style={styles.description}>{chooseDBTranslation(i18n, item).letter}</Text>
              </View>
            )
          }),
        [data.references, headingColor]
      )
      break

    case 'urls':
      items = useMemo(
        () =>
          (items = data.urls.map((item: IUrl, index: number) => {
            return (
              <View wrap={false} key={item.id} style={styles.itemContainer}>
                {index === 0 ? (
                  <Text style={[styles.title, { color: headingColor ? headingColor : colors.secondary }]}>
                    {t('print.links.title')}
                  </Text>
                ) : null}
                <View style={styles.textContainer}>
                  <Text style={styles.text}>{item.url}</Text>
                </View>
                <Text style={styles.description}>{chooseDBTranslation(i18n, item).description}</Text>
              </View>
            )
          })),
        [data.urls, headingColor]
      )
      break

    default:
      break
  }

  return <View style={styles.container}>{items}</View>
}

export default DataLayout
