import { Grid, Divider, Box, useTheme } from '@mui/material'
import colors from 'constants/colors'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ILanguageSkill } from 'types/cvsInterfaces'
import { languageLevels } from 'utils/utils'

/**
 * Renders a component that displays a list of programming languages and their skill levels.
 *
 * @param {Array} items - the list of programming languages with their skill levels
 * @param {string} headingColor - the color of the heading
 * @param {Object} languages - an object mapping language codes to language names
 * @return {JSX.Element} - the rendered component
 * @notExported
 */
const Language = ({ items, headingColor, languages }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const hidden = useMemo(() => {
    if (items.length) {
      return false
    }
    return true
  }, [items])

  if (hidden) {
    return <></>
  }

  const orderedLanguages = _.orderBy(items, [item => item.level], ['desc', 'asc'])

  return (
    <Grid sx={{ paddingTop: '10pt', paddingLeft: '20pt', paddingRight: '20pt', fontSize: 10 }}>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          marginBottom: '20pt',
        }}
      >
        {orderedLanguages.map((language: ILanguageSkill, index: number) => {
          return (
            <React.Fragment key={language.id}>
              {index === 0 ? (
                <Grid
                  sx={{
                    fontSize: '18.8pt',
                    fontFamily: 'Raleway',
                    fontWeight: 800,
                    marginBottom: '8pt',
                    color: theme.palette.neutral.dark,
                    letterSpacing: 0,
                    textTransform: 'capitalize',
                    borderBottom: `2pt solid ${theme.palette.neutral.dark}`,
                    marginTop: '18pt',
                    minWidth: '100%',
                  }}
                  style={{ color: headingColor ? headingColor : colors.secondary }}
                >
                  {t('print.languages.title')}
                </Grid>
              ) : null}
              <Grid sx={{ width: '33%', display: 'flex', flexDirection: 'column', marginBottom: '5pt' }}>
                <Box sx={{ fontSize: '11.8pt', fontFamily: 'Raleway', fontWeight: 'bold', lineHeight: 1.5 }}>
                  {languages ? languages[language.languageCode] : language.languageCode}
                </Box>
                <Grid sx={{ fontSize: '10.1pt', marginBottom: '2pt' }}>
                  <Box>{`${language.level ? t(languageLevels()[(language.level ?? 1) - 1].label) : ''}`}</Box>
                </Grid>
              </Grid>
            </React.Fragment>
          )
        })}
      </Grid>
      <Grid container alignItems="flex-end" justifyContent="space-between" style={{ marginTop: 10 }}>
        <Box sx={{ marginHorizontal: '10pt', fontSize: '10.1pt', color: theme.palette.neutral.main }}>{`A2 - ${t(
          'language.basic'
        )}`}</Box>
        <Divider orientation="vertical" variant="fullWidth" flexItem sx={{ color: colors.gray }} />
        <Box sx={{ marginHorizontal: '10pt', fontSize: '10.1pt', color: theme.palette.neutral.main }}>{`B1 - ${t(
          'language.moderate'
        )}`}</Box>
        <Divider orientation="vertical" variant="fullWidth" flexItem sx={{ color: colors.gray }} />
        <Box sx={{ marginHorizontal: '10pt', fontSize: '10.1pt', color: theme.palette.neutral.main }}>{`B2 - ${t(
          'language.good'
        )}`}</Box>
        <Divider orientation="vertical" variant="fullWidth" flexItem sx={{ color: colors.gray }} />
        <Box sx={{ marginHorizontal: '10pt', fontSize: '10.1pt', color: theme.palette.neutral.main }}>{`C1 - ${t(
          'language.excellent'
        )}`}</Box>
        <Divider orientation="vertical" variant="fullWidth" flexItem sx={{ color: colors.gray }} />
        <Box sx={{ marginHorizontal: '10pt', fontSize: '10.1pt', color: theme.palette.neutral.main }}>{`C2 - ${t(
          'language.native'
        )}`}</Box>
      </Grid>
    </Grid>
  )
}

export default React.memo(Language)
