import { Grid, Link } from '@mui/material'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import { getLoginInitialData } from 'Components/reusable/DataContext/InitialData'
import { loginSchema } from 'Components/reusable/DataContext/ValidationSchema'
import { useTranslation } from 'react-i18next'
import { ILoginAccount } from 'types/accountInterfaces'
import { termsAndPolicyApi } from 'api/TermsAndPolicyAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import DataContext, { NewOrExisting } from 'Components/reusable/DataContext'
import LoadingIndicator from 'Components/reusable/LoadingIndicator'
import { useLogin, useUser } from 'hooks'
import { LoginError } from 'hooks/login'
import i18n from 'i18n'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { IVerifyAccount } from 'types/accountInterfaces'
import { IError } from 'types/error'
import { ITermsAndPolicy } from 'types/termsAndPolicyInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import CaleoPrimaryButton from 'Components/reusable/Buttons/CaleoPrimaryButton'
import CaleoGreenButton from 'Components/reusable/Buttons/CaleoGreenButton'
import { useIsComponentMounted } from 'hooks/util'
import caleoLogo from 'assets/images/caleoLogo.svg'
import LoginPageCard from 'Components/reusable/CaleoCustomComponents/LoginPageCard'

type T = IVerifyAccount

/**
 * Renders the Login component.
 *
 * @return {ReactElement} The rendered Login component.
 * @notExported
 */
const Login: React.FC = () => {
  const isComponentMounted = useIsComponentMounted()
  const [data, setData] = useState<NewOrExisting<T>>(getLoginInitialData())
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [termsAndPolicies, setTermsAndPolicies] = useState<ITermsAndPolicy[]>([])
  const [backendError, setBackendError] = useState<IError>()
  const [loginError, setLoginError] = useState<LoginError>()
  const [loading, setLoading] = useState<boolean>(false)
  const { doLogin, loggingIn, loggedIn, azureLogin, error, loginLoading } = useLogin()
  const location = useLocation()
  const { groupsReady, groups } = useUser()

  const buttonsEnabled = !loggingIn

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        const result = await termsAndPolicyApi.getGlobalTermsAndPolicies(controller)
        if (isComponentMounted.current) setTermsAndPolicies(result)
      } catch (error) {
        console.error(error)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    if (loggedIn && groupsReady) {
      const azureRedirect = localStorage.getItem('azureRedirect')

      if (location.state?.url) {
        return navigate(location.state?.url)
      } else if (azureRedirect) {
        return navigate(azureRedirect)
      } else if (groups && groups.includes('sales')) {
        return navigate('/employees')
      } else {
        return navigate('/profile')
      }
    }
  }, [loggedIn, groupsReady])

  const onSubmit = async () => {
    setLoginError(undefined)
    try {
      setLoading(true)
      await doLogin(data)
    } catch (error) {
      setLoginError(error as LoginError)
      setLoading(false)
    }
  }

  const handleForgotPassword = () => {
    navigate('/forgot-password')
  }

  if (!data) {
    return <LoadingIndicator />
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <img src={caleoLogo} alt="logo" style={{ width: '159px', padding: '18px' }} />
      </Grid>
      {loginLoading ? (
        <LoadingIndicator />
      ) : (
        data && (
          <Grid item xs={12} display="flex">
            <DataContext data={data} onChange={setData} onSubmit={onSubmit} schema={loginSchema()} localeBase="login">
              <LoginPageCard header={t('loginheader')}>
                <Grid container>
                  <Grid item xs={12} sx={{ marginTop: '16px', marginBottom: '16px' }}>
                    <Grid xs={12} item sx={{ py: 2 }}>
                      <DataContext.TextField<ILoginAccount>
                        id="email"
                        field="email"
                        autoComplete="email"
                        name="email"
                        required
                        fullWidth
                        type="email"
                        insetLabel
                      />
                      <DataContext.PasswordField<ILoginAccount>
                        id="password"
                        field="password"
                        autoComplete="current-password"
                        name="password"
                        required
                        fullWidth
                        type="password"
                        insetLabel
                      />
                      <Link
                        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', padding: '0px' }}
                        onClick={handleForgotPassword}
                      >
                        <CardContentText type="link">{t('resetpassword')}</CardContentText>
                      </Link>
                      <DataContext.CheckField<ILoginAccount> field="remember" />
                    </Grid>
                  </Grid>
                </Grid>
                {loginError && (
                  <Grid container justifyContent="flex-end">
                    <CardContentText type="error">{loginError.message}</CardContentText>
                  </Grid>
                )}
                <Grid container justifyContent="center" spacing={1} alignItems="center">
                  <Grid item xs={12}>
                    <Grid container spacing={4}>
                      {termsAndPolicies.map(item => (
                        <Grid item key={item.id}>
                          {item.id < 3 && (
                            <Link
                              sx={{ textDecoration: 'none' }}
                              onClick={() => {
                                if (item.id === 1) {
                                  navigate('/privacy-policy')
                                } else {
                                  navigate('/terms')
                                }
                              }}
                            >
                              <CardContentText type="link">{chooseDBTranslation(i18n, item)?.title}</CardContentText>
                            </Link>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container alignItems="center" spacing={1}>
                    <Grid item xs="auto">
                      <DataContext.Validation>
                        {({ valid }) => (
                          <CaleoPrimaryButton
                            valid={valid || !buttonsEnabled}
                            clickAction={onSubmit}
                            label={t('login')}
                            loading={loading}
                          />
                        )}
                      </DataContext.Validation>
                    </Grid>
                    <Grid item xs={1}>
                      <CardContentText>{t('or')}</CardContentText>
                    </Grid>
                    <Grid item xs={5}>
                      <CaleoGreenButton
                        clickAction={() => {
                          azureLogin()
                        }}
                        label={t('azureLogin.button')}
                        valid
                      />
                    </Grid>
                    {error && (
                      <Grid item xs={12}>
                        <CardContentText type="error">{t(error)}</CardContentText>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </LoginPageCard>
            </DataContext>
          </Grid>
        )
      )}
    </Grid>
  )
}

export default Login
