import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { chooseDBTranslation } from 'utils/translations'
import { convertToDisplayDate } from 'utils/utils'
import { IProject, IEmployer, IEducation, ICourse, ICertificate, IReference, IUrl } from 'types/cvsInterfaces'
import colors from 'constants/colors'
import { ILayout } from 'types/layoutInterfaces'
import { Box, Grid, useTheme } from '@mui/material'

/**
 * Renders a data layout component.
 *
 * @param {any} data - The data to be rendered.
 * @param {string} type - The type of data.
 * @param {string} headingColor - The color of the heading.
 * @returns {JSX.Element} The rendered DataLayout component.
 * @notExported
 */
const DataLayout: React.FC<{ data; type: string; headingColor: string; layout?: ILayout }> = ({
  data,
  type,
  headingColor,
  layout,
}) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()

  const hidden = useMemo(() => {
    if (data[type].length) {
      return false
    }
    return true
  }, [data, type])

  let items: JSX.Element[] = []

  if (hidden) {
    return <></>
  }

  switch (type) {
    case 'projects':
      items = useMemo(
        () =>
          data.projects.map((item: IProject, index: number) => {
            return (
              <Grid key={item.id} sx={{ width: '100%', marginBottom: '20pt' }}>
                {index === 0 ? (
                  <Grid
                    sx={{
                      fontSize: '18.8pt',
                      fontFamily: 'Raleway',
                      fontWeight: 800,
                      marginBottom: '8pt',
                      letterSpacing: 0,
                      textTransform: 'capitalize',
                      borderBottom: `2pt solid ${theme.palette.neutral.dark}`,
                      marginTop: '18pt',
                      minWidth: '100%',
                      color: headingColor ? headingColor : colors.secondary,
                    }}
                  >
                    {t(`print.projects.title`)}
                  </Grid>
                ) : null}
                <Grid
                  sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between' }}
                >
                  <Box
                    sx={{
                      fontSize: '11.8pt',
                      fontFamily: 'Raleway',
                      fontWeight: 'bold',
                      lineHeight: 1.7,
                      marginRight: '5px',
                      color: theme.palette.neutral.dark,
                    }}
                  >
                    {chooseDBTranslation(i18n, item).name}
                  </Box>
                  <Box sx={{ fontSize: '10.1pt', lineHeight: 1.5 }}>
                    {convertToDisplayDate(item.startDate, item.dateResolution)} -{' '}
                    {item.endDate ? convertToDisplayDate(item.endDate, item.dateResolution) : null}
                  </Box>
                </Grid>
                {chooseDBTranslation(i18n, item).primaryRole.length > 0 ? (
                  <Grid sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: '5pt' }}>
                    <Box
                      sx={{
                        fontSize: '11.8pt',
                        fontFamily: 'Raleway',
                        fontWeight: 'bold',
                        lineHeight: 1.7,
                        marginRight: '5px',
                        color: theme.palette.neutral.dark,
                      }}
                    >
                      {t('print.project.role')}:{' '}
                    </Box>
                    <Box sx={{ fontSize: '11.8pt', color: theme.palette.neutral.dark, lineHeight: 1.7 }}>
                      {chooseDBTranslation(i18n, item)?.primaryRole}
                    </Box>
                  </Grid>
                ) : item.ProjectRoles?.length ? (
                  <Grid sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: '5pt' }}>
                    <Box
                      sx={{
                        fontSize: '11.8pt',
                        fontFamily: 'Raleway',
                        fontWeight: 'bold',
                        lineHeight: 1.7,
                        marginRight: '5px',
                        color: theme.palette.neutral.dark,
                      }}
                    >
                      {t('print.project.roles')}:{' '}
                    </Box>
                    {item.ProjectRoles.map(role => (
                      <Box
                        key={role.id}
                        sx={{
                          fontSize: '11.8pt',
                          lineHeight: 1.7,
                          color: theme.palette.neutral.dark,
                          marginRight: '15pt',
                        }}
                      >
                        {chooseDBTranslation(i18n, role.Skill).name}
                      </Box>
                    ))}
                  </Grid>
                ) : null}
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    marginRight: '15pt',
                    minWidth: '100%',
                  }}
                >
                  <Box
                    sx={{
                      fontSize: '11.8pt',
                      fontFamily: 'Raleway',
                      fontWeight: 'bold',
                      lineHeight: 1.7,
                      marginRight: '5px',
                      color: theme.palette.neutral.dark,
                    }}
                  >
                    {t('print.project.customer')}:{' '}
                  </Box>
                  <Box sx={{ fontSize: '11.8pt', color: theme.palette.neutral.dark, lineHeight: 1.7 }}>
                    {chooseDBTranslation(i18n, item)?.customerName}
                  </Box>
                </Grid>
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    marginRight: '15pt',
                    minWidth: '100%',
                  }}
                >
                  <Box
                    sx={{
                      fontSize: '11.8pt',
                      fontFamily: 'Raleway',
                      fontWeight: 'bold',
                      lineHeight: 1.7,
                      marginRight: '5px',
                      color: theme.palette.neutral.dark,
                    }}
                  >
                    {t('print.project.employer')}:{' '}
                  </Box>
                  <Box sx={{ fontSize: '11.8pt', color: theme.palette.neutral.dark, lineHeight: 1.7 }}>
                    {chooseDBTranslation(i18n, item)?.employerName}
                  </Box>
                </Grid>
                <Box
                  sx={{
                    fontSize: '9.7pt',
                    color: theme.palette.neutral.dark,
                    whiteSpace: 'pre-wrap',
                    textAlign: 'justify',
                  }}
                >
                  {chooseDBTranslation(i18n, item).description}
                </Box>
                {item.PersonSkills?.length ? (
                  <Grid sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: '5pt' }}>
                    <Box
                      sx={{
                        fontSize: '11.8pt',
                        fontFamily: 'Raleway',
                        fontWeight: 'bold',
                        lineHeight: 1.7,
                        marginRight: '5px',
                        color: theme.palette.neutral.dark,
                      }}
                    >
                      {t('print.project.skills')}:{' '}
                    </Box>
                    {item.PersonSkills.map(skill => (
                      <Box
                        key={skill.id}
                        sx={{
                          fontSize: '11.8pt',
                          lineHeight: 1.7,
                          color: theme.palette.neutral.dark,
                          marginRight: '15pt',
                        }}
                      >
                        {chooseDBTranslation(i18n, skill.Skill).name}
                      </Box>
                    ))}
                  </Grid>
                ) : null}
                {item.Industries?.length ? (
                  <Grid sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: '5pt' }}>
                    <Box
                      sx={{
                        fontSize: '11.8pt',
                        fontFamily: 'Raleway',
                        fontWeight: 'bold',
                        lineHeight: 1.7,
                        marginRight: '5px',
                        color: theme.palette.neutral.dark,
                      }}
                    >
                      {t('print.project.industries')}:{' '}
                    </Box>
                    {item.Industries.map(industry => (
                      <Box
                        key={industry.id}
                        sx={{
                          fontSize: '11.8pt',
                          lineHeight: 1.7,
                          color: theme.palette.neutral.dark,
                          marginRight: '15pt',
                        }}
                      >
                        {chooseDBTranslation(i18n, industry.Skill).name}
                      </Box>
                    ))}
                  </Grid>
                ) : null}
              </Grid>
            )
          }),
        [data.projects, headingColor]
      )
      break

    case 'employments':
    case 'Employers':
      items = useMemo(() => {
        const employments = type === 'employments' ? data.employments : data.Employers

        return employments.map((item: IEmployer, index: number) => {
          return (
            <Grid key={item.id} sx={{ width: '100%', marginBottom: '20pt' }}>
              {index === 0 ? (
                <Grid
                  sx={{
                    fontSize: '18.8pt',
                    fontFamily: 'Raleway',
                    fontWeight: 800,
                    marginBottom: '8pt',
                    color: theme.palette.neutral.dark,
                    letterSpacing: 0,
                    textTransform: 'capitalize',
                    borderBottom: `2pt solid ${theme.palette.neutral.dark}`,
                    marginTop: '18pt',
                    minWidth: '100%',
                  }}
                  style={{ color: headingColor ? headingColor : colors.secondary }}
                >
                  {t(`print.employments.title`)}
                </Grid>
              ) : null}
              <Grid sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                <Box
                  sx={{
                    fontSize: '11.8pt',
                    fontFamily: 'Raleway',
                    fontWeight: 'bold',
                    lineHeight: 1.7,
                    marginRight: '5px',
                    color: theme.palette.neutral.dark,
                  }}
                >
                  {chooseDBTranslation(i18n, item).name}
                </Box>
                <Box sx={{ fontSize: '10.1pt', lineHeight: 1.5 }}>
                  {convertToDisplayDate(item.startDate, item.dateResolution)} -{' '}
                  {item.endDate ? convertToDisplayDate(item.endDate, item.dateResolution) : null}
                </Box>
              </Grid>
              {chooseDBTranslation(i18n, item).primaryRole.length > 0 ? (
                <Grid sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: '5pt' }}>
                  <Box
                    sx={{
                      fontSize: '11.8pt',
                      fontFamily: 'Raleway',
                      fontWeight: 'bold',
                      lineHeight: 1.7,
                      marginRight: '5px',
                      color: theme.palette.neutral.dark,
                    }}
                  >
                    {t('print.project.role')}:{' '}
                  </Box>
                  <Box sx={{ fontSize: '11.8pt', color: theme.palette.neutral.dark, lineHeight: 1.7 }}>
                    {chooseDBTranslation(i18n, item)?.primaryRole}
                  </Box>
                </Grid>
              ) : item.EmployerRoles?.length ? (
                <Grid sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: '5pt' }}>
                  <Box
                    sx={{
                      fontSize: '11.8pt',
                      fontFamily: 'Raleway',
                      fontWeight: 'bold',
                      lineHeight: 1.7,
                      marginRight: '5px',
                      color: theme.palette.neutral.dark,
                    }}
                  >
                    {t('print.employment.roles')}:{' '}
                  </Box>
                  {item.EmployerRoles.map(role => (
                    <Box
                      key={role.id}
                      sx={{
                        fontSize: '11.8pt',
                        lineHeight: 1.7,
                        color: theme.palette.neutral.dark,
                        marginRight: '15pt',
                      }}
                    >
                      {chooseDBTranslation(i18n, role.Skill).name}
                    </Box>
                  ))}
                </Grid>
              ) : null}
              <Box
                sx={{
                  fontSize: '9.7pt',
                  color: theme.palette.neutral.dark,
                  whiteSpace: 'pre-wrap',
                  textAlign: 'justify',
                }}
              >
                {chooseDBTranslation(i18n, item).description}
              </Box>
            </Grid>
          )
        })
      }, [data.employments, headingColor])
      break

    case 'degrees':
    case 'Education':
      items = useMemo(() => {
        const education = type === 'degrees' ? data.degrees : data.Education

        return education.map((item: IEducation, index: number) => {
          let date = ''
          if (item.startDate) {
            date += `${convertToDisplayDate(item.startDate, item.dateResolution)} - `
          }
          if (item.endDate) {
            date += `${convertToDisplayDate(item.endDate, item.dateResolution)}`
          }

          return (
            <Grid key={item.id} sx={{ width: '100%', marginBottom: '20pt' }}>
              {index === 0 ? (
                <Grid
                  sx={{
                    fontSize: '18.8pt',
                    fontFamily: 'Raleway',
                    fontWeight: 800,
                    marginBottom: '8pt',
                    color: theme.palette.neutral.dark,
                    letterSpacing: 0,
                    textTransform: 'capitalize',
                    borderBottom: `2pt solid ${theme.palette.neutral.dark}`,
                    marginTop: '18pt',
                    minWidth: '100%',
                  }}
                  style={{ color: headingColor ? headingColor : colors.secondary }}
                >
                  {t(`print.degrees.title`)}
                </Grid>
              ) : null}
              <Grid sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                <Box
                  sx={{
                    fontSize: '11.8pt',
                    fontFamily: 'Raleway',
                    fontWeight: 'bold',
                    lineHeight: 1.7,
                    marginRight: '5px',
                    color: theme.palette.neutral.dark,
                  }}
                >
                  {chooseDBTranslation(i18n, item).school}
                </Box>
                <Box sx={{ fontSize: '10.1pt', lineHeight: 1.5 }}>{date}</Box>
              </Grid>
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  marginRight: '15pt',
                  minWidth: '100%',
                }}
              >
                <Box
                  sx={{
                    fontSize: '11.8pt',
                    fontFamily: 'Raleway',
                    fontWeight: 'bold',
                    lineHeight: 1.7,
                    marginRight: '5px',
                    color: theme.palette.neutral.dark,
                  }}
                >
                  {t('print.degree')}:{' '}
                </Box>
                <Box sx={{ fontSize: '11.8pt', color: theme.palette.neutral.dark, lineHeight: 1.7 }}>
                  {chooseDBTranslation(i18n, item).degree}
                </Box>
              </Grid>
              <Box
                sx={{
                  fontSize: '9.7pt',
                  color: theme.palette.neutral.dark,
                  whiteSpace: 'pre-wrap',
                  textAlign: 'justify',
                }}
              >
                {chooseDBTranslation(i18n, item).description}
              </Box>
            </Grid>
          )
        })
      }, [data.degrees, headingColor])
      break

    case 'courses':
    case 'Courses':
      items = useMemo(() => {
        const courses = type === 'courses' ? data.courses : data.Courses

        return courses.map((item: ICourse, index: number) => {
          let date = ''
          if (item.startDate) {
            date += `${convertToDisplayDate(item.startDate, item.dateResolution)} - `
          }
          if (item.endDate) {
            date += `${convertToDisplayDate(item.endDate, item.dateResolution)}`
          }

          return (
            <Grid key={item.id} sx={{ width: '100%', marginBottom: '20pt' }}>
              {index === 0 ? (
                <Grid
                  sx={{
                    fontSize: '18.8pt',
                    fontFamily: 'Raleway',
                    fontWeight: 800,
                    marginBottom: '8pt',
                    color: theme.palette.neutral.dark,
                    letterSpacing: 0,
                    textTransform: 'capitalize',
                    borderBottom: `2pt solid ${theme.palette.neutral.dark}`,
                    marginTop: '18pt',
                    minWidth: '100%',
                  }}
                  style={{ color: headingColor ? headingColor : colors.secondary }}
                >
                  {t(`print.courses.title`)}
                </Grid>
              ) : null}
              <Grid sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                <Box
                  sx={{
                    fontSize: '11.8pt',
                    fontFamily: 'Raleway',
                    fontWeight: 'bold',
                    lineHeight: 1.7,
                    marginRight: '5px',
                    color: theme.palette.neutral.dark,
                  }}
                >
                  {chooseDBTranslation(i18n, item).course}
                </Box>
                <Box sx={{ fontSize: '10.1pt', lineHeight: 1.5 }}>{date}</Box>
              </Grid>
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  marginRight: '15pt',
                  minWidth: '100%',
                }}
              >
                <Box
                  sx={{
                    fontSize: '11.8pt',
                    fontFamily: 'Raleway',
                    fontWeight: 'bold',
                    lineHeight: 1.7,
                    marginRight: '5px',
                    color: theme.palette.neutral.dark,
                  }}
                >
                  {t('print.organizer')}:{' '}
                </Box>
                <Box sx={{ fontSize: '11.8pt', color: theme.palette.neutral.dark, lineHeight: 1.7 }}>
                  {chooseDBTranslation(i18n, item).organizer}
                </Box>
              </Grid>
              <Box
                sx={{
                  fontSize: '9.7pt',
                  color: theme.palette.neutral.dark,
                  whiteSpace: 'pre-wrap',
                  textAlign: 'justify',
                }}
              >
                {chooseDBTranslation(i18n, item).description}
              </Box>
            </Grid>
          )
        })
      }, [data.courses, headingColor])
      break

    case 'certificates':
    case 'Certificates':
      items = useMemo(() => {
        const certificates = type === 'certificates' ? data.certificates : data.Certificates

        return certificates.map((item: ICertificate, index: number) => {
          let date = ''
          if (item.issueDate) {
            date += `${convertToDisplayDate(item.issueDate, item.dateResolution)}`
          }
          let endDate = ''
          if (item.endDate) {
            endDate += `${convertToDisplayDate(item.endDate, item.dateResolution)}`
          }

          return (
            <Grid key={item.id} sx={{ width: '100%', marginBottom: '20pt' }}>
              {index === 0 ? (
                <Grid
                  sx={{
                    fontSize: '18.8pt',
                    fontFamily: 'Raleway',
                    fontWeight: 800,
                    marginBottom: '8pt',
                    color: theme.palette.neutral.dark,
                    letterSpacing: 0,
                    textTransform: 'capitalize',
                    borderBottom: `2pt solid ${theme.palette.neutral.dark}`,
                    marginTop: '18pt',
                    minWidth: '100%',
                  }}
                  style={{ color: headingColor ? headingColor : colors.secondary }}
                >
                  {t(`print.certificates.title`)}
                </Grid>
              ) : null}
              <Grid sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                <Box
                  sx={{
                    fontSize: '11.8pt',
                    fontFamily: 'Raleway',
                    fontWeight: 'bold',
                    lineHeight: 1.7,
                    marginRight: '5px',
                    color: theme.palette.neutral.dark,
                  }}
                >
                  {chooseDBTranslation(i18n, item).certificate}
                </Box>
                <Box sx={{ fontSize: '10.1pt', lineHeight: 1.5 }}>{date}</Box>
              </Grid>
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  marginRight: '15pt',
                  minWidth: '100%',
                }}
              >
                <Box
                  sx={{
                    fontSize: '11.8pt',
                    fontFamily: 'Raleway',
                    fontWeight: 'bold',
                    lineHeight: 1.7,
                    marginRight: '5px',
                    color: theme.palette.neutral.dark,
                  }}
                >
                  {t('print.certifier')}:{' '}
                </Box>
                <Box sx={{ fontSize: '11.8pt', color: theme.palette.neutral.dark, lineHeight: 1.7 }}>
                  {chooseDBTranslation(i18n, item).certifier}
                </Box>
              </Grid>
              {endDate.length > 0 && layout && layout.showCertificateExpiry && (
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    marginRight: '15pt',
                    minWidth: '100%',
                  }}
                >
                  <Box
                    sx={{
                      fontSize: '11.8pt',
                      fontFamily: 'Raleway',
                      fontWeight: 'bold',
                      lineHeight: 1.7,
                      marginRight: '5px',
                      color: theme.palette.neutral.dark,
                    }}
                  >
                    {t('print.certificateExpiry')}:{' '}
                  </Box>
                  <Box sx={{ fontSize: '11.8pt', color: theme.palette.neutral.dark, lineHeight: 1.7 }}>{endDate}</Box>
                </Grid>
              )}
              {item.url && (
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    marginRight: '15pt',
                    minWidth: '100%',
                  }}
                >
                  <Box
                    sx={{
                      fontSize: '11.8pt',
                      fontFamily: 'Raleway',
                      fontWeight: 'bold',
                      lineHeight: 1.7,
                      marginRight: '5px',
                      color: theme.palette.neutral.dark,
                    }}
                  >
                    {t('print.url')}:{' '}
                  </Box>
                  <Box sx={{ fontSize: '11.8pt', color: theme.palette.neutral.dark, lineHeight: 1.7 }}>{item.url}</Box>
                </Grid>
              )}
              <Box
                sx={{
                  fontSize: '9.7pt',
                  color: theme.palette.neutral.dark,
                  whiteSpace: 'pre-wrap',
                  textAlign: 'justify',
                }}
              >
                {chooseDBTranslation(i18n, item).description}
              </Box>
            </Grid>
          )
        })
      }, [data.certificates, headingColor])
      break

    case 'references':
      items = useMemo(
        () =>
          data.references.map((item: IReference, index: number) => {
            return (
              <Grid key={item.id}>
                {index === 0 ? (
                  <Grid
                    sx={{
                      fontSize: '18.8pt',
                      fontFamily: 'Raleway',
                      fontWeight: 800,
                      marginBottom: '8pt',
                      color: theme.palette.neutral.dark,
                      letterSpacing: 0,
                      textTransform: 'capitalize',
                      borderBottom: `2pt solid ${theme.palette.neutral.dark}`,
                      marginTop: '18pt',
                      minWidth: '100%',
                    }}
                    style={{ color: headingColor ? headingColor : colors.secondary }}
                  >
                    {t('print.reference.title')}
                  </Grid>
                ) : null}
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    marginRight: '15pt',
                    minWidth: '100%',
                  }}
                >
                  <Box
                    sx={{
                      fontSize: '11.8pt',
                      fontFamily: 'Raleway',
                      fontWeight: 'bold',
                      lineHeight: 1.7,
                      marginRight: '5px',
                      color: theme.palette.neutral.dark,
                    }}
                  >
                    {t('print.reference.referee')}:{' '}
                  </Box>
                  <Box
                    sx={{ fontSize: '11.8pt', color: theme.palette.neutral.dark, lineHeight: 1.7 }}
                  >{`${item.firstName} ${item.lastName}`}</Box>
                </Grid>
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    marginRight: '15pt',
                    minWidth: '100%',
                  }}
                >
                  <Box
                    sx={{
                      fontSize: '11.8pt',
                      fontFamily: 'Raleway',
                      fontWeight: 'bold',
                      lineHeight: 1.7,
                      marginRight: '5px',
                      color: theme.palette.neutral.dark,
                    }}
                  >
                    {t('print.reference.company')}:{' '}
                  </Box>
                  <Box sx={{ fontSize: '11.8pt', color: theme.palette.neutral.dark, lineHeight: 1.7 }}>
                    {item.company}
                  </Box>
                </Grid>
                <Box
                  sx={{
                    fontSize: '9.7pt',
                    color: theme.palette.neutral.dark,
                    whiteSpace: 'pre-wrap',
                    textAlign: 'justify',
                  }}
                >
                  {chooseDBTranslation(i18n, item).letter}
                </Box>
              </Grid>
            )
          }),
        [data.references, headingColor]
      )
      break

    case 'urls':
      items = useMemo(
        () =>
          (items = data.urls.map((item: IUrl, index: number) => {
            return (
              <Grid key={item.id} sx={{ width: '100%', marginBottom: '20pt' }}>
                {index === 0 ? (
                  <Grid
                    sx={{
                      fontSize: '18.8pt',
                      fontFamily: 'Raleway',
                      fontWeight: 800,
                      marginBottom: '8pt',
                      color: theme.palette.neutral.dark,
                      letterSpacing: 0,
                      textTransform: 'capitalize',
                      borderBottom: `2pt solid ${theme.palette.neutral.dark}`,
                      marginTop: '18pt',
                      minWidth: '100%',
                    }}
                    style={{ color: headingColor ? headingColor : colors.secondary }}
                  >
                    {t('print.links.title')}
                  </Grid>
                ) : null}
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    marginRight: '15pt',
                    minWidth: '100%',
                  }}
                >
                  <Box sx={{ fontSize: '11.8pt', color: theme.palette.neutral.dark, lineHeight: 1.7 }}>{item.url}</Box>
                </Grid>
                <Box
                  sx={{
                    fontSize: '9.7pt',
                    color: theme.palette.neutral.dark,
                    whiteSpace: 'pre-wrap',
                    textAlign: 'justify',
                  }}
                >
                  {chooseDBTranslation(i18n, item).description}
                </Box>
              </Grid>
            )
          })),
        [data.urls, headingColor]
      )
      break

    default:
      break
  }

  return <Grid sx={{ paddingTop: '10pt', paddingLeft: '20pt', paddingRight: '20pt', fontSize: 10 }}>{items}</Grid>
}

export default DataLayout
