import { Card, CardHeader } from '@mui/material'
import LanguageSelector from 'Components/General/LanguageSelector'
import React from 'react'
import { useTranslation } from 'react-i18next'

/** @notExported */
interface ILoginPageCardProps {
  /** Card header. */
  header
  /** Card avatar. */
  avatar?
  /** Card children. */
  children: React.ReactNode
}

/**
 * Login page card component.
 *
 * @returns Login page card component.
 * @notExported
 */
const LoginPageCard: React.FC<ILoginPageCardProps> = ({ header, avatar, children }) => {
  const { i18n } = useTranslation()

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language)
  }

  return (
    <Card
      sx={{
        alignItems: 'center',
        margin: 'auto',
        minWidth: '410px',
      }}
      elevation={3}
    >
      <CardHeader
        title={header}
        avatar={avatar ?? null}
        action={
          <LanguageSelector
            initialLanguage={i18n.language}
            setLanguage={changeLanguage}
            global={true}
            id="login-language"
          />
        }
      />
      {children}
    </Card>
  )
}

export default LoginPageCard
