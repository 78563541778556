import { Text, View } from '@react-pdf/renderer'
import colors from 'constants/colors'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ILanguageSkill } from 'types/cvsInterfaces'
import { languageLevels } from 'utils/utils'
import { useStyles } from './styles'

/**
 * Renders a list of programming languages along with their skill levels.
 *
 * @param {Array} items - an array of language skills
 * @param {string} headingColor - the color of the heading text
 * @param {Object} languages - an object containing language codes and their corresponding names
 * @return {JSX.Element} - the rendered component
 * @notExported
 */
const Language = ({ items, headingColor, languages }) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const hidden = useMemo(() => {
    if (items.length) {
      return false
    }
    return true
  }, [items])

  if (hidden) {
    return <></>
  }

  const orderedLanguages = _.orderBy(items, [item => item.level], ['desc', 'asc'])

  return (
    <View style={styles.container}>
      <View style={styles.languageContainer}>
        {orderedLanguages.map((language: ILanguageSkill, index: number) => {
          return (
            <React.Fragment key={language.id}>
              {index === 0 ? (
                <Text style={[styles.title, { color: headingColor ? headingColor : colors.secondary }]}>
                  {t('print.languages.title')}
                </Text>
              ) : null}
              <View style={styles.languageItem} wrap={false}>
                <Text style={styles.languageCode}>
                  {languages ? languages[language.languageCode] : language.languageCode}
                </Text>
                <View>
                  <Text>{`${language.level ? t(languageLevels()[(language.level ?? 1) - 1].label) : ''}`}</Text>
                </View>
              </View>
            </React.Fragment>
          )
        })}
      </View>
      <View style={styles.levelsContainer}>
        <Text style={styles.levelText}>{`A2 - ${t('language.basic')}`}</Text>
        <Text style={{ color: colors.gray }}>|</Text>
        <Text style={styles.levelText}>{`B1 - ${t('language.moderate')}`}</Text>
        <Text style={{ color: colors.gray }}>|</Text>
        <Text style={styles.levelText}>{`B2 - ${t('language.good')}`}</Text>
        <Text style={{ color: colors.gray }}>|</Text>
        <Text style={styles.levelText}>{`C1 - ${t('language.excellent')}`}</Text>
        <Text style={{ color: colors.gray }}>|</Text>
        <Text style={styles.levelText}>{`C2 - ${t('language.native')}`}</Text>
      </View>
    </View>
  )
}

export default React.memo(Language)
