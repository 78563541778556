import CheckboxIconOutlined from '@mui/icons-material/CheckBoxOutlined'
import LanguageIcon from '@mui/icons-material/Language'
import SchoolIcon from '@mui/icons-material/School'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import TimelapseIcon from '@mui/icons-material/Timelapse'
import WorkIcon from '@mui/icons-material/Work'
import { AppBar, Box, Divider, Grid, Toolbar, useMediaQuery, useTheme } from '@mui/material'
import { cvAPI } from 'api/CvAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import EducationCard from 'Components/General/EducationCard'
import EmployerHistoryCard from 'Components/General/EmployerHistoryCard'
import LanguageCard from 'Components/General/LanguageCard'
import ProfileCard, { ICvData } from 'Components/General/ProfileCard'
import ReferencesCard from 'pages/Profile/ProfilePageCards/ReferencesCard'
import SkillsCard from 'Components/General/SkillsCard'
import ToolbarButton from 'Components/reusable/Buttons/ToolbarButton'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import { Data } from 'Components/reusable/HOC/withProfileCard'
import { useLanguageData, useUser } from 'hooks'
import { isEqual, orderBy } from 'lodash'
import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ICertificate,
  ICourse,
  ICv,
  IEducation,
  IEmployer,
  ILanguageSkill,
  IPersonIndustry,
  IPersonRole,
  IPersonSkill,
  IProject,
  IReference,
} from 'types/cvsInterfaces'
import { IError } from 'types/error'
import { INetwork } from 'types/networkInterfaces'
import { IPerson } from 'types/userInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import ProjectsCard from 'Components/General/ProjectsCard'
import { useIsComponentMounted } from 'hooks/util'

/**
 * ProfilePageCardsProps interface represents the props for the ProfilePageCards component.
 * It provides the necessary data for rendering the component.
 * @notExported
 */
interface IProfilePageCardsProps {
  // The selected CV object
  selectedCv: ICv
  // The person data object
  personData: IPerson | undefined
  // Callback function to set the CV updated date
  setCvUpdatedAt: (date: Date) => void
  // Flag indicating if the CV is currently loading
  cvLoading: boolean
  // Callback function to set the CV loading state
  setCvLoading: (value: boolean) => void
  // Flag indicating if the user has sales access
  salesAccess: boolean
  // The viewer networks array
  viewerNetworks: INetwork[] | undefined
  // The account networks array
  accountNetworks: INetwork[] | undefined
  // Callback function to set the update CV progress
  setUpdateCvProgress: (update: Date) => void
}

/**
 * ProfilePageCards component.
 *
 * @param selectedCv - The selected CV object.
 * @param personData - The person data object.
 * @param setCvUpdatedAt - Callback function to set the CV updated date.
 * @param cvLoading - Flag indicating if the CV is currently loading.
 * @param setCvLoading - Callback function to set the CV loading state.
 * @param salesAccess - Flag indicating if the user has sales access.
 * @param viewerNetworks - The viewer networks array.
 * @param accountNetworks - The account networks array.
 * @param setUpdateCvProgress - Callback function to set the update CV progress.
 * @returns The ProfilePageCards component.
 * @notExported
 */
const ProfilePageCards: React.FC<IProfilePageCardsProps> = ({
  selectedCv,
  personData,
  setCvUpdatedAt,
  cvLoading,
  setCvLoading,
  salesAccess,
  viewerNetworks,
  accountNetworks,
  setUpdateCvProgress,
}) => {
  const isComponentMounted = useIsComponentMounted()
  const { user, groups } = useUser()
  const { t, i18n } = useTranslation()
  const [backendError, setBackendError] = useState<IError>()
  const [employers, setEmployers] = useState<IEmployer[]>([])
  const [projects, setProjects] = useState<IProject[]>([])
  const [education, setEducation] = useState<IEducation[]>([])
  const [courses, setCourses] = useState<ICourse[]>([])
  const [certificates, setCertificates] = useState<ICertificate[]>([])
  const [references, setReferences] = useState<IReference[]>([])
  const [languageSkills, setLanguageSkills] = useState<ILanguageSkill[]>([])
  const [skills, setSkills] = useState<IPersonSkill[]>([])
  const [industries, setIndustries] = useState<IPersonIndustry[]>([])
  const [roles, setRoles] = useState<IPersonRole[]>([])
  const [overviewData, setOverviewData] = useState<ICvData>({
    Employers: [],
    Projects: [],
    Education: [],
    Courses: [],
    Certificates: [],
    LanguageSkills: [],
  })

  const profileRef = React.useRef<HTMLDivElement>(null)
  const skillsRef = React.useRef<HTMLDivElement>(null)
  const industriesRef = React.useRef<HTMLDivElement>(null)
  const rolesRef = React.useRef<HTMLDivElement>(null)
  const educationRef = React.useRef<HTMLDivElement>(null)
  const employersRef = React.useRef<HTMLDivElement>(null)
  const projectsRef = React.useRef<HTMLDivElement>(null)
  const referencesRef = React.useRef<HTMLDivElement>(null)
  const languagesRef = React.useRef<HTMLDivElement>(null)
  const { languages } = useLanguageData()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (selectedCv) {
      if (!isEqual(selectedCv.PersonSkills, skills)) setSkills(selectedCv.PersonSkills)
      if (!isEqual(selectedCv.Industries, industries)) setIndustries(selectedCv.Industries)
      if (!isEqual(selectedCv.Roles, roles)) setRoles(selectedCv.Roles)
      if (!isEqual(selectedCv.Employers, employers)) setEmployers(selectedCv.Employers)
      if (!isEqual(selectedCv.Projects, projects)) setProjects(selectedCv.Projects)
      if (!isEqual(selectedCv.Education, education)) setEducation(selectedCv.Education)
      if (!isEqual(selectedCv.Courses, courses)) setCourses(selectedCv.Courses)
      if (!isEqual(selectedCv.Certificates, certificates)) setCertificates(selectedCv.Certificates)
      if (!isEqual(selectedCv.References, references)) setReferences(selectedCv.References)
      if (!isEqual(selectedCv.LanguageSkills, languageSkills)) setLanguageSkills(selectedCv.LanguageSkills)

      setOverviewData({
        ...overviewData,
        Employers: selectedCv.Employers,
        Projects: selectedCv.Projects,
        Education: selectedCv.Education,
        Courses: selectedCv.Courses,
        Certificates: selectedCv.Certificates,
        LanguageSkills: selectedCv.LanguageSkills,
      })

      setCvLoading(false)
    }
  }, [selectedCv])

  const editable = useMemo(() => {
    if (selectedCv && user) {
      if (
        (groups && groups.includes('admin')) ||
        (!selectedCv.original && salesAccess) ||
        (user.Person && selectedCv.personId && user.Person.id === selectedCv.personId)
      ) {
        return true
      }
    }
    return false
  }, [salesAccess, selectedCv, user, groups])

  const setUpdatedAt = async () => {
    if (selectedCv) {
      const updatedAt = await cvAPI.getUpdatedAt(selectedCv.id)
      if (!isComponentMounted.current) return
      setCvUpdatedAt(new Date(updatedAt))
    }
  }

  const updateOverviewData = (type: string, data: unknown[]) => {
    if (overviewData) {
      switch (type) {
        case 'employers': {
          setOverviewData({ ...overviewData, Employers: data as IEmployer[] })
          break
        }

        case 'projects': {
          setOverviewData({ ...overviewData, Projects: data as IProject[] })
          break
        }

        case 'education': {
          const updated: Data = data as unknown as Data
          setOverviewData({ ...overviewData, Education: updated.education })
          break
        }

        case 'courses': {
          const updated: Data = data as unknown as Data
          setOverviewData({ ...overviewData, Courses: updated.courses })
          break
        }

        case 'certificates': {
          const updated: Data = data as unknown as Data
          setOverviewData({ ...overviewData, Certificates: updated.certificates })
          break
        }

        case 'languages': {
          setOverviewData({ ...overviewData, LanguageSkills: data as ILanguageSkill[] })
          break
        }

        default:
          break
      }
    }
  }

  const updateProjects = (newProject: IProject) => {
    setProjects([...projects, newProject])
  }

  interface CVOption {
    label: string
    data: ICv | undefined
  }
  let CVOptions: CVOption[] = []

  if (personData && personData.CVs) {
    for (const cv of personData.CVs) {
      CVOptions.push({
        label: chooseDBTranslation(i18n, cv).name ? chooseDBTranslation(i18n, cv).name : String(cv.id),
        data: cv,
      })
    }

    CVOptions = orderBy(CVOptions, ['data.id'], ['asc'])
  }

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth',
      })
    }
  }

  if (cvLoading || !languages) {
    return null
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  if (!personData && !cvLoading) {
    document.title = 'Caleo'
    return (
      <Box p={{ md: 3, sm: 1, xs: 0 }} mt={{ xs: 1 }}>
        <CardContentText>{t('profile.noCv')}</CardContentText>
      </Box>
    )
  }

  return (
    <Grid container direction="row" justifyContent="center" alignItems="flex-start" sx={{ px: 1 }}>
      <Grid item xs={12} sx={{ maxWidth: '1400px !important' }} container spacing={3} mt={0}>
        {!mobileView && (
          <AppBar position="sticky" sx={{ borderRadius: '0px 0px 10px 10px' }} elevation={1}>
            <Toolbar sx={{ borderRadius: '0px 0px 10px 10px' }}>
              <ToolbarButton clickAction={() => scrollToRef(profileRef)}>{t('profile')}</ToolbarButton>
              <Divider orientation="vertical" />
              <ToolbarButton clickAction={() => scrollToRef(skillsRef)}>{t('skills.title')}</ToolbarButton>
              <Divider orientation="vertical" />
              <ToolbarButton clickAction={() => scrollToRef(industriesRef)}>{t('industries.title')}</ToolbarButton>
              <Divider orientation="vertical" />
              <ToolbarButton clickAction={() => scrollToRef(rolesRef)}>{t('roles.title')}</ToolbarButton>
              <Divider orientation="vertical" />
              <ToolbarButton clickAction={() => scrollToRef(employersRef)}>{t('employer.title')}</ToolbarButton>
              <Divider orientation="vertical" />
              <ToolbarButton clickAction={() => scrollToRef(projectsRef)}>{t('project.title')}</ToolbarButton>
              <Divider orientation="vertical" />
              <ToolbarButton clickAction={() => scrollToRef(educationRef)}>{t('education.title')}</ToolbarButton>
              <Divider orientation="vertical" />
              <ToolbarButton clickAction={() => scrollToRef(referencesRef)}>{t('reference.title')}</ToolbarButton>
              <Divider orientation="vertical" />
              <ToolbarButton clickAction={() => scrollToRef(languagesRef)}>{t('language.title')}</ToolbarButton>
            </Toolbar>
          </AppBar>
        )}
        <Grid item xs={12} ref={profileRef}>
          <ProfileCard
            editable={editable}
            cv={selectedCv}
            setUpdatedAt={setUpdatedAt}
            data={overviewData}
            languages={languages}
            viewerNetworks={viewerNetworks}
            accountNetworks={accountNetworks}
            updateProgress={() => setUpdateCvProgress(new Date())}
          />
        </Grid>
        <Grid item xs={12} ref={skillsRef}>
          <SkillsCard
            type="skill"
            details={true}
            update={true}
            editable={editable}
            cv={selectedCv}
            setUpdatedAt={setUpdatedAt}
            header="skills"
            avatar={<CheckboxIconOutlined />}
            items={skills}
            setItems={setSkills}
            setProjects={setProjects}
            setEmployers={setEmployers}
            hideAddButton={!mobileView}
            updateProgress={() => setUpdateCvProgress(new Date())}
          />
        </Grid>
        <Grid item xs={12} ref={industriesRef}>
          <SkillsCard
            type="industry"
            details={true}
            update={true}
            editable={editable}
            cv={selectedCv}
            setUpdatedAt={setUpdatedAt}
            header="industries"
            avatar={<CheckboxIconOutlined />}
            items={industries}
            setItems={setIndustries}
            setProjects={setProjects}
            setEmployers={setEmployers}
            hideAddButton={!mobileView}
            updateProgress={() => setUpdateCvProgress(new Date())}
          />
        </Grid>
        <Grid item xs={12} ref={rolesRef}>
          <SkillsCard
            type="role"
            details={true}
            update={true}
            editable={editable}
            cv={selectedCv}
            setUpdatedAt={setUpdatedAt}
            header="roles"
            avatar={<CheckboxIconOutlined />}
            items={roles}
            setItems={setRoles}
            setProjects={setProjects}
            setEmployers={setEmployers}
            hideAddButton={!mobileView}
            updateProgress={() => setUpdateCvProgress(new Date())}
          />
        </Grid>
        <Grid item xs={12} ref={employersRef}>
          <EmployerHistoryCard
            editable={editable}
            cv={selectedCv}
            setUpdatedAt={setUpdatedAt}
            header={'employer'}
            avatar={<WorkIcon />}
            items={employers}
            setRoles={setRoles}
            updateOverviewData={updateOverviewData}
            updateProjects={updateProjects}
            updateProgress={() => setUpdateCvProgress(new Date())}
          />
        </Grid>
        <Grid item xs={12} ref={projectsRef}>
          <ProjectsCard
            editable={editable}
            cv={selectedCv}
            setUpdatedAt={setUpdatedAt}
            header={'project'}
            avatar={<TimelapseIcon />}
            items={projects}
            setSkills={setSkills}
            setIndustries={setIndustries}
            setRoles={setRoles}
            updateOverviewData={updateOverviewData}
            updateProgress={() => setUpdateCvProgress(new Date())}
          />
        </Grid>
        <Grid item xs={12} ref={educationRef}>
          <EducationCard
            editable={editable}
            cv={selectedCv}
            setUpdatedAt={setUpdatedAt}
            header={'education'}
            avatar={<SchoolIcon />}
            data={{
              education,
              courses,
              certificates,
            }}
            updateOverviewData={updateOverviewData}
            updateProgress={() => setUpdateCvProgress(new Date())}
          />
        </Grid>
        <Grid item xs={12} ref={referencesRef}>
          <ReferencesCard
            editable={editable}
            cv={selectedCv}
            setUpdatedAt={setUpdatedAt}
            header={'reference'}
            avatar={<ThumbUpIcon />}
            items={references}
          />
        </Grid>
        <Grid item xs={12} ref={languagesRef}>
          <LanguageCard
            editable={editable}
            cv={selectedCv}
            setUpdatedAt={setUpdatedAt}
            header={'language'}
            avatar={<LanguageIcon />}
            items={languageSkills}
            languages={languages}
            updateOverviewData={updateOverviewData}
            updateProgress={() => setUpdateCvProgress(new Date())}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default React.memo(ProfilePageCards)
