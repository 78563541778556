import {
  Checkbox,
  Collapse,
  DialogContent,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { useConnectedSkillData } from 'hooks/data'
import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IEmployer, IPersonSkillOrIndustryOrRole, IProject } from 'types/cvsInterfaces'
import { IError } from 'types/error'
import { chooseDBTranslation } from 'utils/translations'
import { convertToDisplayDate } from 'utils/utils'
import newWithConfirmationModal from 'Components/reusable/HOC/newWithConfirmationModal'

/** @notExported  */
interface IDeleteConnectedSkillConfirmProps {
  /** Skill or industry or role to be deleted. */
  item: IPersonSkillOrIndustryOrRole
  /** Type of item. */
  type: string
}

/**
 * Confirmation modal for deleting a connected skill.
 *
 * @returns Confirmation modal.
 * @notExported
 */
const DeleteConnectedSkillConfirm: React.FC<IDeleteConnectedSkillConfirmProps> = ({ item, type }) => {
  const { t, i18n } = useTranslation()
  const [projectDetailsOpen, setProjectDetailsOpen] = useState<boolean>(false)
  const [employerDetailsOpen, setEmployerDetailsOpen] = useState<boolean>(false)
  const { projects, employers, error } = useConnectedSkillData(item.id, type)

  let backendError: IError | undefined = useMemo(() => {
    if (error) return error as IError
    return
  }, [error])

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return (
      <ErrorOverlay
        error={backendError}
        setOpen={() => {
          backendError = undefined
        }}
      />
    )
  }

  return (
    <DialogContent>
      {projects.length || employers.length ? (
        <>
          <Typography color="primary" style={{ fontWeight: 'bold', marginLeft: '15px' }} variant="h2">
            {t(`confirmation.${type}.data`)}
          </Typography>
          <p style={{ marginLeft: '15px' }}>{t(`confirmation.${type}.text`)}</p>
          {projects.length > 0 && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => setProjectDetailsOpen(!projectDetailsOpen)}
                    icon={<ExpandMoreIcon />}
                    checkedIcon={<ExpandLessIcon />}
                    name="collapseCh"
                  />
                }
                label={t('project.title')}
                labelPlacement="start"
                style={{ fontWeight: 'bold' }}
              />
              <Collapse in={projectDetailsOpen}>
                <Table aria-label="allocation table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('profile.project.modal.name')}</TableCell>
                      <TableCell align="right">{t('profile.project.modal.startDate')}</TableCell>
                      <TableCell align="right">{t('profile.project.modal.endDate')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projects.map((project: IProject) => (
                      <TableRow key={project.id}>
                        <TableCell variant="head" scope="row">
                          {chooseDBTranslation(i18n, project).name}
                        </TableCell>
                        <TableCell align="right">{convertToDisplayDate(project.startDate)}</TableCell>
                        <TableCell align="right">
                          {project.endDate ? convertToDisplayDate(project.endDate) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Collapse>
            </>
          )}
          {employers.length > 0 && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => setEmployerDetailsOpen(!employerDetailsOpen)}
                    icon={<ExpandMoreIcon />}
                    checkedIcon={<ExpandLessIcon />}
                    name="collapseCh"
                  />
                }
                label={t('employer.title')}
                labelPlacement="start"
                style={{ fontWeight: 'bold' }}
              />
              <Collapse in={employerDetailsOpen}>
                <Table aria-label="allocation table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('profile.employer.modal.name')}</TableCell>
                      <TableCell align="right">{t('profile.employer.modal.startDate')}</TableCell>
                      <TableCell align="right">{t('profile.employer.modal.endDate')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {employers.map((employer: IEmployer) => (
                      <TableRow key={employer.id}>
                        <TableCell variant="head" scope="row">
                          {chooseDBTranslation(i18n, employer).name}
                        </TableCell>
                        <TableCell align="right">{convertToDisplayDate(employer.startDate)}</TableCell>
                        <TableCell align="right">
                          {employer.endDate ? convertToDisplayDate(employer.endDate) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Collapse>
            </>
          )}
        </>
      ) : (
        <>
          <Typography color="primary" style={{ fontWeight: 'bold' }} variant="h2">
            {t('confirmation.text')}
          </Typography>
        </>
      )}
      <p style={{ marginLeft: '15px' }}>{t('no-undo')}</p>
    </DialogContent>
  )
}

export default newWithConfirmationModal(DeleteConnectedSkillConfirm)
