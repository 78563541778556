import { Box, useTheme } from '@mui/material'
import React from 'react'

/** @notExported   */
interface CardContentTextProps {
  /** Card content text type. */
  type?:
    | 'header'
    | 'description'
    | 'profileDescription'
    | 'date'
    | 'tooltip'
    | 'ellipsis'
    | 'error'
    | 'link'
    | 'cardSubHeader'
    | 'darkSubHeader'
    | 'largeText'
    | 'priceInfo'
    | 'cardHeader'
    | 'notAvailable'
    | 'cardName'
  /** Card content text font weight. */
  fontWeight?
  /** Card content text height. */
  height?
  /** Card content text children. */
  children: React.ReactNode
}

/**
 * Card content text component.
 *
 * @param type -
 * @param fontWeight -
 * @param height -
 * @param children -
 * @returns Card content text element.
 * @notExported
 */
const CardContentText: React.FC<CardContentTextProps> = ({ type, fontWeight, height, children }) => {
  const theme = useTheme()

  const typeStyles = {
    header: {
      fontSize: '15px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    description: {
      whiteSpace: 'pre-wrap',
      textAlign: 'justify',
      fontSize: '14px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
    profileDescription: {
      marginTop: '5px',
      whiteSpace: 'pre-wrap',
      textAlign: 'justify',
      fontSize: '17px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
      opacity: 0.9,
    },
    date: {
      fontSize: '14px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
      color: theme.palette.neutral.main,
    },
    tooltip: {
      fontSize: '12px',
      color: theme.palette.neutral.main,
      lineHeight: '14px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
      },
    },
    ellipsis: {
      width: '410px',
      fontSize: '14px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    error: {
      fontSize: '14px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
      color: theme.palette.error.main,
    },
    link: {
      cursor: 'pointer',
      fontSize: '15px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
      color: theme.palette.neutral.main,
      '&:hover': {
        textDecoration: 'underline',
        color: theme.palette.text.primary,
      },
    },
    cardSubHeader: {
      fontSize: '24px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
      color: theme.palette.neutral.main,
      fontWeight: 'bold',
      fontFamily: 'Raleway',
    },
    darkSubHeader: {
      fontSize: '24px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontFamily: 'Raleway',
    },
    largeText: {
      fontSize: '24px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    priceInfo: {
      fontSize: '18px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
      color: theme.palette.text.primary,
      opacity: 0.6,
    },
    cardHeader: {
      color: theme.palette.primary.main,
      fontSize: '28px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '19px',
      },
      verticalAlign: 'middle',
      textTransform: 'capitalize',
      fontFamily: 'Raleway',
    },
    notAvailable: {
      color: theme.palette.error.main,
    },
    cardName: {
      fontSize: '18px',
      color: theme.palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
      fontWeight: 'bold',
    },
    default: {
      fontSize: '14px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
      color: theme.palette.primary.main,
    },
  }

  const typeStyle = typeStyles[type ?? 'default']

  return (
    <Box
      sx={Object.assign(
        {
          fontFamily: 'Helvetica',
          lineHeight: height === 'low' ? '17px' : '23px',
          fontWeight: fontWeight === 'bold' ? 'bold' : 'inherit',
        },
        typeStyle
      )}
    >
      {children}
    </Box>
  )
}

export default CardContentText
