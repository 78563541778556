import React, { useMemo } from 'react'
import { ResizableDelta, Rnd } from 'react-rnd'
import { IBlock, IAllocationData, IAllocation } from 'types/allocationInterfaces'
import { useNotification } from 'Components/reusable/Notification'
import { allocationAPI } from 'api/AllocationAPI'
import { addDays, subDays } from 'date-fns'
import { IError } from 'types/error'
import colors from 'constants/colors'
import { lightenDarkenColor } from 'utils/utils'
import { Grid } from '@mui/material'

const ResizableBlock = ({
  data,
  maxWidth,
  block,
  daySize,
  startAfterDays,
  type,
  assignment,
  role,
  resizeLeft,
  resizeRight,
  updateData,
  setBackendError,
}: {
  data: IAllocationData
  maxWidth: number
  block: IBlock
  daySize: number
  startAfterDays: number
  type: string
  assignment: string
  role: string
  resizeLeft: boolean
  resizeRight: boolean
  updateData: (data: IAllocationData) => void
  setBackendError: (error: IError) => void
}) => {
  const { setSuccessNotification } = useNotification()

  let size = useMemo(() => {
    if (daySize && block) {
      return { width: `${daySize * block.length}`, height: '44' }
    }
  }, [block, daySize, startAfterDays])

  let position = useMemo(() => {
    if (daySize && block) {
      return { x: daySize * startAfterDays, y: 0 }
    }
  }, [block, daySize, startAfterDays])

  const draggableBlockStyle = (block: IBlock, type: string) => {
    let color: string = colors.background
    let textColor: string = colors.description

    if (block.percent === 0) {
      color = colors.background
    } else if (block.percent < 100) {
      color = colors.orange
    } else if (block.percent < 101) {
      color = colors.red
      textColor = colors.background
    } else if (block.percent > 100) {
      color = colors.purple
      textColor = colors.background
    }

    if (type === 'personal' && block.percent > 0) {
      color = colors.gray
      textColor = colors.description
    }

    const style: {
      border: string
      color: string
      borderRadius: string
      background?: string
      backgroundColor?: string
    } = {
      border: block.percent < 1 ? '1px hidden #fff' : `1px solid ${color}`,
      color: textColor,
      borderRadius: '3px',
    }

    if (block.verified) {
      style.backgroundColor = block.percent < 1 ? color : lightenDarkenColor(color, 50)
    } else {
      if (block.percent > 0) {
        style.background = `repeating-linear-gradient(
      45deg,
      ${lightenDarkenColor(color, 50)},
      ${lightenDarkenColor(color, 50)} 10px,
      ${lightenDarkenColor(color, 20)} 10px,
      ${lightenDarkenColor(color, 20)} 20px
    )`
      }
    }

    return style
  }

  const updateAllocation = async (delta: ResizableDelta, direction) => {
    try {
      const daysChange = Math.ceil(delta.width / daySize)

      if (daysChange !== 0) {
        let startDate = new Date(data.startDate)
        let endDate = new Date(data.endDate)

        if (daysChange <= 0 && direction === 'left') {
          startDate = subDays(startDate, daysChange)
        }
        if (daysChange >= 0 && direction === 'left') {
          startDate = subDays(startDate, daysChange)
        }

        if (daysChange >= 0 && direction === 'right') {
          endDate = addDays(endDate, daysChange)
        }
        if (daysChange <= 0 && direction === 'right') {
          endDate = addDays(endDate, daysChange)
        }

        await allocationAPI.save({
          id: data.id,
          state: data.state,
          percent: data.percent,
          startDate: startDate,
          endDate: endDate,
          type: data.type,
          roleId: data.AssignmentRole ? data.AssignmentRole.id : undefined,
          assignmentId: data.Assignment ? data.Assignment.id : undefined,
          personId: data.personId,
          translations: data.translations,
        } as IAllocation)

        const newBlocks = [...data.blocks]
        const index = newBlocks.findIndex(item => item.percent > 0)

        if (newBlocks[0].percent === 0 && direction === 'left') {
          if (Math.sign(daysChange) === 1) {
            newBlocks[0] = { ...newBlocks[0], length: newBlocks[0].length - daysChange }
          } else {
            newBlocks[0] = { ...newBlocks[0], length: newBlocks[0].length + Math.abs(daysChange) }
          }
        }

        if (Math.sign(daysChange) === 1) {
          newBlocks[index] = { ...newBlocks[index], length: newBlocks[index].length + daysChange }
        } else {
          newBlocks[index] = { ...newBlocks[index], length: newBlocks[index].length - Math.abs(daysChange) }
        }

        if (newBlocks[newBlocks.length - 1].percent === 0 && direction === 'right') {
          if (Math.sign(daysChange) === 1) {
            newBlocks[newBlocks.length - 1] = {
              ...newBlocks[newBlocks.length - 1],
              length: newBlocks[newBlocks.length - 1].length - daysChange,
            }
          } else {
            newBlocks[newBlocks.length - 1] = {
              ...newBlocks[newBlocks.length - 1],
              length: newBlocks[newBlocks.length - 1].length + Math.abs(daysChange),
            }
          }
        }

        updateData({ ...data, startDate: startDate, endDate: endDate, blocks: newBlocks })
        setSuccessNotification()
      }
    } catch (error) {
      setBackendError(error as IError)
    }
  }

  if (!size) return <></>

  return (
    <Rnd
      style={{ position: 'relative', overflow: 'hidden' }}
      size={size}
      position={position}
      minWidth={daySize}
      maxWidth={maxWidth}
      bounds="parent"
      resizeGrid={[daySize, 1]}
      disableDragging
      enableResizing={{
        top: false,
        right: resizeRight,
        bottom: false,
        left: resizeLeft,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      onResizeStop={(_e, direction, ref, delta, newPosition) => {
        size = {
          width: ref.style.width,
          height: '44',
        }
        position = newPosition
        updateAllocation(delta, direction)
      }}
    >
      <Grid
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...draggableBlockStyle(block, type),
          textAlign: 'center',
        }}
      >
        {`${block.percent}%${assignment}${role}`}
      </Grid>
    </Rnd>
  )
}

export default ResizableBlock
