import { DialogContent, Grid } from '@mui/material'
import { searchEmployeesApi } from 'api/SearchEmployeesAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import DataContext from 'Components/reusable/DataContext'
import { useNotification } from 'Components/reusable/Notification'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IError } from 'types/error'
import newWithEditModal from 'Components/reusable/HOC/newWithEditModal'
import { useIsComponentMounted } from 'hooks/util'
import colors from 'constants/colors'

export interface IAssignmentProposal {
  city: string
  remoteAllowed: boolean
  percent: number | null
  pricePerHour: number | null
  startDate: Date | null
  duration: number | null
  extensionPossible: boolean
  information: string
}

/** @notExported  */
interface IAssignmentProposalModalProps {
  /** Modal close function. */
  onClose: () => void
  /** Anonymous ID. */
  anonymousId?: string
  /** Submit indicator. */
  submitIndicator: boolean
  /** Reset submit indicator. */
  resetSubmit: () => void
  /** Assignment proposal data. */
  data
}

/**
 * Assignment proposal modal.
 *
 * @returns Modal component.
 * @notExported
 */
const AssignmentProposalModal: React.FC<IAssignmentProposalModalProps> = ({
  onClose,
  anonymousId,
  submitIndicator,
  resetSubmit,
  data,
}) => {
  const isComponentMounted = useIsComponentMounted()
  const { t } = useTranslation()
  const { setNotification } = useNotification()
  const [backendError, setBackendError] = useState<IError>()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        if (submitIndicator && !anonymousId) {
          await searchEmployeesApi.request(data, controller)
          if (isComponentMounted.current) {
            setNotification({
              message: t('search.request.success'),
              type: 'success',
              duration: 'short',
            })
            onClose()
          }
        }

        if (submitIndicator && anonymousId) {
          await searchEmployeesApi.propose(anonymousId, data, controller)
          if (isComponentMounted.current) {
            setNotification({
              message: t('search.propose.success'),
              type: 'success',
              duration: 'short',
            })
            onClose()
          }
        }
      } catch (err) {
        setBackendError(err as IError)
        resetSubmit()
      }
    })()

    return () => {
      controller.abort()
    }
  }, [submitIndicator])

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <DialogContent sx={{ backgroundColor: colors.secondaryBackground }}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={6}>
          <DataContext.TextField<IAssignmentProposal> field="city" fullWidth insetLabel />
        </Grid>
        <Grid item xs={6}>
          <DataContext.CheckField<IAssignmentProposal> field="remoteAllowed" insetLabel />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DataContext.NullableNumberField<IAssignmentProposal> field="percent" fullWidth insetLabel />
        </Grid>
        <Grid item xs={6}>
          <DataContext.NullableNumberField<IAssignmentProposal> field="pricePerHour" fullWidth insetLabel />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DataContext.DateField<IAssignmentProposal> field="startDate" dateResolution="day" start={true} insetLabel />
        </Grid>
        <Grid item xs={6}>
          <DataContext.NullableNumberField<IAssignmentProposal> field="duration" fullWidth insetLabel />
        </Grid>
      </Grid>
      <DataContext.CheckField<IAssignmentProposal> field="extensionPossible" insetLabel />
      <DataContext.TextField<IAssignmentProposal> field="information" multiline grow rows={6} fullWidth insetLabel />
    </DialogContent>
  )
}

export default newWithEditModal(AssignmentProposalModal)
