import React, { useState } from 'react'
import { CircularProgress, Grid, TextField } from '@mui/material'
import { chooseDBTranslation } from 'utils/translations'
import InfoIcon from '@mui/icons-material/Info'
import UserIcon from '@mui/icons-material/Portrait'
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek'
import DoneIcon from '@mui/icons-material/Done'
import CaleoIconButton from 'Components/reusable/IconButtons/CaleoIconButton'
import { IPerson } from 'types/userInterfaces'
import { IBlock, IAllocationData } from 'types/allocationInterfaces'
import { blockStyle } from 'pages/Allocation/styles'
import colors from 'constants/colors'
import { useTranslation } from 'react-i18next'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { IError } from 'types/error'
import { convertToDisplayDate } from 'utils/utils'
import { useNavigate } from 'react-router-dom'
import { personAPI } from 'api/PersonAPI'
import { useNotification } from 'Components/reusable/Notification'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'

/** @notExported */
interface IPersonAllocationBlocksProps {
  /** Allocation data  */
  allocationData: IAllocationData[]
  /** Person object  */
  person: IPerson
  /** Time scope */
  timeScope: number
  /** Function to set details open   */
  setDetailsOpen: (value) => void
  /** Loading state  */
  loading: boolean
  /** Function to set edit modal status */
  setEditOpen: (value: IPerson) => void
  /** Function to update person */
  updatePerson: (result: IPerson) => void
}

/**
 * Allocation blocks component for a person.
 *
 * @returns Person allocation blocks component.
 * @notExported
 */
const PersonAllocationBlocks: React.FC<IPersonAllocationBlocksProps> = ({
  allocationData,
  person,
  timeScope,
  setDetailsOpen,
  loading,
  setEditOpen,
  updatePerson,
}) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { setSuccessNotification } = useNotification()

  const [allocationInfo, setAllocationInfo] = useState<string>(person.allocationInfo ?? '')
  const [backendError, setBackendError] = useState<IError>()

  if (!allocationData) {
    return null
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  const submitAllocationInfo = async () => {
    if (typeof allocationInfo !== 'undefined') {
      try {
        const result = await personAPI.saveAllocationInfo(person.id, allocationInfo)
        updatePerson(result)
        setSuccessNotification()
      } catch (error) {
        setBackendError(error as IError)
      }
    }
  }

  return (
    <Grid container spacing={1} mb={0.5} alignItems={'center'}>
      {loading && (
        <Grid item alignContent="center" justifyContent="center">
          <CircularProgress />
        </Grid>
      )}
      <Grid item xs={5} md={4} lg={3} xl={3}>
        <Grid container spacing={0.5} alignItems={'center'}>
          <Grid item sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <CaleoIconButton
              tooltip={t('allocation.editTitle')}
              clickAction={e => {
                e.stopPropagation()
                setEditOpen(person)
              }}
              icon={<CalendarViewWeekIcon />}
            />
            <CaleoIconButton
              tooltip={t('dashboard.toProfile')}
              clickAction={e => {
                e.stopPropagation()
                navigate(`/profile/${person.id}`)
              }}
              icon={<UserIcon />}
            />
          </Grid>
          <Grid xs={true} item container justifyContent="flex-end" alignItems="center">
            <Grid>
              <CaleoInputLabel label={t('allocation.notes')} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={7} md={8} lg={9} xl={9} container wrap="nowrap" alignItems={'center'}>
        <Grid item xs>
          <TextField
            value={allocationInfo}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAllocationInfo(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault()
                submitAllocationInfo()
              }
            }}
            margin="dense"
            label={null}
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs="auto">
          <CaleoIconButton
            tooltip={t('save')}
            clickAction={e => {
              e.stopPropagation()
              submitAllocationInfo()
            }}
            icon={<DoneIcon />}
            size="xsmall"
          />
        </Grid>
      </Grid>

      {!loading &&
        allocationData.map((data: IAllocationData, i: number) => {
          let typeColor: string = colors.description

          switch (data.type) {
            case 'personal':
              typeColor = colors.gray
              break

            case 'project':
              typeColor = colors.green
              break

            case 'other':
              typeColor = colors.orange
              break

            default:
              break
          }

          if (data.blocks && data.blocks.length) {
            return (
              <React.Fragment key={i}>
                <Grid item xs={5} md={4} lg={3} xl={3} container alignItems={'center'}>
                  <Grid container spacing={0.5} alignItems={'center'}>
                    <Grid
                      item
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        color: typeColor,
                        fontSize: '1em',
                      }}
                    >
                      {t(`allocation.${data.type}`)}
                    </Grid>
                    <Grid item>
                      <CaleoIconButton
                        size="small"
                        icon={<InfoIcon sx={{ fontSize: '1em' }} />}
                        clickAction={() => setDetailsOpen({ allocation: data, person })}
                        sx={{ p: 0, m: 0 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={7} md={8} lg={9} xl={9} container alignItems="center">
                  <Grid container spacing={0} wrap="nowrap">
                    {data.blocks.map((block: IBlock, i: number) => {
                      const assignment = data.Assignment ? `, ${chooseDBTranslation(i18n, data.Assignment).name}` : ''
                      const role = data.AssignmentRole ? `, ${chooseDBTranslation(i18n, data.AssignmentRole).name}` : ''

                      return (
                        <Grid
                          key={i}
                          container
                          style={blockStyle(block, timeScope)}
                          alignContent="center"
                          justifyContent="center"
                        >
                          <Grid item sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {block.percent > 0
                              ? `${block.percent}%${assignment}${role} (${
                                  block.endDate ? convertToDisplayDate(block.endDate) : '-'
                                })`
                              : `${block.percent}%`}
                          </Grid>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
              </React.Fragment>
            )
          } else {
            return
          }
        })}
    </Grid>
  )
}

export default PersonAllocationBlocks
