import { CircularProgress, Dialog, DialogActions, DialogTitle, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import SaveButton from '../Buttons/SaveButton'
import CloseButton from '../IconButtons/CloseButton'
import { useTheme } from '@mui/material/styles'

/**
 * Simple modal higher order component.
 *
 * @param ModalComponent - Modal component to wrap.
 * @returns Simple modal component.
 * @notExported
 */
const withSimpleModal = ModalComponent => {
  const SimpleModal = props => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const [submitLocally, setSubmitLocally] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [buttonLoading, setButtonLoading] = useState<boolean>(false)

    return (
      <Dialog
        fullScreen={fullScreen}
        open
        onClose={() => props.onClose({})}
        maxWidth={props.maxWidth}
        fullWidth={props.fullWidth}
      >
        <DialogTitle>
          <span id="form-dialog-title">{props.header}</span>
          <CloseButton clickAction={() => props.onClose({})} />
        </DialogTitle>
        <ModalComponent
          {...props}
          submitIndicator={submitLocally}
          resetSubmit={() => {
            setSubmitLocally(false)
            setButtonLoading(false)
          }}
          setLoading={setLoading}
          setSubmitLocally={setSubmitLocally}
        />
        <DialogActions sx={{ background: theme.palette.background.paper, paddingRight: '22px', paddingBottom: '22px' }}>
          {props.allowSubmit && (
            <SaveButton
              label={loading ? <CircularProgress /> : props.allowSubmit}
              valid={props.valid === undefined ? true : props.valid}
              clickAction={() => {
                setButtonLoading(true)
                setSubmitLocally(true)
              }}
              disabled={loading}
              loading={buttonLoading}
            />
          )}
        </DialogActions>
      </Dialog>
    )
  }
  return SimpleModal
}

export default withSimpleModal
