import {
  AppBar,
  Avatar,
  Badge,
  BadgeProps,
  Grid,
  Menu,
  MenuItem,
  styled,
  Toolbar as ToolBar,
  Tooltip,
} from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { profileRequiredSchema } from 'Components/reusable/DataContext/ValidationSchema'
import BadgeIconButton from 'Components/reusable/IconButtons/BadgeIconButton'
import { useSearchData } from 'Components/General/SearchProvider/SearchProvider'
import { useCountryData, useLogin, useUser } from 'hooks'
import React, { useContext, useRef, useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { CountryCode } from 'types/ids'
import LanguageSelector from '../LanguageSelector'
import NotificationModal from './NotificationModal'
import { userAPI } from 'api/UserAPI'
import { useIsComponentMounted } from 'hooks/util'
import ProfileModal from '../ProfileCard/ProfileModal'
import { About } from '../ProfileCard'
import { cvAPI } from 'api/CvAPI'
import { personAPI } from 'api/PersonAPI'
import PasswordModal from './PasswordModal'

interface ToolbarContext {
  itemRef: React.RefObject<HTMLDivElement>
}

// The default value's type is a lie here because the default value is
// never actually used! We promise that a value is always provided.
export const toolbarContext = React.createContext<ToolbarContext>({} as ToolbarContext)

const { Provider } = toolbarContext

/**
 * Toolbar provider.
 *
 * @param children
 * @returns Provider.
 * @notExported
 */
export const ToolbarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const itemRef = useRef<HTMLDivElement>(null)
  return <Provider value={{ itemRef }}>{children}</Provider>
}

/**
 * Styled Badge component.
 * @notExported
 */
const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    right: 10,
    top: 8,
  },
}))

/** @notExported */
interface IToolbarProps {
  /** Count of unread notifications. */
  unreadCount: number
}

/**
 * Top toolbar component.
 *
 * @returns Toolbar component.
 * @notExported
 */
const Toolbar: React.FC<IToolbarProps> = ({ unreadCount }) => {
  const isComponentMounted = useIsComponentMounted()
  const anchorEl = useRef(null)
  const { t, i18n } = useTranslation()
  const { user, groups, profileImage, ready, setUpdateUser } = useUser()
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [contactModalOpen, setContactModalOpen] = useState<boolean>(false)
  const [notificationModalOpen, setNotificationModalOpen] = useState<boolean>(false)
  const [language, setLanguage] = useState<string>(i18n.language)
  const { resetSearch } = useSearchData()
  const location = useLocation()
  const navigate = useNavigate()
  const { itemRef } = useContext(toolbarContext)
  const { loggedIn, token, azureToken } = useLogin()
  const [about, setAbout] = useState<About>()
  const [updateAbout, setUpdateAbout] = useState<boolean>(true)
  const [image, setImage] = useState<string>('')
  const { countries } = useCountryData()
  const [passwordModalOpen, setPasswordModalOpen] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      const controller = new AbortController()

      if (user && language && loggedIn) {
        await userAPI.setLanguage(language, controller)
      }

      if (isComponentMounted.current) i18n.changeLanguage(language)

      return () => {
        controller.abort()
      }
    })()
  }, [language, loggedIn, user, ready])

  useEffect(() => {
    const controller = new AbortController()

    if (updateAbout && loggedIn) {
      ;(async () => {
        if (user?.Person) {
          const image = await personAPI.getProfileImage(user?.Person.id, controller)
          if (isComponentMounted.current) setImage(image)
        }
        const aboutData = await cvAPI.getUserAbout(controller)
        if (isComponentMounted.current) {
          aboutData.CV = aboutData.CVs[0]
          aboutData.CV.organizationId = aboutData.organizationId
          if (!aboutData.country) aboutData.country = 'fi' as CountryCode

          setAbout(aboutData)
          setUpdateAbout(false)
        }
      })()
    }

    return () => {
      controller.abort()
    }
  }, [updateAbout, loggedIn])

  const toggleAccountMenu = () => {
    setMenuOpen(true)
  }

  const handleClose = () => {
    setMenuOpen(false)
  }

  const handleLogout = () => {
    resetSearch()
    navigate('/logout')
    handleClose()
  }

  const changeOldImage = (image: string) => {
    setImage(image)
  }

  let hideToolbar = false
  if (
    location.pathname.includes('/reset-password') ||
    location.pathname.includes('/forgot-password') ||
    location.pathname.includes('/change-password') ||
    location.pathname.includes('/login') ||
    location.pathname.includes('/register') ||
    location.pathname.includes('/confirm') ||
    location.pathname.includes('/activate') ||
    location.pathname.includes('/assignments/public') ||
    location.pathname.includes('/terms') ||
    location.pathname.includes('/privacy-policy')
  ) {
    hideToolbar = true
  }

  const badgeInvisible = useMemo(() => {
    if (
      (groups && !groups.includes('sales')) ||
      (user && user.Person?.telephone && user.Person?.telephone.length > 4)
    ) {
      return true
    } else {
      return false
    }
  }, [groups, user, user?.Person?.telephone])

  return (
    <>
      {!hideToolbar && (
        <AppBar position="static" elevation={4} sx={{ width: '100%' }}>
          <ToolBar variant="dense">
            <div ref={itemRef} style={{ display: 'flex', flexWrap: 'nowrap', height: '44px', width: '88%' }} />

            <div
              style={{
                display: 'flex',
                flexWrap: 'nowrap',
                height: '44px',
                justifyContent: 'flex-end',
                maxWidth: '12%',
              }}
            >
              {loggedIn && user && ready && (
                <BadgeIconButton
                  clickAction={() => navigate('/notifications')}
                  icon={
                    <Tooltip title={t('notifications')}>
                      <NotificationsIcon />
                    </Tooltip>
                  }
                  badgeContent={unreadCount}
                />
              )}
              <Grid>
                <LanguageSelector
                  initialLanguage={i18n.language}
                  setLanguage={setLanguage}
                  global={true}
                  id="toolbar-language"
                />
              </Grid>
              {loggedIn && user && ready && (
                <>
                  <Badge
                    variant="dot"
                    color="error"
                    overlap="circular"
                    invisible={badgeInvisible}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Tooltip title={t('accountMenu')}>
                      <Avatar ref={anchorEl} src={profileImage} onClick={toggleAccountMenu} />
                    </Tooltip>
                  </Badge>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl.current}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    keepMounted
                    open={menuOpen}
                    onClose={handleClose}
                  >
                    {groups.includes('sales') && (
                      <StyledBadge variant="dot" color="error" invisible={badgeInvisible}>
                        <MenuItem
                          onClick={() => {
                            setUpdateAbout(true)
                            setContactModalOpen(true)
                          }}
                        >
                          {t('contactInformation')}
                        </MenuItem>
                      </StyledBadge>
                    )}
                    <MenuItem onClick={() => setNotificationModalOpen(true)}>{t('notificationSettings')}</MenuItem>
                    {token && !azureToken && (
                      <MenuItem onClick={() => setPasswordModalOpen(true)}>{t('changePassword.header')}</MenuItem>
                    )}
                    <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
                  </Menu>
                </>
              )}
            </div>
          </ToolBar>
        </AppBar>
      )}
      {contactModalOpen && user && user.Person && about && (
        <ProfileModal
          item={about}
          schema={profileRequiredSchema()}
          maxWidth="md"
          fullWidth
          localeBase={'profile.modal'}
          countries={countries}
          oldImage={image}
          personId={user?.Person.id}
          changeOldImage={changeOldImage}
          onClose={() => {
            setContactModalOpen(false)
            setUpdateAbout(true)
            setUpdateUser(true)
            if (location.pathname.includes('/profile')) {
              navigate(0)
            }
          }}
          disableDelete={true}
          submitOnModal={true}
          header="about"
          isPhoneRequired
          noEscClose
        />
      )}
      {notificationModalOpen && (
        <NotificationModal
          onClose={() => setNotificationModalOpen(false)}
          allowSubmit={t('save')}
          header={t('notificationSettings')}
          noEscClose
        />
      )}
      {passwordModalOpen && (
        <PasswordModal onClose={() => setPasswordModalOpen(false)} header={t('changePassword.header')} />
      )}
    </>
  )
}

export default Toolbar

export { ToolbarItems } from './ToolbarItems'
