import { Avatar, Button, Chip, Grid, Popover, Slider, Switch, TextField } from '@mui/material'
import CaleoIconButton from 'Components/reusable/IconButtons/CaleoIconButton'
import React, { useEffect, useState } from 'react'
import CopyAllIcon from '@mui/icons-material/CopyAll'
import CaleoPrimaryButton from 'Components/reusable/Buttons/CaleoPrimaryButton'
import { useTranslation } from 'react-i18next'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import { personAPI } from 'api/PersonAPI'
import { useUser } from 'hooks'
import { salesPoolAPI } from 'api/SalesPoolAPI'
import { ISalesPoolItem } from 'types/salesPoolInterfaces'
import { useSalesPool } from 'Components/General/SalesPoolProvider/SalesPoolProvider'
import { useNotification } from 'Components/reusable/Notification'
import { IError } from 'types/error'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import YellowOutlinedButton from 'Components/reusable/Buttons/YellowOutlinedButton'
import { useIsComponentMounted } from 'hooks/util'
import colors from 'constants/colors'

/**
 * SalesPool modal.
 *
 * @returns SalesPool modal component.
 * @notExported
 */
const SalesPoolModal: React.FC = () => {
  const isComponentMounted = useIsComponentMounted()
  const { t } = useTranslation()
  const { features } = useUser()
  const { editedSalesPool, setEditedSalesPool, salesPools, setSalesPools } = useSalesPool()
  const { setSuccessNotification } = useNotification()

  const [images, setImages] = useState<{ personId: number; image: string }[]>([])
  const [expiringWeekCount, setExpiringWeekCount] = useState<number>(4)
  const [linkText, setLinkText] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [salesPoolAnonymous, setSalesPoolAnonymous] = useState<boolean>(false)
  const [allocationsVisible, setAllocationsVisible] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [backendError, setBackendError] = useState<IError>()

  useEffect(() => {
    const controller = new AbortController()

    if (editedSalesPool && editedSalesPool.SalesPoolItems && editedSalesPool.SalesPoolItems.length) {
      ;(async () => {
        try {
          const imagesMap: { personId: number; image: string }[] = []
          if (editedSalesPool.SalesPoolItems) {
            for (const salesPoolItem of editedSalesPool.SalesPoolItems) {
              if (salesPoolItem.Person && salesPoolItem.personId) {
                if (!imagesMap.find(image => image.personId === salesPoolItem.personId)) {
                  const image = await personAPI.getProfileImage(salesPoolItem.personId, controller)
                  imagesMap.push({ personId: salesPoolItem.personId, image: image })
                }
              }
            }
          }
          if (isComponentMounted.current) {
            setImages(imagesMap)

            setTitle(editedSalesPool.title)
            setExpiringWeekCount(editedSalesPool.durationInWeeks)
            if (editedSalesPool.publicId && editedSalesPool.publicId.length > 1) {
              setLinkText(
                `${window.location.protocol}//${window.location.hostname}${
                  window.location.hostname === 'localhost' ? `:${window.location.port}` : ''
                }/salespool/${editedSalesPool.publicId}`
              )
            } else {
              setLinkText('')
            }
            setSalesPoolAnonymous(editedSalesPool.anonymous)
            setAllocationsVisible(editedSalesPool.allocationsVisible)
          }
        } catch (error) {
          setBackendError(error as IError)
        }
      })()
    } else {
      setOpen(false)
    }

    return () => {
      controller.abort()
    }
  }, [editedSalesPool])

  const openSalespoolPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const deleteSalesPoolitem = (item: ISalesPoolItem) => {
    if (editedSalesPool && editedSalesPool.SalesPoolItems) {
      const newItems = editedSalesPool.SalesPoolItems.filter(poolItem => {
        if (item.personId) {
          return poolItem.personId !== item.personId
        } else {
          return poolItem.uploadId !== item.uploadId
        }
      })

      setEditedSalesPool({ ...editedSalesPool, SalesPoolItems: newItems })
    }
  }

  const getChip = (item: ISalesPoolItem, index: number) => {
    const label = (
      <span>{item.Person ? `${item.Person.firstName} ${item.Person.lastName}` : item.Upload?.originalname}</span>
    )
    return (
      <Chip
        key={index}
        style={{ margin: 5 }}
        avatar={
          item.Person ? <Avatar src={images.find(image => image.personId === item.personId)?.image ?? ''} /> : undefined
        }
        label={label}
        onDelete={() => deleteSalesPoolitem(item)}
      />
    )
  }

  const generateSalesPoolLink = async () => {
    if (editedSalesPool && editedSalesPool.SalesPoolItems && editedSalesPool.SalesPoolItems.length) {
      try {
        const result = await salesPoolAPI.createSalesPool({
          title,
          durationInWeeks: expiringWeekCount,
          anonymous: salesPoolAnonymous,
          poolItems: editedSalesPool.SalesPoolItems,
          allocationsVisible: allocationsVisible,
        })

        if (isComponentMounted.current) {
          const poolLink = `${window.location.protocol}//${window.location.hostname}${
            window.location.hostname === 'localhost' ? `:${window.location.port}` : ''
          }/salespool/${result.publicId}`

          setLinkText(poolLink)
          setEditedSalesPool(result)
          setSalesPools([...salesPools, result])
        }
      } catch (error) {
        setBackendError(error as IError)
      } finally {
        if (isComponentMounted.current) setSuccessNotification()
      }
    }
  }

  const updateSalesPoolLink = async () => {
    if (
      editedSalesPool &&
      editedSalesPool.id &&
      editedSalesPool.SalesPoolItems &&
      editedSalesPool.SalesPoolItems.length
    ) {
      try {
        const result = await salesPoolAPI.updateSalesPool(editedSalesPool.id, {
          title,
          durationInWeeks: expiringWeekCount,
          anonymous: salesPoolAnonymous,
          poolItems: editedSalesPool.SalesPoolItems,
          allocationsVisible: allocationsVisible,
        })

        if (isComponentMounted.current) {
          const poolLink = `${window.location.protocol}//${window.location.hostname}${
            window.location.hostname === 'localhost' ? `:${window.location.port}` : ''
          }/salespool/${result.publicId}`

          setLinkText(poolLink)
          setEditedSalesPool(result)

          const index = salesPools.findIndex(pool => pool.id === result.id)
          const newSalesPools = [...salesPools]
          newSalesPools[index] = result
          setSalesPools(newSalesPools)
        }
      } catch (error) {
        setBackendError(error as IError)
      } finally {
        if (isComponentMounted.current) setSuccessNotification()
      }
    }
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <>
      {editedSalesPool && editedSalesPool.SalesPoolItems && editedSalesPool.SalesPoolItems.length > 0 && (
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs="auto" sx={{ visibility: open ? 'hidden' : 'visible' }}>
            <Button onClick={openSalespoolPopover} size="small" variant="contained" color="primary">
              {t('open')}
            </Button>
          </Grid>
          {!open && (
            <Grid item xs>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  {t('selection')}: {editedSalesPool.SalesPoolItems.map((item, i) => getChip(item, i))}{' '}
                  {`(${editedSalesPool.SalesPoolItems.length})`}
                </Grid>
              </Grid>
            </Grid>
          )}
          <Popover
            id="salespool-popover"
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            PaperProps={{ sx: { backgroundColor: colors.secondaryBackground, width: '100%' } }}
          >
            <Grid container alignItems="center" justifyContent="space-between" spacing={2} p={2}>
              {editedSalesPool.SalesPoolItems.filter(item => item.uploadId)?.length > 0 && salesPoolAnonymous && (
                <Grid item xs={12}>
                  <CardContentText type={'error'}>{t('salesPool.anonymousFileInfo')}</CardContentText>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={6}>
                    <CaleoInputLabel label={t('salesPool.titleField')} />
                    <TextField
                      value={title}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
                      margin="dense"
                      label={null}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  {editedSalesPool.SalesPoolItems?.length && editedSalesPool.publicId?.length && (
                    <Grid item xs={1}>
                      <YellowOutlinedButton
                        label={t('salesPool.reset')}
                        clickAction={() => setEditedSalesPool(undefined)}
                        valid
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                  <Grid item sx={{ width: '250px' }}>
                    <CaleoInputLabel label={t('salesPool.expiresIn', { date: expiringWeekCount })} />
                    <Slider
                      aria-label="duration"
                      value={expiringWeekCount}
                      onChange={(e, value) => {
                        if (!Array.isArray(value)) setExpiringWeekCount(value)
                      }}
                      min={1}
                      max={8}
                      step={1}
                      valueLabelDisplay="auto"
                      defaultValue={4}
                    />
                  </Grid>
                  {features.includes('allocations') && (
                    <Grid item>
                      <CaleoInputLabel label={t('salesPool.allocationsVisible')} />
                      <Switch
                        checked={allocationsVisible}
                        onChange={() => setAllocationsVisible(!allocationsVisible)}
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <CaleoInputLabel label={t('salesPool.anonymous')} />
                    <Switch checked={salesPoolAnonymous} onChange={() => setSalesPoolAnonymous(!salesPoolAnonymous)} />
                  </Grid>
                  {linkText.length > 0 && (
                    <Grid item>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>{linkText}</Grid>
                        <Grid item>
                          <CaleoIconButton
                            icon={<CopyAllIcon />}
                            clickAction={() => navigator.clipboard.writeText(linkText)}
                            tooltip={t('salesPool.copyToClipboard')}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item>
                    {linkText.length > 0 && editedSalesPool && editedSalesPool.id ? (
                      <CaleoPrimaryButton
                        valid={true}
                        label={t('salesPool.updateSalesPool')}
                        clickAction={updateSalesPoolLink}
                      />
                    ) : (
                      <CaleoPrimaryButton
                        valid={true}
                        label={t('salesPool.generateLink')}
                        clickAction={generateSalesPoolLink}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs="auto">
                <Button onClick={handleClose} size="small" variant="contained" color="primary">
                  {t('close')}
                </Button>
              </Grid>
              <Grid item xs>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    {t('selection')}: {editedSalesPool.SalesPoolItems.map((item, idx) => getChip(item, idx))}{' '}
                    {`(${editedSalesPool.SalesPoolItems.length})`}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Popover>
        </Grid>
      )}
    </>
  )
}

export default SalesPoolModal
