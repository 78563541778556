import { Autocomplete, Button, Chip, Grid, TextField } from '@mui/material'
import { useSearchData } from 'Components/General/SearchProvider/SearchProvider'
import DataContext from 'Components/reusable/DataContext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { chooseDBTranslation } from 'utils/translations'
import { fuseFiltering } from 'utils/utils'
import { IIndustry, IRole, ISkill } from 'types/cvsInterfaces'
import SearchFields from './SearchFields'
import TermsDisplay from './TermsDisplay'

/** @notExported */
interface IDetailedSearchProps {
  /** Function to submit search. */
  onSubmit: () => void
  /** Whether loading. */
  loading: boolean
  /** The skills that can be selected. */
  skillOptions: ISkill[]
  /** The roles that can be selected. */
  roleOptions: IRole[]
  /** The industries that can be selected. */
  industryOptions: IIndustry[]
}

/**
 * React functional component for detailed search.
 *
 * @param {IDetailedSearchProps} onSubmit - Function to handle form submission
 * @param {boolean} loading - Flag for loading state
 * @param {Array} skillOptions - List of available skill options
 * @param {Array} roleOptions - List of available role options
 * @param {Array} industryOptions - List of available industry options
 * @return {JSX.Element} The detailed search component
 */
const DetailedSearch: React.FC<IDetailedSearchProps> = ({
  onSubmit,
  loading,
  skillOptions,
  roleOptions,
  industryOptions,
}) => {
  const { t, i18n } = useTranslation()
  const { searchData, setSearchData, searchableNetworks } = useSearchData()

  return (
    <DataContext
      data={searchData.terms}
      onChange={values => setSearchData({ ...searchData, terms: { ...values } })}
      onSubmit={onSubmit}
      localeBase="search.detailedSearch"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item sx={{ fontWeight: 'bold' }}>
              {t('search.from')}
            </Grid>
            <Grid item>
              <Autocomplete
                noOptionsText={t('controls.noOptionsText')}
                options={searchableNetworks ?? []}
                getOptionLabel={option => chooseDBTranslation(i18n, option).name}
                value={searchData.terms.networks}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e, value) => {
                  if (!value) return
                  setSearchData({ ...searchData, terms: { ...searchData.terms, networks: value } })
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    sx={{ minWidth: 200 }}
                    name="networks"
                    size="small"
                    variant="standard"
                    margin="dense"
                  />
                )}
                filterOptions={(options, { inputValue }) => fuseFiltering(options, inputValue, ['translations.name'])}
                getOptionDisabled={option => {
                  if (searchData.terms.networks.find(item => item.id === option.id)) {
                    return true
                  }
                  return false
                }}
                renderTags={(value, getTagProps) => {
                  return value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={option.id}
                      sx={{ mr: 0.5, borderRadius: 0.5 }}
                      variant="outlined"
                      size="small"
                      label={chooseDBTranslation(i18n, option).name}
                    />
                  ))
                }}
                multiple
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <SearchFields skillOptions={skillOptions} roleOptions={roleOptions} industryOptions={industryOptions} />
        <TermsDisplay />
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={onSubmit}
            disabled={!(!loading && searchData.terms.networks.length > 0)}
            color="newPrimary"
          >
            {t('resources.searchEmployees.search')}
          </Button>
        </Grid>
      </Grid>
    </DataContext>
  )
}

export default DetailedSearch
