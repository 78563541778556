import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IItemVisibility } from 'types/layoutInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import VisibilityCheckbox from '../VisibilityCheckbox'
import colors from 'constants/colors'

/**
 * Renders the About section of the page.
 *
 * @param {Object} about - The about object containing information about the section.
 * @param {string} layout - The layout of the page.
 * @param {boolean} hideSection - Whether to hide the section or not.
 * @param {boolean} expanded - Whether the accordion is expanded or not.
 * @param {function} setStatus - Function to set the status of the accordion.
 * @return {JSX.Element} The rendered About section.
 * @notExported
 */
const About = ({ about, layout, hideSection, expanded, setStatus }) => {
  const { t, i18n } = useTranslation()
  const description = chooseDBTranslation(i18n, about).about

  let hidden = false
  if (layout && layout.ItemVisibilities && layout.ItemVisibilities.length > 0) {
    const visibilities = layout.ItemVisibilities.filter((visibility: IItemVisibility) => visibility.type === 'about')
    if (visibilities.length > 0) {
      hidden = visibilities.every((value: IItemVisibility) => value.hidden === true)
    }
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={(e, expanded) => setStatus(expanded)}
      elevation={0}
      sx={{ borderRadius: '5px', border: `1px solid ${colors.borderColor}` }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          fontSize: '20px',
          color: theme => theme.palette.primary.main,
          fontWeight: 'bold',
          textTransform: 'capitalize',
        }}
      >
        <Grid container alignItems="center">
          <Grid item>
            <VisibilityCheckbox checked={hidden} onChange={(checked: boolean) => hideSection(checked, 'about')} />
          </Grid>
          <Grid item> {t('print.layout.about')}</Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{description}</AccordionDetails>
    </Accordion>
  )
}

export default React.memo(About)
