import { Button, Grid, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import UpdateIcon from '@mui/icons-material/Update'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { orderBy } from 'lodash'
import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ICertificate,
  ICourse,
  IEducation,
  IEmployer,
  ILanguageSkill,
  IPersonSkillOrIndustryOrRole,
  IProject,
  IReference,
} from 'types/cvsInterfaces'
import { IError } from 'types/error'
import CaleoIconButton from '../IconButtons/CaleoIconButton'
import { isEqual } from 'lodash'
import AddIcon from '@mui/icons-material/Add'

type T =
  | IReference
  | IPersonSkillOrIndustryOrRole
  | ILanguageSkill
  | IProject
  | IEmployer
  | ICourse
  | IEducation
  | ICertificate

export type Data = { education: IEducation[]; courses: ICourse[]; certificates: ICertificate[] }

/**
 * Profile card higher order component.
 *
 * @param Component - The component to wrap.
 * @returns The wrapped component.
 * @notExported
 */
const withProfileCard = Component => {
  const ProfileCard = props => {
    const [items, setItems] = useState<T[]>(props.items)
    const [data, setData] = useState<Data>(props.data)
    const [editedItem, setEditedItem] = useState<T | null | undefined>(undefined)
    const [backendError, setBackendError] = useState<IError>()
    const [detailsOpen, setDetailsOpen] = useState<boolean>(false)
    const [updateOpen, setUpdateOpen] = useState<boolean>(false)
    const { t } = useTranslation()

    useEffect(() => {
      if (!isEqual(props.items, items)) setItems(props.items)
    }, [props.items])

    const replaceOrAddItem = (item: T | { deleted: number }): T[] => {
      const newItems: T[] = []
      const targetId = 'deleted' in item ? item.deleted : item.id
      let found = false
      for (const existing of items) {
        if (existing.id === targetId) {
          if (!('deleted' in item)) {
            newItems.push(item)
          }
          found = true
        } else {
          newItems.push(existing)
        }
      }
      if (!found && !('deleted' in item)) {
        newItems.push(item)
      }
      setItems(newItems)
      return newItems
    }

    const replaceOrAddItems = (incomingItems: T[]): T[] => {
      const newItems: T[] = [...items]

      for (const item of incomingItems) {
        const index = newItems.findIndex(existing => existing.id === item.id)

        if (index > -1) {
          newItems[index] = item
        } else {
          newItems.push(item)
        }
      }

      setItems(newItems)
      return newItems
    }

    const replaceOrAddData = (item: T | { deleted: number }, type: 'education' | 'courses' | 'certificates'): Data => {
      const newData: Data = {
        education: [],
        courses: [],
        certificates: [],
      }
      const targetId = 'deleted' in item ? item.deleted : item.id
      let itemData

      if (type === 'education') {
        itemData = item as IEducation
        newData.courses = [...data.courses]
        newData.certificates = [...data.certificates]
      } else if (type === 'courses') {
        itemData = item as ICourse
        newData.education = [...data.education]
        newData.certificates = [...data.certificates]
      } else if (type === 'certificates') {
        itemData = item as ICertificate
        newData.education = [...data.education]
        newData.courses = [...data.courses]
      }

      let found = false
      for (const existing of data[type]) {
        if (existing.id === targetId) {
          if (!('deleted' in itemData)) {
            newData[type].push(itemData)
          }
          found = true
        } else {
          newData[type].push(existing as typeof itemData)
        }
      }
      if (!found && !('deleted' in itemData)) {
        newData[type].push(itemData)
      }

      setData(newData)
      return newData
    }

    if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
      return <ErrorOverlay error={backendError} setOpen={setBackendError} />
    }

    const getSortedItems = useCallback(() => {
      if (
        props.header === 'skills' ||
        props.header === 'industries' ||
        props.header === 'roles' ||
        props.header === 'language'
      ) {
        return orderBy(items, ['level'], ['desc'])
      } else if (props.header === 'project' || props.header === 'employer') {
        return orderBy(items, ['endDate', 'startDate'], ['desc', 'desc'])
      }
      return items
    }, [items])

    const getAddButton = useCallback(() => {
      return (
        <Button onClick={() => setEditedItem(null)} color="primary" variant="contained" startIcon={<AddIcon />}>
          {t(`profile.${props.header}.add`)}
        </Button>
      )
    }, [])

    const getDetailsButton = useCallback(() => {
      return (
        <CaleoIconButton clickAction={() => setDetailsOpen(true)} size="medium" icon={<InfoIcon fontSize="large" />} />
      )
    }, [])

    const getUpdateButton = useCallback(() => {
      return (
        <CaleoIconButton clickAction={() => setUpdateOpen(true)} size="medium" icon={<UpdateIcon fontSize="large" />} />
      )
    }, [])

    const getHeaderAction = useCallback(() => {
      return (
        <span style={{ display: 'flex', flexFlow: 'row-reverse', alignItems: 'center' }}>
          {props.editable && !props.hideAddButton && getAddButton()}
          {props.update && props.editable && getUpdateButton()}
          {props.details && getDetailsButton()}
        </span>
      )
    }, [])

    return (
      <Grid container mt={4}>
        <Grid item xs={12}>
          <Grid item xs={12} container justifyContent="space-between">
            <Grid item>
              <Typography variant="h3" gutterBottom fontWeight="bold">
                {t(`${props.header}.title`)}
              </Typography>
            </Grid>
            <Grid item>{getHeaderAction()}</Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Component
            {...props}
            closeItem={() => setEditedItem(undefined)}
            replaceOrAddItem={replaceOrAddItem}
            replaceOrAddItems={replaceOrAddItems}
            replaceOrAddData={replaceOrAddData}
            items={getSortedItems()}
            data={data}
            openItem={(item: T) => setEditedItem(item)}
            editedItem={editedItem}
            closeDetails={() => setDetailsOpen(false)}
            detailsOpen={detailsOpen}
            closeUpdate={() => setUpdateOpen(false)}
            updateOpen={updateOpen}
          />
        </Grid>
      </Grid>
    )
  }
  return ProfileCard
}

export default withProfileCard
