import { Divider, Grid } from '@mui/material'
import { releaseAPI } from 'api/ReleaseNoteAPI'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IReleaseNote } from 'types/releaseNoteInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { convertToDisplayDate } from 'utils/utils'
import DOMPurify from 'dompurify'
import CardContentText from 'Components/reusable/CaleoCustomComponents/CardContentText'
import { orderBy } from 'lodash'
import { useIsComponentMounted } from 'hooks/util'

/**
 * Renders the release notes component.
 *
 * @return {React.ReactElement} The rendered release notes component.
 * @notExported
 */
const ReleaseNotes: React.FC = () => {
  const isComponentMounted = useIsComponentMounted()
  const { t, i18n } = useTranslation()
  const [notes, setNotes] = useState<IReleaseNote[]>()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      const releaseNotes = await releaseAPI.getNotes(controller)

      if (!isComponentMounted.current) return
      setNotes(orderBy(releaseNotes, ['createdAt'], ['desc']))
    })()

    return () => {
      controller.abort()
    }
  }, [])

  return (
    <Grid container sx={{ maxWidth: 1400, m: '0 auto', px: 1 }}>
      <Grid item xs={12} mt={1}>
        <CardContentText type="cardHeader">{t('releaseNotes.title')}</CardContentText>
      </Grid>
      <Grid item xs={12} container>
        {notes &&
          notes.length > 0 &&
          notes.map((note, i) => {
            return (
              <React.Fragment key={i}>
                {i !== 0 && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}
                <Grid item xs={12} mt={3}>
                  <CardContentText type="darkSubHeader">
                    <span style={{ textTransform: 'capitalize' }}>{chooseDBTranslation(i18n, note).title}</span>
                  </CardContentText>
                </Grid>
                <Grid item xs={12}>
                  {`${t('admin.releaseNotes.createdAt')}: ${
                    note.createdAt ? convertToDisplayDate(note.createdAt) : '-'
                  } ${t('releaseNotes.version')}: ${note.version}`}
                </Grid>
                <Grid item xs={12}>
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(chooseDBTranslation(i18n, note).note) }} />
                </Grid>
              </React.Fragment>
            )
          })}
        {!notes ||
          (notes.length < 1 && (
            <Grid item xs={12}>
              {t('releaseNotes.noNotes')}
            </Grid>
          ))}
      </Grid>
    </Grid>
  )
}

export default ReleaseNotes
