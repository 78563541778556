import { DialogContent } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import newWithConfirmationModal from 'Components/reusable/HOC/newWithConfirmationModal'

/** @notExported */
interface DeleteConfirmProps {
  /** Confirmation text. */
  confirmationText?: string
  /** Header text. */
  headerText?: string
}

/**
 * Dlete confim component.
 *
 * @returns Delete confirm component.
 * @notExported
 */
const DeleteConfirm: React.FC<DeleteConfirmProps> = ({ confirmationText, headerText }) => {
  const { t } = useTranslation()

  return (
    <DialogContent>
      <h4>{headerText ? headerText : t('confirmation.text')}</h4>
      {confirmationText && <p>{confirmationText}</p>}
    </DialogContent>
  )
}

export default newWithConfirmationModal(DeleteConfirm)
