import { Button, Dialog, DialogActions, DialogTitle, Grid, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import DeleteEmployeeConfirm from 'Components/General/Admin/AdminEmployees/DeleteEmployeeConfirm'
import DeleteOrganizationConfirm from 'Components/General/Admin/AdminOrganizations/DeleteOrganizationConfirm'
import DeleteSitesConfirm from 'Components/General/Admin/AdminOrganizations/DeleteSitesConfirm'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import DeleteConnectedSkillConfirm from 'Components/General/SkillsCard/DeleteConnectedSkillConfirm'
import DeleteConfirm from 'Components/reusable/Confirmations/DeleteConfirm'
import DataContext, { NewOrExisting } from 'Components/reusable/DataContext'
import LoadingIndicator from 'Components/reusable/LoadingIndicator'
import { useNotification } from 'Components/reusable/Notification'
import { cloneDeep } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Flag from 'react-world-flags'
import { IAllocation } from 'types/allocationInterfaces'
import {
  ICertificate,
  ICourse,
  IEducation,
  IEmployer,
  ILanguageSkill,
  IPersonSkillOrIndustryOrRole,
  IProject,
  IReference,
} from 'types/cvsInterfaces'
import { IError } from 'types/error'
import { IAbout } from 'types/layoutInterfaces'
import { ITeam } from 'types/teamInterfaces'
import { INetworkRegister } from 'types/networkInterfaces'
import { COUNTRY_CODES } from 'constants/caleo-constants'
import CardContentText from '../CaleoCustomComponents/CardContentText'
import CloseButton from '../IconButtons/CloseButton'
import YellowOutlinedButton from '../Buttons/YellowOutlinedButton'
import { IChatRoom } from 'types/chatInterfaces'
import { useIsComponentMounted } from 'hooks/util'
import colors from 'constants/colors'

type T =
  | IReference
  | IPersonSkillOrIndustryOrRole
  | ILanguageSkill
  | IProject
  | IEmployer
  | IEducation
  | ICertificate
  | ICourse
  | IAbout
  | IAllocation
  | ITeam
  | INetworkRegister
  | IChatRoom

/**
 * Edit modal higher order component.
 *
 * @param ModalComponent - The modal component to wrap.
 * @returns The wrapped modal component.
 * @notExported
 */
const newWithEditModal = ModalComponent => {
  const EditModal = props => {
    const isComponentMounted = useIsComponentMounted()
    const { t, i18n } = useTranslation()
    const { setSuccessNotification } = useNotification()
    const [data, setData] = useState<NewOrExisting<T> | null>(null)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const [confirmation, setConfirmation] = useState<boolean>(false)
    const [backendError, setBackendError] = useState<IError>()
    const [multipleItems, setMultipleItems] = useState<NewOrExisting<T>[]>([])
    const [schema, setSchema] = useState<unknown>()
    const [api, setApi] = useState<typeof props.api>()
    const [localeBase, setLocaleBase] = useState<string>()
    const [submitLocally, setSubmitLocally] = useState<boolean>(false)
    const [deleteLocally, setDeleteLocally] = useState<boolean>(false)
    const [deleted, setDeleted] = useState<boolean>(false)
    const [buttonLoading, setButtonLoading] = useState<boolean>(false)
    const [extraActions, setExtraActions] = useState<React.ReactNode>()

    const saveLabel = props.customButtons ? t(`${localeBase}.save`) : t('save')
    const updateLabel = props.customButtons ? t(`${localeBase}.update`) : t('update')

    useEffect(() => {
      let stillValid = true

      ;(async () => {
        const initialData = props.item ? cloneDeep(props.item) : await props.initialData
        if (stillValid) {
          setData(initialData)
          setSchema(props.schema)
          setApi(props.api)
          setLocaleBase(props.localeBase)
        }
      })()

      return () => {
        stillValid = false
      }
    }, [])

    const sendData = (type: 'create' | 'edit' | 'delete', newItem) => {
      if (props.onAdvancedClose) props.onAdvancedClose(type, newItem)
    }

    const onSubmit = async () => {
      if (buttonLoading) return

      setButtonLoading(true)

      if (props.submitOnModal === undefined) {
        let newItem: T
        if (multipleItems && multipleItems.length > 0) {
          try {
            const items = multipleItems

            if (props.addSelected && props.checkFields && props.checkFields.length && data) {
              const checked = props.checkFields.every(
                field => data[field] !== null && data[field] !== undefined && data[field] !== ''
              )
              if (checked) {
                items.push(data)
              }
            }

            const newItems: T[] = []

            for (const item of items) {
              newItems.push(await api.create(props.cvId, item))
            }

            props.onClose({ newItem: newItems })
            setSuccessNotification()
          } catch (err) {
            setBackendError(err as IError)
          }
        } else {
          try {
            if (props.item && props.item.id) {
              if (props.networkManage) {
                newItem = await api.networkSave(props.item, data)
                sendData('edit', newItem)
              } else {
                newItem = await api.save(props.item, data)
                sendData('edit', newItem)
              }
            } else {
              newItem = await api.create(props.cvId, data)
              sendData('create', newItem)
            }
            if (isComponentMounted.current) {
              props.onClose({ newItem })
              setSuccessNotification()
            }
          } catch (err) {
            setBackendError(err as IError)
          }
        }
        if (isComponentMounted.current) setButtonLoading(false)
      } else {
        setSubmitLocally(true)
      }
    }

    const deleteItem = async () => {
      if (props.deleteOnModal === undefined) {
        try {
          if (props.item && props.item.id) {
            sendData('delete', props.item)
            await api.delete(props.item.id, props.cvId)
            if (isComponentMounted.current) {
              setDeleted(true)
              props.onClose({ newItem: { deleted: props.item.id, item: props.item, cvId: props.cvId ?? undefined } })
            }
          }
        } catch (error) {
          setBackendError(error as IError)
        }
      } else {
        setDeleteLocally(true)
        setConfirmation(false)
      }
    }

    const add = () => {
      if (!data) return

      setMultipleItems([...multipleItems, data])
      setData(props.initialData)
    }

    if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
      return <ErrorOverlay error={backendError} setOpen={setBackendError} />
    }

    if (!data) {
      return <LoadingIndicator />
    }

    return (
      <DataContext
        data={data}
        onChange={setData}
        onSubmit={onSubmit}
        schema={schema ?? props.schema}
        localeBase={localeBase}
      >
        <Dialog
          open
          fullScreen={fullScreen}
          onClose={() => {
            if (props.noEscClose) {
              return
            }
            props.onClose({})
          }}
          maxWidth={props.maxWidth}
          fullWidth={props.fullWidth ? true : false}
          sx={{
            '.MuiDialog-paper': {
              backgroundColor: colors.secondaryBackground,
              borderRadius: 1,
            },
          }}
        >
          <DialogTitle sx={{ backgroundColor: colors.secondaryBackground }}>
            {props.item ? (
              <span id="form-dialog-title">{t(`${localeBase}.editTitle`)}</span>
            ) : (
              <span id="form-dialog-title">{t(`${localeBase}.addTitle`)}</span>
            )}
            {props.onClose && <CloseButton clickAction={() => props.onClose({})} />}
          </DialogTitle>
          <ModalComponent
            {...props}
            multipleItems={multipleItems}
            addMultiple={() => add()}
            removeFromArray={items => setMultipleItems(items)}
            data={data}
            setData={object => setData(object)}
            setSchema={object => setSchema(object)}
            setLocaleBase={object => setLocaleBase(object)}
            setApi={object => setApi(object)}
            submitIndicator={submitLocally}
            deleteIndicator={deleteLocally}
            resetSubmit={() => {
              setButtonLoading(false)
              setSubmitLocally(false)
            }}
            deleted={deleted}
            setExtraActions={(actionItem: React.ReactNode) => setExtraActions(actionItem)}
          />
          <DialogActions sx={{ p: 2 }}>
            <Grid
              container
              justifyContent={props.maxWidth === 'xs' ? 'flex-end' : 'space-between'}
              direction="row"
              alignItems="center"
              style={{ display: 'flex' }}
              spacing={1}
            >
              <Grid item>
                {props.hideLanguageHelperText ? null : (
                  <Grid container spacing={2}>
                    <Grid item>
                      <Flag
                        style={{ height: '20px', width: '32px' }}
                        code={COUNTRY_CODES[i18n.language]}
                        fallback={<span>??</span>}
                      />
                    </Grid>
                    <Grid item>
                      <CardContentText type="description">{t('translationImport.helperText')}</CardContentText>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  {extraActions && <Grid item>{extraActions}</Grid>}
                  <Grid item>
                    {props.extraButtonAction && props.item && props.extraButtonLabel && (
                      <YellowOutlinedButton
                        clickAction={() => props.extraButtonAction(props.item)}
                        valid
                        label={props.extraButtonLabel}
                        noMargin
                      />
                    )}
                  </Grid>
                  <Grid item>
                    {props.item && props.disableDelete !== true && (
                      <Button variant="outlined" onClick={() => setConfirmation(true)}>
                        {t('delete')}
                      </Button>
                    )}
                  </Grid>
                  <Grid item>
                    {props.disableSubmit !== true && (
                      <DataContext.Validation>
                        {({ valid }) => (
                          <Button
                            disabled={multipleItems.length > 0 ? multipleItems.length < 1 && !valid : !valid}
                            onClick={onSubmit}
                            color={props.submitColor}
                            variant="contained"
                          >
                            {buttonLoading ? <LoadingIndicator /> : <>{props.item ? updateLabel : saveLabel}</>}
                          </Button>
                        )}
                      </DataContext.Validation>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
          {!props.employeeModal && !props.skillsModal && !props.organizationModal && !props.siteModal && props.item && (
            <DeleteConfirm open={confirmation} confirm={deleteItem} onClose={() => setConfirmation(false)} />
          )}
          {props.siteModal && props.item && (
            <DeleteSitesConfirm
              item={props.item}
              open={confirmation}
              confirm={deleteItem}
              onClose={() => setConfirmation(false)}
            />
          )}
          {props.skillsModal && props.item && (
            <DeleteConnectedSkillConfirm
              item={props.item}
              open={confirmation}
              confirm={deleteItem}
              type={props.type}
              onClose={() => setConfirmation(false)}
            />
          )}
          {props.organizationModal && props.item && (
            <DeleteOrganizationConfirm
              item={props.item}
              open={confirmation}
              confirm={deleteItem}
              onClose={() => setConfirmation(false)}
              maxWidth="md"
              fullWidth={true}
              networkManage={props.networkManage}
            />
          )}
          {props.employeeModal && !props.networkManage && props.item && (
            <DeleteEmployeeConfirm
              open={confirmation}
              confirm={deleteItem}
              onClose={() => setConfirmation(false)}
              item={props.item}
            />
          )}
          {props.employeeModal && props.networkManage && props.item && (
            <DeleteEmployeeConfirm
              open={confirmation}
              confirm={deleteItem}
              onClose={() => setConfirmation(false)}
              item={props.item}
              networkManage={props.networkManage}
            />
          )}
        </Dialog>
      </DataContext>
    )
  }
  return EditModal
}

export default newWithEditModal
