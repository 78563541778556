import { Text, View } from '@react-pdf/renderer'
import colors from 'constants/colors'
import React, { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IPersonSkillOrIndustryOrRole } from 'types/cvsInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { useStyles } from './styles'
import { roundToDecimal, skillLevels } from 'utils/utils'

/**
 * Render a Skill component.
 *
 * @param {Array} items - An array of items.
 * @param {string} headingColor - The color of the heading.
 * @param {string} type - The type of the component.
 * @param {boolean} isHidden - Indicates whether the component is hidden or not.
 * @param {boolean} hideLastUsed - Indicates whether to hide the last used item or not.
 * @return {ReactNode} The rendered Skill component.
 * @notExported
 */
const Skill = ({ items, headingColor, type, isHidden, hideLastUsed }) => {
  const { t, i18n } = useTranslation()
  const styles = useStyles()

  const hidden = useMemo(() => {
    if (items.length) {
      return isHidden
    }
    return true
  }, [items])

  const rows = useMemo(() => {
    if (items.length) {
      return items.map((item: IPersonSkillOrIndustryOrRole) => (
        <View key={item.id} style={styles.item} wrap={false}>
          <Text style={styles.skillText}>{chooseDBTranslation(i18n, item.Skill).name}</Text>
          <View style={styles.skillData}>
            <Text style={{ fontSize: '10pt', color: colors.primary }}>{`${item.level}. ${
              item.level ? t(skillLevels()[(item.level ?? 1) - 1].label) : ''
            }`}</Text>
            <Text style={{ fontSize: '10pt', color: colors.primary }}>
              {`${t('profile.skill.experience')}: ${
                item.experienceMonths
                  ? `${roundToDecimal(item.experienceMonths / 12, 1)} ${t('profile.skill.years.abbr')}`
                  : '-'
              }`}
            </Text>
            {!hideLastUsed && (
              <Text style={{ fontSize: '10pt', color: colors.primary }}>
                {t('profile.skill.lastUsed')}: {item.lastUsed ? item.lastUsed : ''}
              </Text>
            )}
          </View>
        </View>
      ))
    }
  }, [items])

  if (hidden) {
    return <></>
  }

  return (
    <>
      <View style={styles.container}>
        <Text style={[styles.title, { color: headingColor ? headingColor : colors.secondary }]}>
          {t(`print.${type}.title`)}
        </Text>
        <View style={styles.columnContainer}>
          <View style={styles.skillColumn}>
            {rows.map((row: ReactNode[], i: number) => {
              if (i + 1 <= (rows.length + 1) / 2) {
                return (
                  <View key={type + i} wrap={false} style={styles.skillRow}>
                    {row}
                  </View>
                )
              }
            })}
          </View>
          <View style={styles.skillColumn}>
            {rows.map((row: ReactNode[], i: number) => {
              if (i + 1 > (rows.length + 1) / 2) {
                return (
                  <View key={type + i} wrap={false} style={styles.skillRow}>
                    {row}
                  </View>
                )
              }
            })}
          </View>
        </View>
        <View style={styles.levelsContainer}>
          <Text style={styles.levelText}>{`1 - ${t('print.skills.fundamentals')}`}</Text>
          <Text style={{ color: colors.gray }}>|</Text>
          <Text style={styles.levelText}>{`2 - ${t('print.skills.novice')}`}</Text>
          <Text style={{ color: colors.gray }}>|</Text>
          <Text style={styles.levelText}>{`3 - ${t('print.skills.intermediate')}`}</Text>
          <Text style={{ color: colors.gray }}>|</Text>
          <Text style={styles.levelText}>{`4 - ${t('print.skills.advanced')}`}</Text>
          <Text style={{ color: colors.gray }}>|</Text>
          <Text style={styles.levelText}>{`5 - ${t('print.skills.expert')}`}</Text>
        </View>
      </View>
    </>
  )
}

export default React.memo(Skill)
